<template>
	<div>
		<gista-modal :value="modalInfo?.value"
			customClass="max-w-2xl bg-white"
			:scrollable="true"
			@input="closeModal">
			<div class="w-full p-6">
				<div class="flex mb-6">
					<h1 class="text-xl font-semibold">{{modalInfo?.title}}</h1>
				</div>
				<div class="flex mb-6">
					<p class="text-gray whitespace-pre-wrap break-words">{{modalInfo?.description}}</p>
				</div>
				<div class="">
					<p>Skala Nilai:</p>
					<ul class="list-decimal text-gray"
						style="padding-inline-start: 18px;">
						<li v-for="(n,i) in modalInfo?.nilai"
							:key="i">{{n}}</li>
					</ul>
				</div>
			</div>
		</gista-modal>
	</div>
</template>

<script>
export default {
	name: "ModalInfo",
	props: ['modalInfo'],
	data() {
		return {
		}
	},

	methods: {
		closeModal() {
			this.modalInfo.value = false
		}
	}
}
</script>