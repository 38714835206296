<template>
  <div v-if="!completedNoLoi" 
    class="flex items-center justify-center height-custom">
    <div class="flex flex-col items-center gap-3">
      <img src="@/core/assets/icons/file/file.svg" alt="icon" class="w-28"/>
      <div  class="flex flex-col items-center gap-3">
        <div class="flex items-center text-lg font-medium text-gray">Create LOI?</div>
        <div class="flex justify-center gap-3" >
          <button-gista type="secondary" color="primary" customClass="w-full sm:w-28 sm:mr-3.5" @click="() => isOpenCancel = true">
            NO
          </button-gista>
          <button-gista color="primary"
            customClass="w-full sm:w-28"
            @click="click(true)">YES</button-gista>
        </div>
      </div>
    </div>
    <ModalConfirm 
        title="Tidak Buat LOI" 
        message="Apakah anda yakin? Jika Ya, maka status project menjadi completed dan tidak dapat diubah kembali." 
        textButton="Yes" 
        icon="rejected" 
        :isOpen="isOpenCancel" 
        @changeIsOpen="() => isOpenCancel = false" 
        @click="click(false)"
        colorButton="success"></ModalConfirm>
  </div>
</template>
<script>
import ModalConfirm from '@/core/components/modal/Confirm'

export default {
  name: "SingleVendorLoi",
  data() {
    return {
      isOpenCancel: false,
    }
  },
  watch: {
  },
  computed:{
    isSingleVendor() {
      return this.draftSktLoi.sktVendors?.length === 0
    },
    draftSktLoi: {
      get() {
        return this.$store.state.sktLoi.draftSktLoi
      },
      set(value) {
        this.$store.commit('sktLoi/SET_DRAFT_SKT_LOI', value)
      }
    },
    completedNoLoi() {
      return !this.draftSktLoi.needLoi && this.isSingleVendor && this.draftSktLoi.isCompleted
    }
  },
  methods: {
    click(param) {
      this.$emit('click', param)
    },
  },
  components:{
    ModalConfirm
  }
}
</script>