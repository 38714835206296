<template>
	<MainLayout>

		<LayoutProjectDetail :key="$route.params.projectId">
			<Detail v-if="activePage === PROJECT_DETAIL" />
			<PraQualification v-if="activePage === PROJECT_PRA_QUALIFICATION" />
			<Qualification v-if="activePage === PROJECT_QUALIFICATION" />
			<Scoring v-if="activePage === PROJECT_SCORING" />
			<Negotiation v-if="activePage === PROJECT_NEGOTIATION" />
			<ProcurementApproval v-if="activePage === PROJECT_APPROVAL" />
			<SktLoi v-if="activePage === PROJECT_SKT_LOI" />
			<Spp v-if="activePage === PROJECT_SPP" />
		</LayoutProjectDetail>

	</MainLayout>
</template>

<script>
import Detail from '@/core/components/rfp/Detail.vue';
import PraQualification from '@/core/components/rfp/PraQualification.vue'
import Qualification from '@/core/components/rfp/Qualification.vue';
import Scoring from '@/core/components/rfp/Scoring.vue'
import Negotiation from '@/core/components/rfp/Negotiation.vue'
import ProcurementApproval from '@/core/components/rfp/ProcurementApproval.vue'
import SktLoi from '@/core/components/rfp/SktLoi.vue'
import Spp from '@/core/components/rfp/Spp.vue'
import { PATH_ROOT, PROJECT_DETAIL, PROJECT_PRA_QUALIFICATION, PROJECT_QUALIFICATION, PROJECT_SCORING, PROJECT_NEGOTIATION, PROJECT_APPROVAL, PROJECT_SKT_LOI, PROJECT_SPP } from '@/core/constant/routeName'


export default {
	name: "projectDetail",
	data() {
		return {
			PROJECT_DETAIL,
			PROJECT_PRA_QUALIFICATION,
			PROJECT_QUALIFICATION,
			PROJECT_SCORING,
			PROJECT_NEGOTIATION,
			PROJECT_APPROVAL,
			PROJECT_SKT_LOI,
			PROJECT_SPP,
			PATH_ROOT,
		}
	},
	computed: {
		activePage() {
			return this.$store.state.projectDetail.activePage
		},
		scoringVendorApproval() {
      return this.$store.state.projectDetail.scoringVendorApproval
    },
    approvalGsit() {
      return this.scoringVendorApproval?.find(e => e.unitKerja === 'GSIT')
    },
    approvalUkkp() {
      return this.scoringVendorApproval?.find(e => e.unitKerja === 'UKKP')
    },
    approvalDlog() {
      return this.scoringVendorApproval?.find(e => e.unitKerja === 'ITP')
    },
		approverScoringDecision: {
			get() {
				return this.$store.state.projectDetail.approverScoringDecision
			},
			set(val) {
				this.$store.commit('projectDetail/SET_APPROVER_SCORING_DECISION', val)
			}
		}
	},
	methods: {
		setApprover() {
			// set approver scoring decision form state when open project detail at first time so when move tab route not changing that state form
			this.approverScoringDecision.approverGsit = this.approvalGsit?.userId? { name: this.approvalGsit?.userName, userName: this.approvalGsit?.userId } : this.approverScoringDecision.approverGsit
			this.approverScoringDecision.approverUkkp = this.approvalUkkp?.userId? { name: this.approvalUkkp?.userName, userName: this.approvalUkkp?.userId } : this.approverScoringDecision.approverUkkp
			this.approverScoringDecision.approverDlog = this.approvalDlog?.userId? { name: this.approvalDlog?.userName, userName: this.approvalDlog?.userId } : this.approverScoringDecision.approverDlog

		}
	},
	async mounted() {
		await this.$store.dispatch('dashboard/getMenu')
    await this.$store.dispatch('projectDetail/getScoringVendorApproval', { rfpId: this.$route.params.projectId })
		//add spp to check if tab SPP should be shown/not
		await this.$store.dispatch('spp/getSpp', { rfpId: this.$route.params.projectId })

		this.setApprover()
	},
	components: {
		Detail,
		PraQualification,
		Qualification,
		Scoring,
		Negotiation,
		ProcurementApproval,
		SktLoi,
		Spp
	},
}


</script>