<template>
	<div class="">
		<ButtonGista v-if="!roleUser" @click="toggleOpen"
			color="secondary"
			:disabled="proposalInformation?.isCanceled"
			:customClass="projectDetailContainerWidth < 640 ? 'w-full' : 'w-56'">
			Ask For Changes
		</ButtonGista>
		<GistaModal 
			:value="isOpen"
			width="2/3"
			@input="toggleClose"
			customClass="bg-white top-0 bottom-0 mt-6 mb-8 max-w-7xl">
			<div v-if="!roleUser" class="px-6 py-6">
				<ValidationObserver v-slot="{ handleSubmit }">
				
					<div class="flex justify-center mb-6">
						<h4 class="text-3xl font-semibold">Proposal Rejection</h4>
					</div>
					<div class="w-full mb-6">
						<ValidationProvider name="revision reason"
							rules="required|max:300"
							v-slot="{ errors }">
							<TextareaGista label="Revision Reason"
								v-model="form.revisionReason"
								:mandatory="true"
								:error="!!errors[0]">
								<template #message>
									<p>{{ errors[0] }}</p>
								</template>
							</TextareaGista>
						</ValidationProvider>
					</div>

					<div class="flex justify-end">
						<ButtonGista @click="handleSubmit(clickSendEmail)"
							color="secondary"
							customClass="w-full sm:w-max">
							Send Email</ButtonGista>
					</div>
				</ValidationObserver>
			</div>
			
		</GistaModal>
	</div>
</template>
<script>
//proposal revision => proposal rejection
import { LOG, OPR } from '@/core/constant/applicationRoleName.js'
import { MESSAGE_SUCCESS_ASK_FOR_CHANGES } from '@/core/constant/successMessage'

export default {
	name: 'ProposalRevisionITP',
	props: ['qualification', 'index'],
	data() {
		return {
			isOpen: false,
			form: {
				revisionReason: '',
				vendorCandidateId: '',
			},
		}
	},
	computed: {
		menu() {
			return this.$store.state.dashboard.menu
		},
		roleUser() {
			const roleName = this.menu[0]?.applicationRoleName
			return roleName?.includes(OPR) && !roleName?.includes(LOG)
		},
		userProfile() {
			return this.$store.state.dashboard.userProfile
		},
		proposalInformation() {
			return this.$store.state.projectDetail.proposalInformation
		},
		qualificationsRevision() {
			return this.$store.state.qualification.qualificationsRevision
		},
		qualificationsRevisionTech() {
			return this.$store.state.qualification.qualificationsRevisionTech
		},
		menuQualificationActionRevision() {
			return this.$store.getters['dashboard/menuQualificationActionRevision']
		},
		menuQualificationActionAskRevision() {
			return this.$store.getters['dashboard/menuQualificationActionAskRevision']
		},
		modal: {
			get() {
				return this.$store.state.modal.modal
			},
			set(value) {
				this.$store.commit('modal/SET_MODAL', value)
			}
    	},
		projectDetailContainerWidth() {
			return this.$store.state.width.projectDetailContainerWidth
		},
	},
	methods: {
		toggleOpen() {
			this.isOpen = !this.isOpen
		},
		toggleClose() {
			this.isOpen = !this.isOpen
		},
		async clickSendEmail() {
			try {
				this.modal.modalLoading = true
				const form = {
					revisionReason: this.form.revisionReason,
					vendorCandidateId: this.qualification?.vendorCandidateId
				}
				const response = await this.$store.dispatch('qualification/putQualificationsProposalRevision', form)
				if (response?.status < 300 && response?.status >= 200) {
					this.isOpen = false
					this.modal.modalLoading = false
					this.modal.modalSuccessSaved = { value: true, title: MESSAGE_SUCCESS_ASK_FOR_CHANGES }
					//reset state
					this.form = {
						revisionReason: '',
						vendorCandidateId: this.form.vendorCandidateId,
					}
					this.$store.dispatch('qualification/getQualifications', this.$route.params.projectId)
					this.$store.dispatch('projectLog/refetchProjectLogByRfpId', this.$route.params.projectId)
					return
				}
				this.$store.dispatch('modal/throwError', { response })
			} catch (error) {
				console.log(error);
				this.modal.modalLoading = false
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
			}
		},

	},

	mounted() {
		if (this.roleUser) {
			this.$store.dispatch('qualification/getQualificationsProposalRevisionTech', this.qualification?.vendorCandidateId)
		} else {
			this.$store.dispatch('qualification/getQualificationsProposalRevision', this.qualification?.vendorCandidateId)
		}
		console.log('vendor candidate', this.qualfication?.vendorCandidateId);
		this.form.vendorCandidateId = this.qualification?.vendorCandidateId
		this.form.revisionReason = this.qualification?.rejectNotes
	}
}

</script>