<template>
	<div class="w-full border-b border-gray-light p-6">
		<div class="flex justify-between mb-4 cursor-pointer" @click="toggleCollapse">
			<div class="flex">
				<span class="icon-check text-2xl text-gray-dark mr-6"></span>
				<h4 class="text-base text-gray-dark font-semibold">SELECTED ({{vendorDetailProjectSelected.length}})</h4>
			</div>
			<div>
				<p :class="collapseSelected ? 'rotate-180' : 'rotate-0'"
					class="icon-chevron-down text-gray text-2xl cursor-pointer transform transition ease-in"></p>
			</div>
		</div>
		<div v-show="!collapseSelected" class="overflow-auto">
			<div v-if="vendorDetailProjectSelected.length === 0" class="flex justify-center py-6">
				<p class="text-gray font-medium">No Data</p>
			</div>
			<Card v-for="(n,i) in vendorDetailProjectSelected" :key="i" class="md:width-custom w-full flex flex-col md:flex-row justify-between border border-gray-lightest items-center mb-6">
				<div class="flex flex-col w-full md:w-3/4">
					<div class="flex flex-wrap mb-3 flex-col md:flex-row">
						<div class="md:mb-0 mb-1.5">
							<label class="text-sm">Project ID</label>
							<h5 class="text-primary-darkest">{{n.projectId || '-'}}</h5>
						</div>
						<div class="line-vertical"></div>
						<div class="md:mb-0 mb-1.5">
							<label class="text-sm">No. {{n.projectRequestType}}</label>
							<h5>{{n.noRequest || '-'}}</h5>
						</div>
						<div class="line-vertical"></div>
						<div class="">
							<label class="text-sm">Project Name</label>
							<h5>{{n.projectName}}</h5>
						</div>
					</div>
					<div class="flex flex-wrap">
						<div class="flex items-center mb-3">
							<span class="icon-user text-3xl mr-3.5"></span>
							<div class="ml-2.5">
								<label class="text-xs">Technical Coordinator</label>
								<h5>{{n.technicalCoordinatorName}}</h5>
							</div>
						</div>
						<div class="line-vertical"></div>
						<div class="flex items-center mb-3">
							<span class="icon-calendar text-3xl mr-3.5"></span>
							<div class="">
								<label class="text-xs">Date Created</label>
								<h5>{{n.createdAt}}</h5>
							</div>
						</div>
					</div>
				</div>
				<div class="w-full md:w-1/4 md:mt-0 mt-1.5  flex justify-end">
					<ButtonGista :isDark="false"
						color="primary"
						type="secondary"
						customClass="w-full md:w-max"
						@click="seeDetailProject(n)">
						See details
					</ButtonGista>
				</div>
			</Card>
		</div>
	</div>
</template>
<script>
import Card from '@/core/components/custom/Card.vue'
import { PATH_ROOT, PROJECT_DETAIL, QUOTATION_DETAIL } from '@/core/constant/routeName'


export default {
	name: "Projects",
	props: {
		vendor: Object,
	},
	data() {
		return {
			collapseSelected: false,
		}

	},
	computed: {
		vendorDetailProjectSelected() {
      return this.$store.state.vendor.vendorDetailProjectSelected
    },
		modal: {
			get() {
				return this.$store.state.modal.modal
			},
			set(value) {
				this.$store.commit('modal/SET_MODAL', value)
			}
		}
	},
	methods: {
		toggleCollapse() {
			this.collapseSelected = !this.collapseSelected
		},
		seeDetailProject(project) {
			this.$emit('closeModal')
			setTimeout(() => {
				if (project.projectRequestType === 'RFP') {
					this.$router.push(`/${PATH_ROOT}/${PROJECT_DETAIL}/${project.projectRequestId}`).catch(() => ({}))
				} else {
					this.$router.push(`/${PATH_ROOT}/${QUOTATION_DETAIL}/${project.projectRequestId}`).catch(() => ({}))
				}
			}, 200)
		}

	},
	components: {
		Card
	}
}
</script>
<style scoped>
.width-custom {
	min-width: 50rem;
}
</style>