<template>
  <div>
    <div v-if="!loadingGetNegotiationList && menuRfpNegotiation?.canRead" class="lg:overflow-x-auto">
      <Card v-for="(item, i) in negotiationList"
        :key="i"
        class="border border-gray-lightest mt-6 px-6 py-3.5 " :class="projectDetailContainerWidth > 1024 ? 'min-w' : ''">
        <div class="flex mb-3.5" :class="projectDetailContainerWidth > 1024 ? 'flex-row' : 'flex-col'">
          <div class="pr-4" :class="`${projectDetailContainerWidth > 1024 && (item.localPartners.length === 0 || allLocalPartnerEliminated(i))? 'border-r border-gray-lightest w-2/4' : 'w-full'}`">
            <div class="w-full mb-1 flex flex-wrap">
              <label class="text-sm text-tertiary">Vendor #{{item.vendorSequence}}</label>
              <label v-if="item.isNewVendor" class="text-xs text-success bg-success-lightest rounded py-1 px-2 ml-3.5">New Vendor</label>
            </div>
            <h5 class="text-base text-gray-darkest">{{item.vendorName}}</h5>
          </div>
          <div :class="projectDetailContainerWidth > 1024 ? 'w-2/4 pl-4' : ''" v-if="item.localPartners.length === 0 || allLocalPartnerEliminated(i)">
            
            <div class="flex w-full" :class="projectDetailContainerWidth > 640 ? 'mt-0' : 'mt-3.5 flex-col'" >
              <div :class="projectDetailContainerWidth > 640 ? 'w-3/5' : ''">
                <label class="text-sm text-gray">Negotiation status</label>
                <div v-if="item?.isRequiredUploadOffer" class="w-max mt-2.5 flex items-center rounded-md py-2 px-2.5" :class="negotiationStatusBg(item.previousNegotiationStatus)">
                  <span class="mr-3 text-2xl font-bold cursor-pointer icon-tag" :class="negotiationStatusText(item.previousNegotiationStatus)"></span>
                  <h5 class="text-base" :class="negotiationStatusText(item.previousNegotiationStatus)">{{ item.previousNegotiationStatusText }}</h5>
                </div>
                <div v-else class="w-max mt-2.5 flex items-center rounded-md py-2 px-2.5" :class="negotiationStatusBg(item.negotiationStatus)">
                  <span class="mr-3 text-2xl font-bold cursor-pointer icon-tag" :class="negotiationStatusText(item.negotiationStatus)"></span>
                  <h5 class="text-base" :class="negotiationStatusText(item.negotiationStatus)">{{ item.negotiationStatusText }}</h5>
                </div>
              </div>
              <div class="flex items-center " :class="projectDetailContainerWidth < 640 ? 'w-full' : 'justify-end w-2/5'">
                <div class="flex items-center h-full " :class="projectDetailContainerWidth > 640 ? 'pl-4 border-l border-gray-lightest' : 'mt-3.5'">
                  <ButtonGista :isDark="false"
                    color="primary"
                    type="secondary"
                    customClass="truncate text-gray"
                    :disabled="!roleOprItp && disableNoInitialOffer(item.negotiationStatus)"
                    @click="seeDetail(item.vendorCandidateId)">
                    See details
                  </ButtonGista>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-for="(local, j) in item.localPartners" :key="j" class="border-l border-gray-light " :class="projectDetailContainerWidth < 768 ? 'flex-col' : ''">
          <div class="flex py-2.5 ml-4  " :class="`${projectDetailContainerWidth < 1024 ? 'flex-col' : ''} ${j !== item.localPartners.length - 1? 'border-b border-dashed border-gray-light' : ''}`">
            <div class="py-3.5" :class="projectDetailContainerWidth < 1024 ? '' : 'border-r border-gray-lightest w-2/4'">
              <div class="w-full mb-1 flex flex-wrap">
                <label class="text-sm text-tertiary">Local Partner #{{local.vendorSequence}}</label>
                <label v-if="local.isNewVendor" class="text-xs text-success bg-success-lightest rounded py-1 px-2 ml-3.5">New Vendor</label>
              </div>
              <h5 class="text-base text-gray-darkest">{{local.vendorName}}</h5>
            </div>
            <div :class="[projectDetailContainerWidth < 640 ? 'flex-col gap-3.5' : 'flex-row gap-0', projectDetailContainerWidth < 1024 ? '' : 'w-2/4 pl-4']">
              <div class="w-full flex" :class="projectDetailContainerWidth < 640 ? 'flex-col gap-3.5' : 'flex-row gap-0'" v-if="!local.isEliminated">
                <div :class="projectDetailContainerWidth < 640 ? 'w-full' : 'w-3/5' ">
                  <label class="text-sm text-gray">Negotiation status</label>
                  <div v-if="local?.isRequiredUploadOffer" class="w-max mt-2.5 flex items-center rounded-md py-2 px-2.5" :class="negotiationStatusBg(local.previousNegotiationStatus)">
                    <span class="mr-3 text-2xl font-bold cursor-pointer icon-tag" :class="negotiationStatusText(local.previousNegotiationStatus)"></span>
                    <h5 class="text-base" :class="negotiationStatusText(local.previousNegotiationStatus)">{{ local.previousNegotiationStatusText }}</h5>
                  </div>
                  <div v-else class="w-max mt-2.5 flex items-center rounded-md py-2 px-2.5" :class="negotiationStatusBg(local.negotiationStatus)">
                    <span class="mr-3 text-2xl font-bold cursor-pointer icon-tag" :class="negotiationStatusText(local.negotiationStatus)"></span>
                    <h5 class="text-base" :class="negotiationStatusText(local.negotiationStatus)">{{ local.negotiationStatusText }}</h5>
                  </div>
                </div>
                <div class="flex items-center" :class="projectDetailContainerWidth < 640 ? 'w-full' : 'justify-end w-2/5'">
                  <div class="flex items-center h-full border-gray-lightest" :class="projectDetailContainerWidth < 640 ? '' : 'border-l pl-4'">
                    <ButtonGista :isDark="false"
                      color="primary"
                      type="secondary"
                      customClass="truncate text-gray"
                      :disabled="!roleOprItp && menuRfpNegotiationDetails?.canRead && disableNoInitialOffer(local.negotiationStatus)"
                      @click="seeDetail(local.vendorCandidateId)">
                      See details
                    </ButtonGista>
                  </div>
                </div>
              </div>
              <div class="flex items-center w-full h-full" v-else>
                <p class="text-gray">Eliminated - Qualification</p>
              </div>
            </div>

          </div>
        </div>
      </Card>
    </div>
    <div v-else>
      <Card v-for="n in 2" :key="n" class="flex mt-6 border border-gray-lightest">
        <div class="w-full">
          <div class="animate-pulse">
            <div class="grid grid-cols-2 gap-4">
              <div class="grid grid-cols-1 gap-4 border-r border-gray-lightest">
                <div class="w-40 h-4 rounded bg-gray-light"></div>
                <div class="w-40 h-4 rounded bg-gray-light"></div>
              </div>
              <div class="grid grid-cols-1 gap-4">
                <div class="w-40 h-4 rounded bg-gray-light"></div>
                <div class="w-40 h-4 rounded bg-gray-light"></div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import Card from '@/core/components/custom/Card.vue';
import { LOG, OPR, } from '@/core/constant/applicationRoleName.js'

export default {
  name: 'NegotiationTab',
  data() {
    return {
      negotiationStatus: {
        none: 'NONE',
        initialOffer: 'INITIAL_OFFER',
        negotiation: 'NEGOTIATION',
        finalDeal: 'FINAL_DEAL'
      }
    }
  },
  computed: {
    menu() {
			return this.$store.state.dashboard.menu
		},
    menuRfpNegotiation() {
      return this.$store.getters['dashboard/menuRfpNegotiation']
    },
    menuRfpNegotiationDetails() {
			return this.$store.getters['dashboard/menuRfpNegotiationDetails']
		},
    roleOprItp() {
			const roleName = this.menu[0]?.applicationRoleName
      return roleName?.includes(LOG) && roleName?.includes(OPR)
		},
    vendorDetail: {
      get() {
        return this.$store.state.negotiation.vendorDetail
      },
      set(value) {
        this.$store.commit('negotiation/SET_VENDOR_DETAIL', value)
      }
    },
    loadingGetNegotiationList: {
      get() {
        return this.$store.state.negotiation.loadingGetNegotiationList
      },
      set(value) {
        this.$store.commit('negotiation/SET_LOADING_GET_NEGOTIATION_LIST', value)
      }
    },
    negotiationList() {
      return this.$store.state.negotiation.negotiationList.vendors
    },
    projectDetailContainerWidth() {
        return this.$store.state.width.projectDetailContainerWidth
    },

  },
  methods: {
    negotiationStatusBg(negStatus) {
      switch (negStatus) {
        case this.negotiationStatus.none:
          return 'bg-gray-lightest'
        case this.negotiationStatus.initialOffer:
          return 'bg-warning-lightest'
        case this.negotiationStatus.negotiation:
          return 'bg-secondary-lightest'
        case this.negotiationStatus.finalDeal:
          return 'bg-success-lightest'
      }
    },
    negotiationStatusText(negStatus) {
      switch (negStatus) {
        case this.negotiationStatus.none:
          return 'text-gray-darkest'
        case this.negotiationStatus.initialOffer:
          return 'text-warning-darkest'
        case this.negotiationStatus.negotiation:
          return 'text-secondary-darkest'
        case this.negotiationStatus.finalDeal:
          return 'text-success-darkest'
      }
		},
    seeDetail(vendorCandidateId) {
      this.vendorDetail = vendorCandidateId
      this.$router.push({ name: 'projectNegotiation', query: { vendor: vendorCandidateId }}).catch(() => ({}))
    },
    disableNoInitialOffer(negStatus) {
      return negStatus === this.negotiationStatus.none
    },
    allLocalPartnerEliminated(i) {
      return this.negotiationList[i].localPartners.every(e => e.isEliminated)
    },
  },
  async mounted() {
		this.loadingGetNegotiationList = true
		const response = await this.$store.dispatch('negotiation/getNegotiation', { rfpId: this.$route.params.projectId })
    if (response?.status === 200) {
      this.loadingGetNegotiationList = false
    }

	},
  components: {
    Card,
}
}
</script>

<style scoped>
.min-w {
  min-width: 52rem;
}
</style>