<template>
  <div class="grid gap-6 w-full" :class="`${projectDetailContainerWidth > 720? 'grid-cols-2': 'grid-cols-1'}`">
    <Card v-for="n in 2" :key="n" class="border border-gray-lightest flex justify-between items-center w-full">
      <div class="w-full animate-pulse">
        <div class="h-4 bg-gray-light rounded max-w-xs mb-2"></div>
        <div class="h-4 bg-gray-light rounded max-w-xs mb-12"></div>
        <div class="h-4 bg-gray-light rounded w-full mb-2"></div>
        <div class="h-4 bg-gray-light rounded w-full mb-2"></div>
        <div class="h-4 bg-gray-light rounded w-full mb-2"></div>
        <div class="h-4 bg-gray-light rounded w-full mb-2"></div>
        <div class="h-4 bg-gray-light rounded w-full mb-12"></div>
        <div class="h-4 bg-gray-light rounded w-full mb-2"></div>
        <div class="h-4 bg-gray-light rounded w-full mb-12"></div>
        <div class="h-4 bg-gray-light rounded w-full mb-2"></div>
        <div class="h-4 bg-gray-light rounded w-full mb-12"></div>
        <div class="flex justify-center">
          <div class="h-12 bg-gray-light rounded w-full max-w-xs mb-2"></div>
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
import Card from '@/core/components/custom/Card.vue'

export default {
  name: 'ScoringDecisionLoading',
  computed: {
    projectDetailContainerWidth() {
			return this.$store.state.width.projectDetailContainerWidth
		},
  },
  components: {
    Card
  }
}
</script>