<template>
  <div>
    <div class="flex mt-6">
      <div class="w-full">
        <div class="animate-pulse">
          <div class="flex justify-center">
            <div class="h-12 bg-gray-light rounded max-w-40 mb-3.5"></div>
          </div>
          <ScoringVendorLoading />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScoringVendorLoading from './ScoringVendorLoading.vue';


export default {
    name: "ScoringLoading",
    components: { ScoringVendorLoading }
}
</script>
<style scoped>
.max-w-40 {
  max-width: 30rem;
  width: 30rem;
}
</style>