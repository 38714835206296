<template>
  <div class="border-b " :class="`${error? 'border-error': 'border-gray'}`">
    <input 
      @input="changeInput"
      @keypress="validationNumber"
      type="text" 
      placeholder="0"
      :value="inputValue(value)"
      :title="error"
      class="w-full text-sm border-none focus:ring-0 text-center p-0"
      :class="`${error?'text-error':'text-gray-darkest'} ${bgColor}`"
      >
  </div>
</template>

<script>
export default {
  name: "InputVendor",
  props: ['value', 'bobot', 'error', 'bgColor'],
  data() {
    return {}
  },
  watch: {
    error() {
      console.log(this.error);
    }
  },
  methods: {
    validationNumber(e) {
      let keyCode = e.keyCode
      if (keyCode < 48 || keyCode > 57) {
        e.preventDefault();
      }
    },
    blurInput() {
      if (+this.value > +this.bobot) {
        console.log(this.value, this.bobot);
        this.$emit('input', this.bobot)
      }
    },
    inputValue(value) {
      return value > 0 ? value : ''
    },
    changeInput(e) {
      this.$emit('input', e.target.value)
    }
  }
}
</script>