<template>
	<div class="">
		<div class="w-full " :class="projectDetailContainerWidth < 988 ? 'overflow-x-auto' : ''">
			<div class="w-full">
				<table class="w-full" aria-describedby="vendorCandidateScoring">
					<thead class="sticky top-0 z-20 h-16 py-1 bg-white">
						<tr class="">
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-16 pl-2 pr-8 text-sm font-medium text-left border-b-2 min-w-vendor text-gray-darkest border-primary">Vendor Candidate</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-16 pr-8 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">Status</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-16 pr-8 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">Scoring Result</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-16 pr-8 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">Scoring Escrow</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center justify-center h-16 px-8 text-sm font-medium text-center border-b-2 text-gray-darkest border-primary">BAN</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center justify-center h-16 px-8 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">Due Diligence</h5>
							</th>
						</tr>
					</thead>
					<tbody v-for="(n,i) in dataProcurementApproval.candidateScoringList" :key="i">
						<tr :class="`${n.localPartnerList?.length ? '' : 'border-b border-gray-lightest'}`">
							<td class="pl-2">
								<div class="pr-8" :class="n.localPartnerList?.length ? 'pt-4' : ''">
									<h5 class="text-sm text-tertiary">Vendor #{{ n.vendorSeq }}</h5>
									<h5 class="text-sm text-gray-darkest">{{ n.vendorName}}</h5>
								</div>
							</td>
							<td>
								<div v-if="n.localPartnerList?.length && allNotEliminated(i)"></div>
								<div v-else-if="(!isSubmitted || isRejected && !readOnly) && !['Eliminasi', 'Tidak maju ke tahap negosiasi'].includes(n.candidateScoringDetails.status)" class="mr-8 py-3.5 min-w-status">
									<SelectSolutip
										:options="[TERPILIH, TIDAK_TERPILIH]"
										@change="changeStatus($event, i)"
										size="small"
										:value="n.candidateScoringDetails.status"
                    :error="!!n?.error"
										@isOptionsExpanded="isOptionsExpanded($event, i, null)"
									>
										<template #selected>
											{{ n.candidateScoringDetails.status }}
										</template>
									</SelectSolutip>
									<div v-if="!!n.error">
											<p class="mt-1 text-xs text-error">{{ n.error === true ? MESSAGE_EMPTY_FIELD : n.error }}</p>
									</div>
								</div>
								<h5 v-else class="py-6 pr-8 text-sm truncate text-gray-darkest">{{ n.candidateScoringDetails.status }}</h5>
							</td>
							<td>
								<h5 class="pr-8 text-sm text-gray-darkest">{{ n.candidateScoringDetails.scoringResult }}</h5>
							</td>
							<td>
								<h5 class="pr-8 text-sm text-gray-darkest">{{  n.candidateScoringDetails.scoringEscrow }}</h5>
							</td>
							<td>
								<div class="flex items-center justify-center">
									<div v-if="n.localPartnerList?.length && allNotEliminated(i)"></div>
									<button-gista
										@click="clickViewBan(n.candidateScoringDetails.negotiationIdForBan)"
										size="small"
										type="secondary"
										color="primary"
										customClass="w-16 py-1.5"
										v-else-if="n.candidateScoringDetails.negotiationIdForBan"
										>
										<span class="icon-eye"></span>
									</button-gista>
									<button-gista
										size="small"
										type="secondary"
										color="gray"
										disabled
										customClass="w-16 py-1.5 text-xs"
										v-else
										>
										<p class="text-xs">N/A</p>
									</button-gista>
								</div>
							</td>
							<td>
								<div class="flex items-center justify-center" v-if="n.candidateScoringDetails?.dueDiligenceFileName || n.candidateScoringDetails?.dueDiligenceDocumentNo">
									<button-gista
										@click="clickViewDueDiligence(n)"
										size="small"
										type="secondary"
										color="primary"
										customClass="w-16 py-1.5"
										>
										<span class="icon-eye"></span>
									</button-gista>
								</div>
								<div class="flex items-center justify-center" v-else>
									<h5 class="text-sm text-gray-darkest">-</h5>
								</div>
							</td>
						</tr>
						<tr :class="`${j === n.localPartnerList?.length - 1 ? 'border-b border-gray-lightest' : ''}`" v-for="(local, j) in n.localPartnerList" :key="j">
							<td class="pl-2">
								<div class="pr-8 pl-3.5 border-l border-gray-light">
									<h5 class="text-sm text-tertiary">Local Partner #{{ local.vendorSeq }}</h5>
									<h5 class="text-sm text-gray-darkest">{{ local.vendorName }}</h5>
								</div>
							</td>
							<td>
								<div v-if="(!isSubmitted || isRejected && !readOnly) && !['Eliminasi', 'Tidak maju ke tahap negosiasi'].includes(local.candidateScoringDetails.status)" class="mr-8 py-3.5 min-w-status">
									<SelectSolutip
										:options="[TERPILIH, TIDAK_TERPILIH]"
										@change="changeStatusLocal($event, i, j)"
										:value="local.candidateScoringDetails.status"
                    :error="!!local.error"
										@isOptionsExpanded="isOptionsExpanded($event, i, j)"
									>
										<template #selected>
											{{ local.candidateScoringDetails.status }}
										</template>
									</SelectSolutip>
									<div v-if="!!local?.error">
											<p class="mt-1 text-xs text-error">{{ local.error === true ? MESSAGE_EMPTY_FIELD : local.error }}</p>
									</div>
								</div>
								<h5 v-else class="py-6 pr-8 text-sm truncate text-gray-darkest">{{ local.candidateScoringDetails.status }}</h5>
							</td>
							<td></td>
							<td></td>
							<td>
								<div class="flex items-center justify-center">
									<div v-if="local.localPartnerList?.length"></div>
									<button-gista
										@click="clickViewBan(local.candidateScoringDetails.negotiationIdForBan)"
										size="small"
										type="secondary"
										color="primary"
										customClass="w-16 py-1.5"
										v-else-if="local.candidateScoringDetails.negotiationIdForBan"
										>
										<span class="icon-eye"></span>
									</button-gista>
									<button-gista
										size="small"
										type="secondary"
										color="gray"
										disabled
										customClass="w-16 py-1.5 text-xs"
										v-else
										>
										<p class="text-xs">N/A</p>
									</button-gista>
								</div>
							</td>
							<td>
								<div class="flex items-center justify-center" v-if="local.candidateScoringDetails?.dueDiligenceFileName || local.candidateScoringDetails?.dueDiligenceDocumentNo">
									<button-gista
										@click="clickViewDueDiligence(local)"
										size="small"
										type="secondary"
										color="primary"
										customClass="w-16 py-1.5"
										>
										<span class="icon-eye"></span>
									</button-gista>
								</div>
								<div class="flex items-center justify-center" v-else>
									<h5 class="text-sm text-gray-darkest">-</h5>
								</div>
							</td>
						</tr>
					</tbody>
					<tbody>
						<tr v-if="projectDetailContainerWidth < 988 && addHeight" :class="addHeight"></tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import { MESSAGE_EMPTY_FIELD } from '@/core/constant/errorMessage.js'
import SelectSolutip from "@/core/components/custom/Select"
import { TERPILIH, TIDAK_TERPILIH } from '@/core/constant/procurementApproval.js'

export default {
	name: 'VendorCandidateScoringTable',
	props: ['readOnly'],
    data() {
			return {
				MESSAGE_EMPTY_FIELD,
				TERPILIH,
				TIDAK_TERPILIH,
				addHeight: ''
			}
    },
	computed: {
		selectedCandidate: {
			get() {
					return this.$store.state.procurementApproval.selectedCandidate
			},
			set(value) {
					this.$store.commit('procurementApproval/SET_SELECTED_CANDIDATE', value)
			}
		},
		errorVendorCandidate: {
			get() {
					return this.$store.state.procurementApproval.errorVendorCandidate
			},
			set(value) {
					this.$store.commit('procurementApproval/SET_ERROR_VENDOR_CANDIDATE', value)
			}
		},
		dataProcurementApproval: {
      get() {
        return this.$store.state.procurementApproval.dataProcurementApproval
      },
      set(value) {
        this.$store.commit('procurementApproval/SET_DATA_PROCUREMENT_APPROVAL', value)
      }
    },
		isSubmitted() {
      return this.dataProcurementApproval?.isSubmitted
    },
		logApproval() {
      return this.$store.state.procurementApproval.logApproval
    },
    isRejected() {
      return this.dataProcurementApproval?.isRejected
    },
		modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    },
		projectDetailContainerWidth() {
			return this.$store.state.width.projectDetailContainerWidth
		},
	},
	methods: {
		changeStatus(e,i) {
			this.dataProcurementApproval.candidateScoringList[i].candidateScoringDetails.status = e
			const indexNego = this.dataProcurementApproval.negotiationResultList.findIndex(el => el.candidateId === this.dataProcurementApproval.candidateScoringList[i].candidateId)
			this.dataProcurementApproval.negotiationResultList[indexNego].negotiationResultDetails.resultItemList = this.dataProcurementApproval.negotiationResultList[indexNego].negotiationResultDetails.resultItemList?.map(el => ({ ...el, isSelected: (e === TERPILIH) }))
			this.dataProcurementApproval.negotiationResultList[indexNego].isAllSelected = (e === TERPILIH)
			this.getProjectProcurementApproval()
		},
		changeStatusLocal(e, i, j) {
			this.dataProcurementApproval.candidateScoringList[i].localPartnerList[j].candidateScoringDetails.status = e
			const indexNego = this.dataProcurementApproval.negotiationResultList.findIndex(el => el.candidateId === this.dataProcurementApproval.candidateScoringList[i].candidateId)
			const indexLocal = this.dataProcurementApproval.negotiationResultList[indexNego].localPartnerList.findIndex(el => el.candidateId === this.dataProcurementApproval.candidateScoringList[i].localPartnerList[j].candidateId)
			this.dataProcurementApproval.negotiationResultList[indexNego].localPartnerList[indexLocal].negotiationResultDetails.resultItemList = this.dataProcurementApproval.negotiationResultList[indexNego].localPartnerList[indexLocal].negotiationResultDetails.resultItemList?.map(el => ({ ...el, isSelected: (e === TERPILIH) }))
      this.dataProcurementApproval.negotiationResultList[indexNego].localPartnerList[indexLocal].isAllSelected = (e === TERPILIH)
			this.getProjectProcurementApproval()
		},
		async getProjectProcurementApproval() {
			this.checkErrSelect()
			await this.$store.dispatch('procurementApproval/getApprovalProcurementAll', { isMaximize: false })
		},
    checkErrSelect() {
      this.dataProcurementApproval.candidateScoringList.map(e => {
        e.error = ''
        if(e.localPartnerList) {
          e.localPartnerList.map(local => {
            local.error = ''
          })
        }
      })
    },
		async clickViewBan(negotiationId) {
			this.modal.modalLoading = true
			const response = await this.$store.dispatch('negotiation/getBanReport', { negotiationId })
			if (response?.status < 300) {
				this.modal.modalLoading = false
			}
    },
		clickViewDueDiligence(n) {
			const filename = n.candidateScoringDetails?.dueDiligenceFileName
			const documentNo =  n.candidateScoringDetails?.dueDiligenceDocumentNo
			this.$store.dispatch('file/viewAttachment', { filename, documentNo })
		},
		allNotEliminated(i) {
			return !this.dataProcurementApproval?.candidateScoringList[i]?.localPartnerList?.every(e => ['Eliminasi', 'Tidak maju ke tahap negosiasi'].includes(e.candidateScoringDetails.status))
		},
		isOptionsExpanded(e, i, j) {
			if (this.dataProcurementApproval.candidateScoringList?.length -1 === i && j === null) {
				this.addHeight = 'h-16'
			}
			if (this.dataProcurementApproval.candidateScoringList?.length -1 === i &&  this.dataProcurementApproval.candidateScoringList[i].localPartnerList?.length - 1 === j && j !== null) {
				this.addHeight = 'h-16'
			}
			if (e === false) {
				setTimeout(() => {
					this.addHeight = ''
				}, 300)
			}
		}
	},
	mounted() {
	},
	components: {
		SelectSolutip
	}
}
</script>
<style scoped>
.max-h-custom {
	max-height: 40rem;
}
.min-w-vendor {
	min-width: 16rem;
}
.min-w-status {
	min-width: 16rem;
}

</style>
