<template>
	<div class="w-full">
		<div class="flex mb-6 gap-6 flex-row-reverse">
			<div class="flex-none flex items-center" @click="toggleChangeShowRemarks">
				<div class="flex" v-if="!showRemarks">
					<span class="icon-eye text-lg cursor-pointer text-gray hover:text-gray-dark"></span>
					<p class="text-sm font-semibold cursor-pointer text-gray hover:text-gray-dark pl-2.5">View Remarks</p>
				</div>
				<div class="flex" v-else>
					<span class="icon-eye-off text-lg cursor-pointer text-gray hover:text-gray-dark"></span>
					<p class="text-sm font-semibold cursor-pointer text-gray hover:text-gray-dark pl-2.5">Hide Remarks</p>
				</div>
			</div>
			<div class="flex-auto flex" v-if="!scoringPriceLogApproval?.isSubmittedScoringPrice && !menuRfpNegotiationScoringPrice?.canUpdate">
				<span class="icon-info flex items-center text-warning-darkest text-lg cursor-pointer text-gray hover:text-gray-dark"></span>
				<p class="font-medium text-warning-darkest pl-3.5">Proses Scoring Price sedang dilaksanakan oleh OPR GSIT/KP</p>
			</div>
		</div>
		<ValidationObserver ref="scoringVendor">
			<div v-if="!loadingScoringPrice" class="overflow-x-auto">
				<div class="w-full">
					<table class="w-full h-px text-sm" id="scoringVendorTable" aria-describedby="scoringVendor">
						<thead>
							<tr>
								<th scope="col" :colspan="colspan+1+scoringVendorTeknisTree?.[0]?.points?.[0]?.scores.length" class="lg:sticky left-0 p-0 font-semibold text-gray-darkest text-lg bg-white py-2.5 px-2">SCORING TECHNICAL</th>
							</tr>
							<tr class="bg-white border-t border-gray-light">
								<th scope="col" class="lg:sticky left-0 p-0 font-medium text-gray-darkest text-left bg-white w-40"><p class="w-40 py-2.5 px-2 break-words">Criteria</p></th>
								<th v-if="canEditRemarks" scope="col" class="lg:sticky p-0 font-medium text-gray-darkest text-left bg-white w-24 left-40"><p class="w-24 py-2.5 px-2 break-words">Remarks<span class="text-error">*</span></p></th>
								<th scope="col" class="lg:sticky p-0 font-medium text-gray-darkest bg-white w-24 left-64"><p class="w-24 flex items-center justify-center h-full py-2.5 px-2  border-r border-gray-light">Bobot</p></th>
								<th v-for="(vendor, i) in scoringVendorTeknisTree?.[0]?.points?.[0]?.scores"
										:key="i" scope="col" class="font-medium text-gray-darkest truncate min-w-28 p-0"><p class="py-2.5 px-2">{{  vendor.vendorName  }}</p></th>
							</tr>
						</thead>
						<tbody v-for="(item, idx) in scoringVendorTeknisTree" :key="idx">
							<tr class="font-medium text-left bg-primary text-gray-darkest p-0">
								<td class="lg:sticky left-0 p-0 break-words bg-primary">
									<p class="w-40 py-2.5 px-2">{{  scoringVendorTeknisTree[idx].name  }}</p></td>
								<td v-if="canEditRemarks" class="lg:sticky p-0 break-words bg-primary text-center left-40">
									<div class="w-24 py-2.5 px-2 flex items-center justify-center">
										<span v-if="idx === 3" @click="openModal(scoringVendorTeknisTree2[idx])" class="icon-edit text-lg cursor-pointer hover:text-gray-dark"></span>
										<span v-else class="icon-edit text-lg cursor-not-allowed text-gray"></span>
									</div>
								</td>
								<td class="lg:sticky p-0 left-64" >
									<p class="w-24 flex items-center justify-center h-full py-2.5 px-2 border-r bg-primary border-gray-light">{{  scoringVendorTeknisTree[idx].bobot  }}</p>
								</td>
								<template v-if="!showRemarks" >
									<td v-for="(n, i) in scoringVendorTeknisTree?.[0]?.points?.[0]?.scores"
											:key="i"
											class="py-2.5 px-2 text-center">{{  autoSum(scoringVendorTeknisTree[idx].points, i)  }}</td>
								</template>
								<td v-else :colspan="scoringVendorTeknisTree?.[0]?.points?.[0]?.scores.length" class=" py-2.5 px-2">Remarks</td>
							</tr>
							<tr v-for="(n, i) in scoringVendorTeknisTree[idx].points"
								:key="i+'criteria'+n.rfpScoringTeknisId" class="font-medium text-gray-darkest p-0" :class="bgColorWhitePrimary(i)" >
								<td :colspan="colspan" class="lg:sticky left-0 p-0 break-words" :class="bgColorWhitePrimary(i)">
									<p class="w-full py-2.5 px-2 h-full flex items-center" :class="{ 'border-t border-b border-primary-light': i % 2 !== 0}">{{  n.name  }}</p>
								</td>
								<td class="lg:sticky p-0 left-64" :class="`${bgColorWhitePrimary(i)}`">
									<div class="border-r border-gray-light h-full flex items-center">
										<p class="flex items-center justify-center h-full py-2.5 px-2 w-full" :class="{ 'border-t border-b border-primary-light': i % 2 !== 0 }">{{  n.bobot  }}</p>
									</div>
								</td>
								<template v-if="!showRemarks">
									<td v-for="(col, j) in n.scores"
										:key="j"
										class="p-0"
										>
										<div class="py-2.5 px-2 text-center" :class="`${bgColorWhitePrimary(i)} ${ i % 2 !== 0? 'border-t border-b border-primary-light' : ''}`" >
											<div v-if="col.isEnableScoringTeknis && isEdit && idx === 3">
												<ValidationProvider :name="`Nilai${idx}${i}${j}`"
													:rules="`max_value:${n?.bobot}`"
													v-slot="{ errors }">
													<input-vendor v-model="col.skalaNilai" :bobot="n?.bobot" :error="errors[0]" :bgColor="`${i % 2 !== 0? 'bg-primary-lightest': 'bg-white'}`"></input-vendor>
												</ValidationProvider>
											</div>
											<div v-else>
												{{  col?.skalaNilai || '-' }}
											</div>
										</div>
									</td>
								</template>
								<td v-else-if="i === 0" :colspan="scoringVendorTeknisTree?.[0]?.points?.[0]?.scores.length" :rowspan="scoringVendorTeknisTree[idx].points.length" class="p-0">
									<p class="py-2.5 px-2">{{ scoringVendorTeknisTree2?.[idx].remarks }}</p>
								</td>
							</tr>
							<tr v-if="idx === 3" class="font-medium text-left bg-primary text-gray-darkest p-0">
								<td :colspan="colspan" class="lg:sticky left-0 break-words bg-primary p-0"><p class="w-64 py-2.5 px-2">Total Bobot</p></td>
								<td class="lg:sticky p-0 left-64" ><p class="w-24 py-2.5 px-2 flex items-center justify-center h-full border-r border-gray-light bg-primary">{{totalBobot}}</p></td>
								<td class="p-0 text-center "
										v-for="(n, i) in scoringVendorTeknisTree?.[3]?.points?.[0]?.scores"
										:key="i">{{  sumTotal(i)  }}</td>
							</tr>
							<tr v-if="idx === 2" class="border-t border-b border-gray-light">
								<td :colspan="colspan+1+scoringVendorTeknisTree?.[0]?.points?.[0]?.scores.length" class="lg:sticky left-0 p-0 font-semibold text-gray-darkest text-lg text-center bg-white py-2.5 px-2">SCORING PRICE</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<ScoringVendorLoading v-else/>
			<div class="flex mt-6 mb-3.5 cursor-pointer"
				@click="changeCollapse">
				<div class="flex flex-auto">
					<span class="icon-file text-xl text-gray-dark font-semibold mr-6"></span>
					<h4 class="text-base text-gray-dark font-semibold">ATTACHMENTS</h4>
				</div>
				<div class="flex-none">
					<p :class="!collapse? 'rotate-180': 'rotate-0'"
						class="icon-chevron-down text-gray text-2xl transform transition ease-in"></p>
				</div>
			</div>
			<div v-if="!collapse">
				<div class="mb-3.5" v-for="n,i in attachmentsNonDeleted" :key="i">
					<div>
						<p class="mb-1 text-sm font-medium text-gray">Attachment #{{i+1}}</p>
					</div>
					<div class="flex items-center justify-between border rounded-lg h-14 border-primary">
						<div class="text-base font-medium text-gray p-4 truncate">{{ n.fileName }}</div>
						<div class="p-4 flex items-center">
							<span v-if="isEdit && n.fileRef === scoringPriceRef" class="cursor-pointer text-2xl icon-trash-2 text-error hover: text-error-dark" @click="clickRemoveFileAttachment(n)"></span>
							<span v-else class="cursor-pointer text-2xl icon-eye text-primary hover:text-primary-dark" @click="openFile(n)" ></span>
						</div>
					</div>
				</div>
				<div v-if="isEdit">
					<div class="flex flex-wrap items-center">
						<div class="flex" v-if="maxAttachment">
							<span class="mr-2 icon-file-plus flex items-center text-secondary"></span>
							<input type="file"
								id="upload-file-attachment"
								:ref="scoringPriceRef"
								hidden
								@change="addAttachment($event, scoringPriceRef)" />
							<label for="upload-file-attachment"
								class="mr-4 text-base font-medium underline cursor-pointer text-secondary">
								Add
							</label>
						</div>
						<p class="mr-3.5" :class="{ 'text-error': errorFileType, 'text-gray': !errorFileType }">Max Attachment: 10 </p>
						<p :class="{ 'text-error': errorFileType, 'text-gray': !errorFileType }">Supported file: .pdf .jpg .jpeg .png, max file size: 10 MB</p>
					</div>
				</div>
			</div>
			<div class="flex w-full mt-6">
				<div v-if="menuRfpNegotiationScoringPrice?.canUpdate && !scoringPriceLogApproval?.isSubmittedScoringPrice" class="flex flex-row-reverse w-full flex-wrap gap-6">
					<div class="flex-none w-full sm:w-max">
						<button-gista v-if="!isEdit"
							type="primary"
							color="secondary"
							:disabled="proposalInformation?.isCanceled"
							customClass="w-full sm:w-max"
							@click="clickEditInput">
							{{ inputScoringOrEditScoring? 'Edit Scoring' : 'Input Scoring' }}
						</button-gista>
						<div v-if="isEdit"
							class="flex justify-between w-full">
							<div class="flex">
								<button-gista type="tertiary"
									color="gray"
									customClass="border mr-6"
									:disabled="proposalInformation?.isCanceled"
									@click="clickDiscard">
									Discard
								</button-gista>
								<button-gista type="primary"
									:disabled="proposalInformation?.isCanceled"
									@click="clickSave">
									Save
								</button-gista>
							</div>
						</div>
					</div>
					<div class="flex-auto">
						<ScoringEditPrice v-if="!isEdit" />
					</div>
				</div>
			</div>
			<div class="flex flex-wrap gap-6 py-6 mt-6 border-t-2 border-gray-lightest" v-if="menuRfpNegotiationScoringPriceSubmit?.canUpdate && !scoringPriceLogApproval?.isSubmittedScoringPrice">
        <div class="flex-auto flex items-center w-full sm:w-max">
          <p class="mr-3 text-xl icon-alert-circle text-gray"></p>
          <p class="mr-3 font-medium text-gray">Pastikan semua sudah benar sebelum submit</p>
        </div>
        <div class="flex-none w-full sm:w-max">
          <SubmitScoringPrice @click="clickSubmitScoringPrice" @clickToggle="clickChangeIsOpenModalSubmitScoringPrice($event)" :isOpenModalSubmitScoringPrice="isOpenModalSubmitScoringPrice" :isEnableSubmitScoringPrice="scoringPriceLogApproval?.isEnableSubmitScoringPrice" :disabled="isEdit"></SubmitScoringPrice>
        </div>
      </div>
		</ValidationObserver>
		<GistaModal 
			:value="isOpen"
			width="2/3"
			@input="closeModal"
			customClass="bg-white top-0 bottom-0 mt-6 mb-8 max-w-7xl">
			<ValidationObserver v-slot="{ handleSubmit }">
				<div class="px-6 py-6">
					<div class="flex justify-center mb-6">
						<h4 class="text-3xl font-semibold">{{ data_temp?.name }}</h4>
					</div>
					<div class="w-full mb-6">
						<ValidationProvider name="remarks"
							rules="required|max:500"
							v-slot="{ errors }">
							<TextareaGista label="Remarks"
								v-model="scoringVendorTeknisTree2[data_temp.sequence - 1].remarks"
								:error="!!errors[0]"
								:mandatory="true">
								<template #message>
									<p>{{ errors[0] }}</p>
								</template>
							</TextareaGista>
						</ValidationProvider>
					</div>
					<div class="flex justify-end">
						<ButtonGista @click="handleSubmit(clickSaveRemarks)"
							customClass="w-full sm:w-56">
							Save</ButtonGista>
					</div>
				</div>
			</ValidationObserver>
		</GistaModal>
	</div>
</template>
<script>
import InputVendor from './editInput/inputVendor.vue'
import { MESSAGE_SUCCESS_SCORING_VENDOR, MESSAGE_SUCCESS_SCORING_SUBMIT, MESSAGE_SUCCESS_SCORING_REMARKS } from '@/core/constant/successMessage'
import SubmitScoringPrice from '@/core/components/modal/negotiation/SubmitScoringPrice.vue'
import ScoringVendorLoading from '@/core/components/rfp/loading/ScoringVendorLoading.vue'
import ScoringEditPrice from '@/core/components/modal/ScoringEditPrice.vue'

export default {
	name: "ScoringPrice",
	data() {
		return {
			isEdit: false,
			isEditCriteria: false,
      isOpenModalSubmitScoringPrice: false,
			loadingScoringPrice: false,
			isOpen: false,
			collapse: false,
			errorFileType: false,
			showRemarks: false,
			data_temp: {},
			scoringPriceRef: 'scoringPrice'
		}
	},
	computed: {
		vendorCandidate() {
			return this.$store.state.projectDetail.vendorCandidate.filter(e => e.isActive )
		},
		scoringTeknisCriteria() {
			return this.$store.state.projectDetail.scoringTeknisCriteria
		},
		proposalInformation() {
			return this.$store.state.projectDetail.proposalInformation
		},
		scoringVendorTeknisTree: {
			get() {
				return this.$store.state.negotiation.scoringVendorTeknisTree
			},
			set(value) {
				this.$store.commit('negotiation/SET_SCORING_VENDOR_TEKNIS_TREE', value)
			}
		},
		scoringVendorTeknisTree2: {
			get() {
				return this.$store.state.projectDetail.scoringVendorTeknisTree2
			},
			set(value) {
				this.$store.commit('projectDetail/SET_SCORING_VENDOR_TEKNIS_TREE2', value)
			}
		},
		projectLogRfp() {
			return this.$store.state.projectLog.projectLogRfp
		},
		inputScoringOrEditScoring() {
			return this.scoringVendorTeknisTree.some((e,i) => {
				if (i === 3) {
					return e.points.some((el) => el.scores.some(ele => ele.skalaNilai))
				}
			})
		},
		modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    },
		loading() {
      return this.$store.state.modal.loading
    },
		menuRfpNegotiationScoringPrice() {
			return this.$store.getters['dashboard/menuRfpNegotiationScoringPrice']
		},
		menuRfpNegotiationScoringPriceSubmit() {
			return this.$store.getters['dashboard/menuRfpNegotiationScoringPriceSubmit']
		},
		menuRfpNegotiationScoringPriceLogApproval() {
			return this.$store.getters['dashboard/menuRfpNegotiationScoringPriceLogApproval']
		},
		totalBobot() {
			return this.scoringTeknisCriteria.reduce((a,b) => +a + +b.bobot, 0)
		},
		scoringPriceLogApproval() {
			return this.$store.state.negotiation.scoringPriceLogApproval
		},
		counterInterval() {
			return this.$store.state.projectLog.counterInterval
		},
		counterIntervalApproval() {
			return this.$store.state.negotiation.counterInterval
		},
		attachments: {
			get() {
				return this.$store.state.projectDetail.attachments
			},
			set(value) {
        this.$store.commit('projectDetail/SET_ATTACHMENTS', value)
			}
		},
		attachmentsNonDeleted() {
			return this.attachments.filter(e => !e.isDeleted)
		},
		maxAttachment() {
      return this.attachmentsNonDeleted.length < 10
    },
		colspan() {
			return this.canEditRemarks? 2 : 1
		},
		scoringVendorDecision() {
			return this.$store.state.projectDetail.scoringVendorDecision
    },
		canEditRemarks() {
			return this.menuRfpNegotiationScoringPrice?.canUpdate && this.scoringPriceLogApproval?.isEnableInputScoringPrice
		},
		showButtonScoring() {
			return this.scoringVendorTeknisTree.some(e => e.points.some(el => el.scores.some(ele => ele.isEnableScoringTeknis)))
		},
	},
	methods: {
		clickEditInput() {
			this.isEdit = true
		},
		textStatusApproval(status) {
			if (status?.toLowerCase().includes('approved')) {
        return 'text-success'
      } else if (status?.toLowerCase().includes('waiting')) {
				return 'text-warning'
			} else {
        return 'text-error'
      }
		},
		async clickDiscard() {
			this.isEdit = false
			await this.deleteFileNotSaved()
			this.$store.dispatch('projectDetail/getScoringAttachments', this.$route.params.projectId)
			this.$store.dispatch('projectDetail/getScoringVendorTeknisTree2', this.$route.params.projectId)
			try {
				this.modal.modalLoading = true
				const response = await this.$store.dispatch('negotiation/getScoringPrice', { rfpId: this.$route.params.projectId })
				if (response?.status < 300 && response?.status >= 200) {
					this.modal.modalLoading = false
				} else {
					this.$store.dispatch('modal/throwError', { response })
				}
			} catch (error) {
				this.modal.modalLoading = false
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
			}
		},
    clickChangeIsOpenModalSubmitScoringPrice(val) {
      this.isOpenModalSubmitScoringPrice = val
    },
		async clickSave() {
			this.modal.modalLoading = true;
			const success = await this.$refs.scoringVendor.validate()
			if (!success) {
				this.modal.modalLoading = false
				return
			}
			this.isEdit = false
			try {
				const response = await this.$store.dispatch('negotiation/putScoringPrice', { rfpId: this.$route.params.projectId })
				if (response?.status < 300 && response?.status >= 200) {
					//loading out
					this.modal.modalLoading = false;
					//show modal success edit scoring vendor
					this.modal.modalSuccessSaved = { value: true, title: MESSAGE_SUCCESS_SCORING_VENDOR }
				} else {
					this.$store.dispatch('modal/throwError', { response })
				}
			} catch (error) {
				//loading out when error
				this.modal.modalLoading = false;
				//show modal error edit scoring vendor
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
			}
			this.$store.dispatch('negotiation/getScoringPrice', { rfpId: this.$route.params.projectId })
			this.$store.dispatch('negotiation/getScoringPriceLogApproval', { rfpId: this.$route.params.projectId })
			this.$store.dispatch('projectDetail/getScoringAttachments', this.$route.params.projectId)

		},
    async clickSubmitScoringPrice() {
      try {
        //show loading
				this.modal.modalLoading = true;
				const response = await this.$store.dispatch('negotiation/putScoringPriceSubmit', { rfpId: this.$route.params.projectId })
				if (response?.status < 300 && response?.status >= 200) {
					//loading out
					this.modal.modalLoading = false;
					this.clickChangeIsOpenModalSubmitScoringPrice(false)
					//show modal success edit scoring vendor
					this.modal.modalSuccessSubmitted = { value: true, title: MESSAGE_SUCCESS_SCORING_SUBMIT }
					this.$store.commit('projectLog/SET_RFP_LOG_ID', this.projectLogRfp?.rfpLogs?.at(-1)?.rfpLogId)
					this.refetchProjectLog()
					this.refetchDetailBanApprover()
				} else {
					this.$store.dispatch('modal/throwError', { response })
				}
      } catch (error) {
        //loading out when error
				this.modal.modalLoading = false;
				//show modal error edit scoring vendor
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
      }
    },
		refetchDetailBanApprover() {
			this.$store.dispatch('negotiation/refetchScoringPriceLogApproval', { rfpId: this.$route.params.projectId })
		},
		clickEditCriteria() {
			this.isEditCriteria = true
		},
		autoSum(array, i) {
			return array.reduce((acc, b) => +acc + +b.scores[i]?.skalaNilai, 0) || '-'
		},
		sumTotal(index) {
			return this.scoringVendorTeknisTree.reduce((acc, b) => +acc + +b.points.reduce((acc2, c) => +acc2 + +c.scores[index]?.skalaNilai, 0), 0) || '-'
		},
		refetchProjectLog() {
			this.$store.dispatch('projectLog/refetchProjectLogByRfpId', this.$route.params.projectId)
		},
		changeCollapse() {
			this.collapse = !this.collapse
		},
		async addAttachment(e, fileRef) {
      const file = e.target?.files[0]
			const checkFileValidation = await this.$store.dispatch('file/checkFileValidation', { file, fileType: ['application/pdf','image/jpeg','image/png'] })
      if(checkFileValidation){
        this.errorFileType = true
      } else {
				try {
					this.modal.modalLoading = true
					this.errorFileType = false
					const encrypted = await this.$store.dispatch('file/hashMD5',file)
					const formData = new FormData()
					formData.append('file', file)
					formData.append('fileRef', fileRef)
					//checksum encrypted md5
					formData.append('checksum', encrypted)
					formData.append('projectType', 'RFP')
					const response = await this.$store.dispatch('file/uploadFile', formData)
					if (response?.status < 300 && response?.status >= 200) {
						const data = response.data.result
						const fileAfterUpload = {
							fileName: data.fileName,
							filePath: data.filePath,
							documentNo: data.documentNo,
							fileRef: fileRef,
							isDeleted: false,
							isSubmitted: false,
							attachmentId: 0
						}
						this.attachments = [ ...this.attachments, fileAfterUpload ]
						this.errorFileType = false
						this.modal.modalLoading = false
					} else {
						this.$store.dispatch('modal/throwError', { response })
					}
        } catch (error) {
					this.modal.modalLoading = false
					this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
        }
        
      }
      if (this.$refs[fileRef]) {
        this.$refs[fileRef].value = ''
      }
    },
		openFile(n) {
			const { fileName, documentNo } = n
      this.$store.dispatch('file/viewAttachment', { filename: fileName, documentNo })
    },
		async clickRemoveFileAttachment(n){
      try {
				this.modal.modalLoading = true
				const fileAttachment = this.attachments.find(e => e.filePath === n.filePath)
        if (fileAttachment?.attachmentId > 0 ) {
          this.attachments = this.attachments.map(e => (e.filePath === fileAttachment.filePath? ({ ...e, isDeleted: true }): e))
        } else {
          const response = await this.$store.dispatch('file/deleteFile', fileAttachment.documentNo || fileAttachment.fileName)
          if (response?.status < 300 && response?.status >= 200) {
						this.attachments = this.attachments.filter(e => e.filePath !== n.filePath)
					} else {
						this.$store.dispatch('modal/throwError', { response })
					}
        }
				this.modal.modalLoading = false
      } catch (error) {
				this.modal.modalLoading = false
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
      }
    },
		async deleteFileNotSaved() {
			try {
				const attachmentsFiltered = this.attachments.filter(e => e.attachmentId === 0)
				for (const attachment of attachmentsFiltered) {
					const response = await this.$store.dispatch('file/deleteFile', attachment.documentNo || attachment.fileName)
					if (response?.status >= 300) {
						this.$store.dispatch('modal/throwError', { response })
					}
				}
				this.modal.modalLoading = false
			} catch (error) {
				this.modal.modalLoading = false
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
			}
		},
		toggleChangeShowRemarks() {
			this.showRemarks = !this.showRemarks
		},
		openModal(item) {
			this.data_temp = item
			this.isOpen = true
		},
		closeModal() {
			this.isOpen = false
			this.data_temp = {}
			this.$store.dispatch('projectDetail/getScoringVendorTeknisTree2', this.$route.params.projectId)
		},
		async clickSaveRemarks() {
			try {
				//show loading
				this.modal.modalLoading = true;
				const response = await this.$store.dispatch('projectDetail/putScoringSaveRemarks', this.$route.params.projectId)
				if (response?.status < 300 && response?.status >= 200) {
					//loading out
					this.modal.modalLoading = false;
					//show modal success edit scoring vendor
					this.modal.modalSuccessSaved = { value: true, title: MESSAGE_SUCCESS_SCORING_REMARKS }
					this.closeModal()
					this.$store.dispatch('projectDetail/getScoringVendorTeknisTree2', this.$route.params.projectId)
				} else {
					this.$store.dispatch('modal/throwError', { response })
				}
			} catch (error) {
				//loading out when error
				this.modal.modalLoading = false;
				//show modal error edit scoring vendor
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
			}
		},
		bgColorWhitePrimary(i) {
			return i % 2 !== 0 ? 'bg-primary-lightest' : 'bg-white'
		}
	},
	async mounted() {
		this.loadingScoringPrice = true
		const res = await this.$store.dispatch('projectLog/getCheckAccess', { projectRequestId: this.$route.params.projectId, projectRequestType: 'rfp' })
		console.log(res, res?.data.code, 'get access');
    if (res?.data.code !== 200) return
    const r1 = this.$store.dispatch('qualification/getQualifications', this.$route.params.projectId)
    const r2 = this.$store.dispatch('projectDetail/getProjectScoringInformation', this.$route.params.projectId)
		const r3 = this.$store.dispatch('negotiation/getScoringPrice', { rfpId: this.$route.params.projectId })
		const r4 = this.$store.dispatch('negotiation/getScoringPriceLogApproval', { rfpId: this.$route.params.projectId })
    const r5 = this.$store.dispatch('projectDetail/getScoringVendorTeknisTree2', this.$route.params.projectId)
    const r6 = this.$store.dispatch('projectDetail/getScoringAttachments', this.$route.params.projectId)
    const r7 = this.$store.dispatch('projectDetail/getScoringVendorDecision', this.$route.params.projectId)
    const r8 = this.$store.dispatch('projectDetail/getScoringVendorApproval', { rfpId: this.$route.params.projectId })
		const [res1, res2, res3, res4, res5, res6, res7, res8] = await Promise.all([r1, r2, r3, r4, r5, r6, r7, r8])
		if (res1?.status === 200 && res2?.status === 200 && res3?.status === 200 && res4?.status === 200 && res5?.status === 200 && res6?.status === 200 && res7?.status === 200 && res8?.status === 200) {
			this.loadingScoringPrice = false
		}
	},
	destroyed() {
		clearInterval(this.counterInterval)
		clearInterval(this.counterIntervalApproval)
		this.$store.commit('projectLog/SET_COUNTER_INTERVAL', null)
		this.$store.commit('negotiation/SET_COUNTER_INTERVAL', null)
		if (this.isEdit) {
			this.clickDiscard()
		}
	},
	components: {
    SubmitScoringPrice,
    InputVendor,
    ScoringVendorLoading,
		ScoringEditPrice
	}
}
</script>

<style scoped>
.min-w-28 {
	min-width: 7rem;
}
</style>
