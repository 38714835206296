<template>
	<div class="project-i w-full mt-6">
		<div class="flex justify-between mb-3 cursor-pointer"
			@click="changeCollapse">
			<div class="flex">
				<span class="icon-list text-xl text-gray-dark font-semibold mr-6"></span>
				<h4 class="text-base text-gray-dark font-semibold">PROJECT INFORMATION</h4>
			</div>
			<div>
				<p :class="collapse.projectInformation? 'rotate-180': 'rotate-0'"
					class="icon-chevron-down text-gray text-2xl transform transition ease-in"></p>
			</div>
		</div>
		<div v-show="collapse.projectInformation"
			class="w-full">
			<div class="border border-gray-lightest p-2.5 rounded-xl items-start w-full">
				<div class="mb-3 w-full">
					<h5 class="text-tertiary text-sm font-medium mb-1">Background</h5>
					<TiptapView :value="projectInformation?.background" v-if="projectInformation?.background" :grayColor="false"/>
          <p v-else class="text-gray-dark">-</p>
				</div>
				<div class="mb-3 w-full">
					<h5 class="text-tertiary text-sm font-medium mb-1">Tujuan Proyek</h5>
					<TiptapView :value="projectInformation?.tujuanProyek" v-if="projectInformation?.tujuanProyek" :grayColor="false"/>
          <p v-else class="text-gray-dark">-</p>
				</div>
				<div class="mb-3 w-full">
					<h5 class="text-tertiary text-sm font-medium mb-1">Jangka Waktu Proyek</h5>
					<TiptapView :value="projectInformation?.jangkaWaktuProyek" v-if="projectInformation?.jangkaWaktuProyek" :grayColor="false"/>
          <p v-else class="text-gray-dark">-</p>
				</div>

			</div>
		</div>
    <ModalReadMore @click="closeModal" :isOpen="readMoreShow" :text="modalText"/>
	</div>
</template>

<script>
import ModalReadMore from '@/core/components/modal/ReadMore.vue'
import TiptapView from '@/core/components/custom/TiptapView.vue'

export default {
	name: "ProjectInformationDetail",
	data() {
		return {
			modalText: '',
			readMoreShow: false,
		}
	},
	computed: {
		projectInformation() {
			return this.$store.state.projectDetail.projectInformation
		},
		collapse() {
			return this.$store.state.projectDetail.collapse
		},
	},
	methods: {
		changeCollapse() {
			this.$emit("collapse")
		},
		closeModal() {
			this.readMoreShow = false
		},
		readMore(text) {
			this.readMoreShow = true;
			this.modalText = text
		}
	},
	components: {
		ModalReadMore,
		TiptapView
	}
}
</script>