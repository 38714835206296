<template>
  <div class="animate-pulse">
    <div class="overflow-x-auto">
      <div class="w-full overflow-x-auto min-width-custom">
        <table class="w-full h-px text-sm" aria-describedby="scoringEscrowLoading">
          <thead>
            <tr class="font-semibold bg-white text-gray-darkest">
              <th scope="col" class="sticky left-0 p-0 font-semibold text-left bg-white "><p class="w-72 p-3.5 break-words">Risk Factor</p></th>
              <th scope="col" class="sticky w-20 p-0 bg-white left-72"><p class="flex items-center justify-center p-3.5 h-full break-words font-semibold border-r-2 border-gray-light">Bobot</p></th>
              <th v-for="n in 2"
                :key="n"
                scope="col"
                class="font-normal p-3.5">
                <div class="flex justify-center">
                  <div class="h-5 rounded w-28 bg-gray-light"></div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="n in 15" :key="n" class="font-medium text-left text-gray-darkest" :class="{'bg-primary': [1,4,6,8,11,15].includes(n), 'bg-primary-lightest': [3,10,13].includes(n) }">
              <td class="sticky left-0  break-words w-72 p-3.5"><div class="h-5 rounded w-28 bg-gray-light"></div></td>
              <td class="sticky w-20 p-0 left-72" >
                <div class="flex items-center justify-center h-full font-semibold border-r-2 border-gray-light">
                  <div v-if="[1,4,11].includes(n)" class="w-10 h-5 rounded bg-gray-light"></div>
                </div>
              </td>
              <td v-for="col in 2"
                :key="col"
                class="p-3.5">
                <div class="flex items-center justify-center">
                  <div v-if="![1,4,6,8,11].includes(n)" class="w-10 h-5 rounded bg-gray-light"></div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScoringEscrowLoading',
}
</script>


<style scoped>
.min-width-custom {
	min-width: 40rem;
}
</style>