<template>
  <div class="my-6">
    <div class="flex justify-between mb-6" :class="projectDetailContainerWidth < 640 ? 'flex-col' : 'items-center flex-row'">
      <h1 class="text-xl font-medium">{{isSingleVendor? 'LOI': draftSktLoi.needLoi? 'SKT & LOI' : 'SKT'}}</h1>
    </div>
    <ApprovalTable/>
    <Information/>
    <div v-if="draftSktLoi?.sktVendors.length && !isSingleVendor">
      <div class="flex justify-between mb-3.5">
        <div class="flex items-center">
          <span class="mr-6 text-lg icon-file-text text-gray"></span>
          <h5 class="text-gray-dark">SKT</h5>
        </div>
        <div class="flex items-center">
          <p :class="!collapse.skt ? 'rotate-180' : 'rotate-0'" @click="changeCollapse('skt')"
            class="text-2xl transition ease-in transform cursor-pointer icon-chevron-down text-gray"></p>
        </div>
      </div>
      <CardVendorSkt v-if="!collapse.skt"/>
    </div>
    <div v-if="draftSktLoi?.loiVendors.length && draftSktLoi.needLoi" class="pt-6 border-t border-gray-lightest">
      <div class="flex justify-between mb-3.5">
        <div class="flex items-center">
          <span class="mr-6 text-lg icon-file-text text-gray"></span>
          <h5 class="text-gray-dark">LOI</h5>
        </div>
        <div class="flex items-center">
          <p :class="!collapse.loi ? 'rotate-180' : 'rotate-0'" @click="changeCollapse('loi')"
            class="text-2xl transition ease-in transform cursor-pointer icon-chevron-down text-gray"></p>
        </div>
      </div>
      <CardLoi v-if="!collapse.loi"/>
    </div>
    <div class="w-full pt-6 my-6 border-t-2 border-gray-lightest">
      <div class="flex mb-3.5 cursor-pointer"
        @click="changeCollapse('attachments')">
        <div class="flex flex-auto items-center">
          <span class="mr-4 icon-list"></span>
          <h4 class="text-lg font-medium text-gray-darkest">ATTACHMENTS</h4>
        </div>
        <div class="flex-none">
          <p :class="!collapse.attachments? 'rotate-180': 'rotate-0'"
            class="icon-chevron-down text-gray text-2xl transform transition ease-in"></p>
        </div>
      </div>
    </div>
    <Attachment v-if="!collapse.attachments" :readOnly="true"></Attachment>
  </div>
</template>

<script>
import ApprovalTable from '@/core/components/rfp/sktLoi/ApprovalTable.vue'
import Attachment from '@/core/components/rfp/sktLoi/Attachment.vue'
import Information from '@/core/components/rfp/sktLoi/Information.vue'
import CardVendorSkt from '@/core/components/rfp/sktLoi/CardVendorSkt.vue'
import CardLoi from '@/core/components/rfp/sktLoi/CardLoi.vue'

export default {
  name: 'SktLoiDetails',
  data() {
    return {
      collapse: {
        skt: false,
        loi: false,
        attachments: false,
      },
      spv1and2: true
    }
  },
  computed: {
    projectDetailContainerWidth() {
      return this.$store.state.width.projectDetailContainerWidth
    },
    draftSktLoi: {
      get() {
        return this.$store.state.sktLoi.draftSktLoi
      },
      set(value) {
        this.$store.commit('sktLoi/SET_DRAFT_SKT_LOI', value)
      }
    },
    isSingleVendor() {
      return this.draftSktLoi.sktVendors?.length <= 1
    },
  },
  methods: {
    changeCollapse(key) {
      this.collapse[key] = !this.collapse[key] 
    }
  },
  components: {
    ApprovalTable,
    Attachment,
    Information,
    CardVendorSkt,
    CardLoi,
  }
}
</script>