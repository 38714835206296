<template>
  <div class="animate-pulse">
    <div class="overflow-x-auto">
      <div class="w-full overflow-x-auto min-width-custom">
        <table class="w-full h-px text-sm" id="scoringVendorTable" aria-describedby="scoringVendor">
						<thead>
							<tr>
								<th scope="col" colspan="5" class="sticky left-0 p-0 text-gray-darkest font-semibold text-lg bg-white py-2.5 px-2">SCORING TECHNICAL</th>
							</tr>
							<tr class="bg-white border-t border-gray-light">
								<th scope="col" class="sticky left-0 p-0 font-medium text-gray-darkest text-left bg-white w-40"><p class="py-2.5 px-2 break-words">Criteria</p></th>
								<th scope="col" class="sticky left-40 p-0 font-medium text-gray-darkest w-20 text-left bg-white "><p class=" py-2.5 px-2 break-words">Remarks</p></th>
								<th scope="col" class="sticky p-0 font-medium text-gray-darkest bg-white w-20 left-60"><p class="flex items-center justify-center h-full py-2.5 px-2  border-r border-gray-light">Bobot</p></th>
								<th v-for="n in 2"
										:key="n" scope="col" class="font-medium text-gray-darkest truncate min-w-28">
                    <div class="flex justify-center">
                  <div class="h-5 rounded w-28 bg-gray-light"></div>
                </div>
                </th>
							</tr>
						</thead>
						<tbody v-for="n in 4" :key="n">
							<tr class="font-medium text-left bg-primary text-gray-darkest">
								<td class="sticky left-0 break-words bg-primary py-2.5 px-2"><div class="h-5 rounded w-28 bg-gray-light"></div></td>
								<td class="sticky left-40 break-words bg-primary py-2.5 px-2 text-center">
                  <div class="flex items-center justify-center h-full ">
                    <div class="w-10 h-5 rounded bg-gray-light"></div>
                  </div>
                </td>
								<td class="sticky p-0 left-60">
                  <div class="flex items-center justify-center h-full  border-r border-gray-light">
                    <div class="w-10 h-5 rounded bg-gray-light"></div>
                  </div>
                </td>
                <td v-for="m in 2"
                    :key="m"
                    class=" py-2.5 px-2 text-center">
                    <div class="flex items-center justify-center h-full ">
                      <div class="w-10 h-5 rounded bg-gray-light"></div>
                    </div>
                </td>
							</tr>
							<tr v-for="col in 2"
								:key="col" class="font-medium text-gray-darkest" :class="{ 'bg-primary-lightest border-t border-b border-primary-light': col % 2 !== 0}">
								<td colspan="2" class="sticky left-0 py-2.5 px-2 bg-white break-words" :class="{ 'bg-primary-lightest border-t border-b border-primary-light': col % 2 !== 0}">
									<div class="flex items-center h-full ">
                    <div class="w-28 h-5 rounded bg-gray-light"></div>
                  </div>
								</td>
								<td class="sticky p-0 bg-white left-60" :class="{ 'bg-primary-lightest border-t border-b border-primary-light': col % 2 !== 0 }">
									<div class="flex items-center justify-center h-full border-r border-gray-light">
                    <div class="w-10 h-5 rounded bg-gray-light"></div>
                  </div>
								</td>
								<template >
									<td v-for="p in 2"
										:key="p"
										class=" py-2.5 px-2 text-center">
										<div class="flex items-center justify-center h-full ">
                      <div class="w-10 h-5 rounded bg-gray-light"></div>
                    </div>
									</td>
								</template>
							</tr>
							<tr v-if="n === 4" class="font-medium text-left bg-primary text-gray-darkest">
								<td colspan="2" class="sticky left-0 p-3 break-words bg-primary"><p>Total Bobot</p></td>
								<td class="sticky p-0 left-60">
                  <div class="flex items-center justify-center h-full border-r border-gray-light">
                    <div class="w-10 h-5 rounded bg-gray-light"></div>
                  </div>
                </td>
								<td class="p-3.5 text-center "
										v-for="q in 2"
										:key="q">
                    <div class="flex items-center justify-center h-full ">
                      <div class="w-10 h-5 rounded bg-gray-light"></div>
                    </div>
                </td>
							</tr>
							<tr v-if="n === 3" class="border-t border-b border-gray-light">
								<td colspan="5" class="sticky left-0 p-0  text-gray-darkest text-lg font-semibold text-center bg-white py-2.5 px-2">SCORING PRICE</td>
							</tr>
						</tbody>
					</table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScoringVendorLoading'
}
</script>

<style scoped>
.min-width-custom {
	min-width: 40rem;
}
</style>