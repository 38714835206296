<template>
	<div class="w-full">
		<ButtonGista v-if="!negotiationDetails.isScheduled" color="secondary"
			:customClass="projectDetailContainerWidth < 640 ? 'w-full mr-6' : 'sm:w-60 mr-6'"
			:disabled="negotiationDetails?.isCanceled"
			@click="changeIsOpen(true)">
			Negotiation Scheduled
		</ButtonGista>
		<div v-else class="flex items-center py-4 mr-3.5">
			<span class="mr-4 text-xl font-semibold icon-check text-secondary"></span>
			<p class="text-sm font-semibold text-secondary">Negotiation Scheduled</p>
		</div>
		<ModalConfirm 
				title="Apakah negosiasi sudah dijadwalkan?" 
				message="Negosiasi dijadwalkan diluar sistem SOLUTIP" 
				icon="saved" 
				textButton="Yes, Scheduled" 
				:isOpen="isOpen" 
				:isBackButton="true"
				@changeIsOpen="changeIsOpen($event)"
				@click="click"
				colorButton="secondary"></ModalConfirm>
	</div>
</template>

<script>
import ModalConfirm from '@/core/components/modal/Confirm'
export default {
	name: "NegotiationScheduled",
	data() {
		return {
			isOpen: false,
		}
	},
	computed: {
		projectDetailContainerWidth() {
			return this.$store.state.width.projectDetailContainerWidth
		},
		negotiationDetails() {
			return this.$store.state.negotiation.negotiationDetails
		},
		vendorDetail() {
			return this.$store.state.negotiation.vendorDetail
    },
		modal: {
			get() {
				return this.$store.state.modal.modal
			},
			set(value) {
				this.$store.commit('modal/SET_MODAL', value)
			}
    },
	},
	methods: {
		async click() {
			try {
				this.modal.modalLoading = true;
				const response = await this.$store.dispatch('negotiation/putNegotiationScheduleConfirm', { vendorCandidateId: this.negotiationDetails.vendorCandidateId })
				if (response?.status < 300 && response?.status >= 200) {
					//loading out
					this.modal.modalLoading = false;
					// this.$emit("click")
					this.$store.dispatch('negotiation/getNegotiationDetails', { vendorCandidateId: this.vendorDetail })
					this.isOpen = false
				} else {
					this.$store.dispatch('modal/throwError', { response })
				}

			} catch (error) {
				console.log(error);
				this.modal.modalLoading = false;
				this.modal.modalError = { value: true, traceid: error.traceid, title: error.title, message: error.message }
			}
		},
		closeModal() {
			this.isOpen = false
		},
		changeIsOpen(val) {
			this.isOpen = val
		}
	},
	components: {
		ModalConfirm
	}
}
</script>