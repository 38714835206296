<template>
	<div class="p-0" :class="projectDetailContainerWidth < 640 ? 'mt-4' : 'mt-0'">

		<ButtonGista
			v-if="disabledConfirmation"
			@click="toggle"
			type="primary"
			color="success"
			:disabled="proposalInformation?.isCanceled"
			:customClass="projectDetailContainerWidth < 640 ? 'w-full' : ''"
			>
			Confirmation
		</ButtonGista>

		<GistaModal :value="isOpen"
			width="2/3"
			scrollable
			@input="toggle"
			customClass="bg-white top-0 bottom-0 mt-6 mb-8 max-w-7xl">
			<div class="px-6 py-6">
				<div class="flex justify-center mb-6">
					<h4 class="text-3xl font-semibold">Send RFP Confirmation</h4>
				</div>
				<div class="w-full">
					<div class="flex mb-4" :class="projectDetailContainerWidth < 640 ? 'flex-col' : 'flex-row items-center'">
						<h5 class="text-base sm-bold" :class="projectDetailContainerWidth < 640 ? 'mb-4' : 'mb-0'">QUALIFIED VENDORS</h5>
						<div class="flex items-center" :class="projectDetailContainerWidth < 640 ? '' : 'ml-3.5'">
							<p class="text-xl icon-check-circle text-success"></p>
							<h5 class="text-success text-base sm-bold ml-3.5">The RFP will be sent to the following vendors</h5>
						</div>
					</div>
					<div class="grid w-full mb-4 sm:gap-6" :class="{'grid-cols-3' :projectDetailContainerWidth > 1024, 'grid-cols-2':projectDetailContainerWidth <= 1024 && projectDetailContainerWidth > 640, 'grid-cols-1':projectDetailContainerWidth <= 640}" >
						<div v-for="(n, i) in qualificationQualified" :key="i" class="border-2 border-success-lightest shadow-success-sm px-6 py-3.5 rounded-xl mb-4 w-full">
							<div>
								<div class="flex flex-wrap">
									<h6 class="mb-1 mr-6 text-tertiary">Vendor #{{n?.sequence}}</h6>
									<div v-if="n.isNewVendor" class="flex px-6 py-1 mb-1 text-xs rounded-lg bg-success-lightest text-success">
										<h6>New Vendor</h6>
									</div>
								</div>
								<h5 class="text-sm sm-bold">{{n?.vendorName}}</h5>
								<p class="text-xs break-words text-gray-dark">{{n?.vendorEmail}}</p>
							</div>
						</div>
					</div>

				</div>
				<div class="w-full" v-if="qualificationDisqualified.length > 0">
					<div class="flex flex-col items-start gap-3 mb-4 sm:gap-0 sm:items-center sm:flex-row">
						<h5 class="text-base sm-bold">ELIMINATED VENDORS</h5>
						<div class="flex items-center sm:ml-3.5">
							<p class="text-xl icon-slash text-error"></p>
							<h5 class="text-error text-base sm-bold ml-3.5">The RFP will not be sent the following vendors</h5>
						</div>
					</div>
					<div class="grid w-full mb-4 sm:gap-6" :class="{'grid-cols-3' :projectDetailContainerWidth > 1024, 'grid-cols-2':projectDetailContainerWidth <= 1024 && projectDetailContainerWidth > 640, 'grid-cols-1':projectDetailContainerWidth <= 640}">
						<div v-for="(n, i) in qualificationDisqualified" :key="i" class="bg-gray-lightest border-2 border-gray-lightest  px-6 py-3.5 rounded-xl mb-4 w-full">
							<div>
								<div class="flex flex-wrap">
									<h6 class="mb-1 mr-10 text-tertiary">Vendor #{{n?.sequence}}</h6>
									<div v-if="n.isNewVendor" class="px-6 py-1 mb-1 text-xs rounded-lg bg-success-lightest text-success">
										<h6>New Vendor</h6>
									</div>
								</div>
								<h5 class="text-sm sm-bold">{{n?.vendorName}}</h5>
								<p class="text-xs break-words text-gray-dark">{{n?.vendorEmail}}</p>
							</div>
						</div>


					</div>

				</div>
				<div class="flex justify-end">

					<ButtonGista @click="sendRfp"
						type="primary"
						color="success"
						:customClass="projectDetailContainerWidth < 640 ? 'w-full' : ''"
						>
						Send RFP
					</ButtonGista>

				</div>
			</div>
		</GistaModal>
	</div>
</template>
<script>
import { MESSAGE_SUCCESS_SEND_RFP_CONFIRMATION } from '@/core/constant/successMessage'
import { CANDIDATE, PRA_QUALIFICATION_ELIMINATED } from '@/core/constant/qualification.js'
import { VENDOR_01_AWAITING_PRE_QUALIFICATION_DOCUMENT, SPP_PROCESS } from '@/core/constant/statusProject'

export default {
	name: 'SendRFIConfirmation',
	data() {
		return {
			isOpen: false,
		}
	},
	computed: {
		qualifications() {
			return this.$store.state.qualification.qualifications
		},
		qualificationQualified() {
			return this.qualifications.filter(e => {
				if (e.localPartners.some(el => el.statusVendor === CANDIDATE)) return e
				return e.statusVendor === CANDIDATE
			}) 
		},
		qualificationDisqualified() {
			return this.qualifications.filter(e => e.statusVendor === PRA_QUALIFICATION_ELIMINATED)
		},
		projectDetailContainerWidth() {
			return this.$store.state.width.projectDetailContainerWidth
		},
		projectLogRfp() {
			return this.$store.state.projectLog.projectLogRfp
		},
		currentStatusProject() {
			const rfpLogs = this.projectLogRfp?.rfpLogs
			const logsLength = rfpLogs.length
			if(rfpLogs[logsLength - 1]?.statusProject === SPP_PROCESS) return rfpLogs[logsLength - 2]?.statusProject
			return rfpLogs[logsLength - 1]?.statusProject
    },
		preQualificationStatusProject() {
      const statusProject = [VENDOR_01_AWAITING_PRE_QUALIFICATION_DOCUMENT,]
      return statusProject.includes(this.currentStatusProject)
    },
		disabledConfirmation() {
			const qualificationNonEliminated = this.qualifications.filter(e => !e.isDisqualified)
			if (this.preQualificationStatusProject) {
				return qualificationNonEliminated.every(e => e.isCompletedRequiredDocuments && e.statusVendor === CANDIDATE && e.localPartners.filter(el => !el.isDisqualified).every(el => el.isCompletedRequiredDocuments && el.statusVendor === CANDIDATE))
			} else {
				return qualificationNonEliminated.every(e => e.isCompletedRequiredDocuments && e.localPartners.filter(el => !el.isDisqualified).every(el => el.isCompletedRequiredDocuments)) && qualificationNonEliminated.some(e => e.statusVendor === CANDIDATE || e.localPartners.some(el => el.statusVendor === CANDIDATE))
			}
		},
		proposalInformation() {
      return this.$store.state.projectDetail.proposalInformation
    },
		modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    }

	},
	methods: {
		toggle() {
			this.isOpen = !this.isOpen
		},
		async sendRfp() {
			try {
				//show loading
				this.modal.modalLoading = true;
				const response = await this.$store.dispatch('qualification/postQualificationsSendRfp', this.$route.params.projectId)
				if (response?.status < 300 && response?.status >= 200) {
					//loading out
					this.modal.modalLoading = false
					this.isOpen = false
					//show modal success send rfp
					this.modal.modalSuccessSubmitted = { value: true, title: MESSAGE_SUCCESS_SEND_RFP_CONFIRMATION }
					this.$store.dispatch('projectLog/refetchProjectLogByRfpId', this.$route.params.projectId)
					//get qualifications
					this.$store.dispatch('qualification/getQualifications', this.$route.params.projectId)
				} else {
					this.$store.dispatch('modal/throwError', { response })
				}
			} catch (error) {
				console.log(error);
				//loading out
				this.modal.modalLoading = false;
				//show modal error send rfp
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
			}
		},

	},
}

</script>