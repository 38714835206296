<template>
  <div>
    <ButtonGista type="primary" class="w-full" color="success" @click="changeIsOpen(true)" :disabled="disabled">{{isEdit ? 'Submit' : 'Send Email'}}</ButtonGista>
    <ModalConfirm 
      title="Apakah anda yakin?" 
      message="Pastikan seluruh informasi sudah benar" 
      :textButton="isEdit ? 'Submit' : 'Send Email to vendor'" 
      :isOpen="isOpenModalSendEmail" 
      @changeIsOpen="changeIsOpen($event)" 
      :isBackButton="true"
      @click="clickReviseOffer"
      colorButton="success">
      <template #optional>
        <div v-if="isLocalPartner && !isEdit" class="flex flex-col h-full p-4 mb-10 border rounded-lg border-gray-lightest">
          <label class="block mb-1 text-sm font-medium text-gray">Send email to <span class="text-error">*</span></label>
          <GroupRadioButton class="pt-3"
            :options="options"
            :selected="optionSendEmail"
            name="options"
            textField="text"
            textField2="value"
            @change="changeOptionSendEmail($event)" />
        </div>
      </template>
    </ModalConfirm>
  </div>
</template>

<script>
  import ModalConfirm from '@/core/components/modal/Confirm'
  import GroupRadioButton from "@/core/components/custom/GroupRadioButton.vue";

  export default {
    name: 'SendEmailReviseOffer',
    props: ['isOpenModalSendEmail', 'optionSendEmail', 'isLocalPartner', 'disabled'],
    data() {
      return {
        options: [
          {
            text: 'Local Partner',
            value: 'LOCAL_PARTNER',
          },
          {
            text: 'Local Partner & Principal',
            value: 'BOTH',
          }
        ]
      }
    },
    computed: {
      selectedIdxOffer() {
        return this.$store.state.negotiation.selectedIdxOffer
      },
      isEdit() {
        return this.selectedIdxOffer !== ''
      }
    },
    methods: {
      changeIsOpen(val) {
        this.$emit('clickToggle', val)
      },
      clickReviseOffer() {
        this.$emit('click')
      },
      changeOptionSendEmail(e) {
        this.$emit('changeOptionSendEmail', e)
      }

    },
    components: {
      GroupRadioButton,
      ModalConfirm
    }
  }
</script>