<template>
  <div>
    <div v-if="!isLoading">
      <div class="flex justify-between my-6">
        <div>
          <span class="text-2xl font-bold cursor-pointer icon-arrow-left text-gray"
            @click="clickBackToVendorDetail"></span>
        </div>
        <div>
            <h4 class="text-lg font-medium text-gray-darkest">BAN - {{ banDetails?.currentNegotiationStatus }} - {{banDetails?.negotiationStatus}}</h4>
        </div>
        <div></div>
      </div>
      <div class="flex flex-col justify-between mb-6 sm:flex-row">
        <div class="pr-4 ">
          <div class="w-full mb-1">
            <label class="text-sm text-tertiary">{{negotiationDetails?.isLocalPartner? 'Local Partner': 'Vendor'}} #{{ negotiationDetails?.vendorCandidateSequence }}</label>
            <label class="text-xs text-success bg-success-lightest rounded py-1 px-2 ml-3.5">New Vendor</label>
          </div>
          <h5 class="text-base text-gray-darkest">{{ banDetails?.vendorName }}</h5>
        </div>
        <div class="mt-3 sm:mt-0 " v-if="menuRfpNegotiationBanReport?.canRead">
          <ButtonGista size="small" type="secondary" color="success" class="w-full sm:w-fit" @click="clickViewBan">
            <template #icon-left>
              <span class="icon-eye text-lg pr-3.5"></span>
            </template>
            View BAN
          </ButtonGista>
        </div>
      </div>
      <div v-if="!loading.loadingApprovedBan && !banDetails?.isHasRenegotiation" class="mb-4 overflow-x-auto border rounded-lg border-gray-light">
        <div class="border-gray-light">
          <div class="p-2">
            <p class="text-sm font-medium text-gray">Negotiation Participant</p>
          </div>
        </div>
        <table class="w-full" aria-describedby="negotiationParticipant">
          <thead>
            <tr class="bg-primary-lightest">
              <th scope="col" class="p-2 border-t border-gray-light" :class="`${detailBanApprover.representUkkpId && detailBanApprover.representGsitId? 'w-1/3' : 'w-1/2'}`">
                <p class="text-sm font-medium text-left text-gray">DLOG</p>
              </th>
              <th scope="col" class="p-2 border-t border-l border-gray-light" :class="`${detailBanApprover.representUkkpId && detailBanApprover.representGsitId? 'w-1/3' : 'w-1/2'}`" v-if="detailBanApprover.representGsitId" >
                <p class="text-sm font-medium text-left text-gray">GSIT</p>
              </th>
              <th scope="col" class="p-2 border-t border-l border-gray-light" :class="`${detailBanApprover.representUkkpId && detailBanApprover.representGsitId? 'w-1/3' : 'w-1/2'}`" v-if="detailBanApprover.representUkkpId" >
                <p class="text-sm font-medium text-left text-gray">UKKP</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="p-2 border-gray-light" :class="`${detailBanApprover.representUkkpId && detailBanApprover.representGsitId? 'w-1/3' : 'w-1/2'}`">
                <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">{{detailBanApprover.itpVerificatorName?.toLowerCase()}}</p>
                <p v-if="showStatus(detailBanApprover.itpVerificatorStatus)" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark">{{ detailBanApprover.itpVerificatorStatus }} </p>
                <p v-if="detailBanApprover.itpVerificatorApproved" 
                  class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                  <span :class="textStatusApproved(detailBanApprover.itpVerificatorApproved)">{{ detailBanApprover.itpVerificatorApproved?.split('|')[0] }}</span>
                  <span class="px-2 font-black text-gray">&#183;</span>
                  <span class="whitespace-nowrap">{{ detailBanApprover.itpVerificatorApproved?.split('|')[1] }}</span> 
                </p>
              </td>
              <td class="p-2 border-l border-gray-light" :class="`${detailBanApprover.representUkkpId? 'w-1/3' : 'w-1/2'}`" v-if="detailBanApprover.representGsitId">
                  <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">{{detailBanApprover.representGsitName?.toLowerCase()}}</p>
                <p v-if="showStatus(detailBanApprover.representGsitStatus)" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark">{{ detailBanApprover.representGsitStatus }}</p>
                <p v-if="detailBanApprover.representGsitApproved" 
                  class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                  <span :class="textStatusApproved(detailBanApprover.representGsitApproved)">{{ detailBanApprover.representGsitApproved?.split('|')[0] }}</span>
                  <span class="px-2 font-black text-gray">&#183;</span>
                  <span class="whitespace-nowrap">{{ detailBanApprover.representGsitApproved?.split('|')[1] }}</span>
                </p>
              </td>
              <td class="p-2 border-l border-gray-light" :class="`${detailBanApprover.representGsitId? 'w-1/3' : 'w-1/2'}`" v-if="detailBanApprover.representUkkpId">
                <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">{{detailBanApprover.representUkkpName?.toLowerCase()}}</p>
                <p v-if="showStatus(detailBanApprover.representUkkpStatus)" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark">{{ detailBanApprover.representUkkpStatus }}</p>
                <p v-if="detailBanApprover.representUkkpApproved" 
                  class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                  <span :class="textStatusApproved(detailBanApprover.representUkkpApproved)">{{ detailBanApprover.representUkkpApproved?.split('|')[0] }}</span>
                  <span class="px-2 font-black text-gray">&#183;</span> 
                  <span class="whitespace-nowrap">{{ detailBanApprover.representUkkpApproved?.split('|')[1] }}</span>
                </p>
              </td>
            </tr>
            
          </tbody>
        </table>
  
      </div>
      <div v-if="!loading.loadingApprovedBan && !banDetails?.isHasRenegotiation" class="mb-4 overflow-x-auto border rounded-lg border-gray-light">
        <div class="border-gray-light">
          <div class="p-2">
            <p class="text-sm font-medium text-gray">Approved By</p>
          </div>
        </div>
        <table class="w-full" aria-describedby="approvedBy" >
          <thead>
            <tr class="bg-primary-lightest">
              <th scope="col" class="p-2 border-t border-gray-light" :class="`${detailBanApprover.directApproverUkkpId && detailBanApprover.directApproverGsitId? 'w-1/3' : 'w-1/2'}`">
                <p class="text-sm font-medium text-left text-gray">DLOG</p>
              </th>
              <th scope="col" class="p-2 border-t border-l border-gray-light" :class="`${detailBanApprover.directApproverUkkpId && detailBanApprover.directApproverGsitId? 'w-1/3' : 'w-1/2'}`" v-if="detailBanApprover.directApproverGsitId" >
                <p class="text-sm font-medium text-left text-gray">GSIT</p>
              </th>
              <th scope="col" class="p-2 border-t border-l border-gray-light" :class="`${detailBanApprover.directApproverUkkpId && detailBanApprover.directApproverGsitId? 'w-1/3' : 'w-1/2'}`" v-if="detailBanApprover.directApproverUkkpId" >
                <p class="text-sm font-medium text-left text-gray">UKKP</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr >
              <td class="p-2 border-gray-light" :class="`${detailBanApprover.directApproverUkkpId && detailBanApprover.directApproverGsitId? 'w-1/3' : 'w-1/2'}`">
                <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">{{detailBanApprover.directApproverItpName?.toLowerCase()}}</p>
                <p v-if="showStatus(detailBanApprover.directApproverItpStatus)" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark">{{ detailBanApprover.directApproverItpStatus }}</p>
                <p v-if="detailBanApprover.directApproverItpApproved" 
                  class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                  <span :class="textStatusApproved(detailBanApprover.directApproverItpApproved)">{{ detailBanApprover.directApproverItpApproved?.split('|')[0] }}</span>
                  <span class="px-2 font-black text-gray">&#183;</span>
                  <span class="whitespace-nowrap">{{ detailBanApprover.directApproverItpApproved?.split('|')[1] }}</span>
                </p>
              </td>
              <td class="p-2 border-l border-gray-light" :class="`${detailBanApprover.directApproverUkkpId? 'w-1/3' : 'w-1/2'}`" v-if="detailBanApprover.directApproverGsitId">
                <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">{{detailBanApprover.directApproverGsitName?.toLowerCase()}}</p>
                <p v-if="showStatus(detailBanApprover.directApproverGsitStatus)" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark">{{ detailBanApprover.directApproverGsitStatus }}</p>
                <p v-if="detailBanApprover.directApproverGsitApproved" 
                  class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                  <span :class="textStatusApproved(detailBanApprover.directApproverGsitApproved)">{{ detailBanApprover.directApproverGsitApproved?.split('|')[0] }}</span>
                  <span class="px-2 font-black text-gray">&#183;</span>
                  <span class="whitespace-nowrap">{{ detailBanApprover.directApproverGsitApproved?.split('|')[1] }}</span>
                </p>
              </td>
              <td class="p-2 border-l border-gray-light" :class="`${detailBanApprover.directApproverGsitId? 'w-1/3' : 'w-1/2'}`" v-if="detailBanApprover.directApproverUkkpId">
                <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">{{detailBanApprover.directApproverUkkpName?.toLowerCase()}}</p>
                <p v-if="showStatus(detailBanApprover.directApproverUkkpStatus)" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark">{{ detailBanApprover.directApproverUkkpStatus }}</p>
                <p v-if="detailBanApprover.directApproverUkkpApproved" 
                  class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                  <span :class="textStatusApproved(detailBanApprover.directApproverUkkpApproved)">{{ detailBanApprover.directApproverUkkpApproved?.split('|')[0] }}</span>
                  <span class="px-2 font-black text-gray">&#183;</span>
                  <span class="whitespace-nowrap">{{ detailBanApprover.directApproverUkkpApproved?.split('|')[1] }}</span>
                </p>
              </td>
            </tr>
            
          </tbody>
        </table>
      </div>
      <div v-if="loading.loadingApprovedBan">
        <div class="animate-pulse">
          <div class="h-24 mb-4 rounded-lg bg-gray-light"></div>
          <div class="h-24 mb-4 rounded-lg bg-gray-light"></div>
        </div>
      </div>
      <div>
        <div class="flex items-center mb-6">
          <span class="mr-4 icon-list"></span>
          <h5 class="text-lg font-medium text-gray-darkest">Detail BAN</h5>
        </div>
        <div class="grid gap-x-6 gap-y-3.5 mb-3.5 border-b border-gray-lightest pb-3.5"
          :class="{
            'grid-cols-1': projectDetailContainerWidth < 768,
            'grid-cols-2': projectDetailContainerWidth >= 768
          }">
          <InputDisabled label="Project ID" :value="banDetails.projectId"></InputDisabled>
          <InputDisabled label="Request No." :value="banDetails.requestNo"></InputDisabled>
          <InputDisabled label="Vendor Name" :value="banDetails.vendorName"></InputDisabled>
          <InputDisabled label="Project Name" :value="banDetails.projectName"></InputDisabled>
          <InputDisabled label="Request By" :value="banDetails.requestByName?.toLowerCase()" class="capitalize"></InputDisabled>
          <InputDisabled label="Created By" :value="banDetails.createdBy?.toLowerCase()" class="capitalize"></InputDisabled>
          <InputDisabled v-if="workflowGsit || workflowGsitUkkp" label="PIC GSIT" :value="banDetails.picGsitName?.toLowerCase()" class="capitalize"></InputDisabled>
          <InputDisabled v-if="workflowUkkp || workflowGsitUkkp" label="PIC UKKP" :value="banDetails.picUkkpName?.toLowerCase()" class="capitalize"></InputDisabled>
        </div>
        <div class="mb-3">
          <div class="mb-1">
            <p class="text-sm font-medium text-gray">Project Type</p>
          </div>
          <div class="flex flex-wrap mt-3">
            <div class="flex items-center mb-3">
              <span v-if="banDetails.projectType?.includes('Hardware')" class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
              <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
              <p class="ml-2 mr-6 font-medium text-gray">Hardware</p>
            </div>
            <div class="flex items-center mb-3">
              <span v-if="banDetails.projectType?.includes('Software')" class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
              <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
              <p class="ml-2 mr-6 font-medium text-gray">Software</p>
            </div>
            <div class="flex items-center mb-3">
              <span v-if="banDetails.projectType?.includes('Service')" class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
              <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
              <p class="ml-2 mr-6 font-medium text-gray">Service</p>
            </div>
            <div class="flex items-center mb-3">
              <span v-if="banDetails.projectType?.includes('Maintenance')"  class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
              <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
              <p class="ml-2 mr-6 font-medium text-gray">Maintenance</p>
            </div>
            <div class="flex items-center mb-3">
              <span v-if="banDetails.projectType?.includes('Link-Communication')" class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
              <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
              <p class="ml-2 mr-6 font-medium text-gray">Link-Communication</p>
            </div>
          </div>
        </div>
        <div class="mb-4">
          <NegotiationDealTable :items="banDetails"/>
        </div>
        <div class="grid gap-x-6 gap-y-3.5 pb-3.5"
          :class="{
            'grid-cols-1': projectDetailContainerWidth < 768,
            'grid-cols-2': projectDetailContainerWidth >= 768
          }">
          <InputDisabled v-if="banDetails.firstNegotiatorName" label="Negotiator 1" :value="banDetails.firstNegotiatorName?.toLowerCase()" class="capitalize"></InputDisabled>
          <InputDisabled v-if="banDetails.secondNegotiatorName" label="Negotiator 2" :value="banDetails.secondNegotiatorName?.toLowerCase() ?? '-'" class="capitalize"></InputDisabled>
          <InputDisabled v-if="banDetails.itpVerificatorName" label="ITP Verificator (S5-S4)" :value="banDetails.itpVerificatorName?.toLowerCase()" class="capitalize"></InputDisabled>
          <InputDisabled v-if="banDetails.directApproverItpName" label="Approval ITP (S3-S1)" :value="banDetails.directApproverItpName?.toLowerCase()" class="capitalize"></InputDisabled>
          <InputDisabled v-if="banDetails.representGsitName" label="Representative GSIT (S3-S2)" :value="banDetails.representGsitName?.toLowerCase()" class="capitalize"></InputDisabled>
          <InputDisabled v-if="banDetails.directApproverGsitName" label="Approval GSIT (S1)" :value="banDetails.directApproverGsitName?.toLowerCase()" class="capitalize"></InputDisabled>
          <InputDisabled v-if="banDetails.representUkkpName" label="Representative UKKP (S3-S2)" :value="banDetails.representUkkpName?.toLowerCase()" class="capitalize"></InputDisabled>
          <InputDisabled v-if="banDetails.directApproverUkkpName" label="Approval UKKP (S1)" :value="banDetails.directApproverUkkpName?.toLowerCase()" class="capitalize"></InputDisabled>
        </div>
        <div class="grid gap-x-6 gap-y-3.5 pb-3.5"
          :class="{
            'grid-cols-1': projectDetailContainerWidth < 768,
            'grid-cols-2': projectDetailContainerWidth >= 768
          }">
          <div class="col-span-2">
            <div class="flex items-center">
              <label class="block mb-1 text-sm text-gray">
                Term of Payment
              </label>
            </div>
            <div class="">
              <TiptapView v-if="banDetails.termOfPayment" v-model="banDetails.termOfPayment" />
              <InputDisabled value="-" v-else/>
            </div>
          </div>
          <div :class="{
            'col-span-2': projectDetailContainerWidth < 768,
            'col-span-1': projectDetailContainerWidth >= 768}"
            >
            <InputDisabled label="Waiver Bg" :value="waiverBgMap[banDetails.waiverBg]"></InputDisabled>
          </div>
          <div class="col-span-2">
            <div class="flex items-center">
              <label class="block mb-1 text-sm text-gray">
                Negotiation Note/ BCA Request
              </label>
            </div>
            <div class="">
              <TiptapView v-if="banDetails.negotiationNote" v-model="banDetails.negotiationNote" />
              <InputDisabled value="-" v-else/>
            </div>
          </div>
          <div class="col-span-2">
            <div class="flex items-center">
              <label class="block mb-1 text-sm text-gray">
                Note Internal BCA
              </label>
            </div>
            <div class="">
              <TiptapView v-if="banDetails.internalNote" v-model="banDetails.internalNote" />
              <InputDisabled value="-" v-else/>
            </div>
          </div>
          <div v-if="banDetails?.fileQuotationFileName" class="col-span-2">
            <div class="mb-1">
              <p class="mb-1 text-sm text-gray">File Quotation</p>
            </div>
            <div class="flex items-center justify-between w-full border rounded-lg border-primary h-14 min-h-file">
              <div class="px-4 py-2">
                <p class="text-base font-medium text-gray">{{ banDetails?.fileQuotationFileName }}</p>
              </div>
              <div v-if="banDetails?.fileQuotationFileName" class="flex items-center px-4">
                <p class="text-xl cursor-pointer icon-eye text-primary hover:text-primary-darkest" @click="openFile(banDetails?.fileQuotationFileName, banDetails?.fileQuotationDocumentNo)"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="border border-gray-light rounded-lg p-3.5">
          <div class="flex items-center mb-6">
            <span class="mr-4 icon-list"></span>
            <h5 class="text-lg font-medium text-gray-darkest">Summary Negotiation</h5>
          </div>
          <div class="grid gap-x-6 gap-y-3.5 pb-3.5"
            :class="{
              'grid-cols-1': projectDetailContainerWidth < 768,
              'grid-cols-2': projectDetailContainerWidth >= 768
            }">
            <div>
              <InputDisabled label="Initial Price" size="medium" :value="summaryWithText" />
            </div>
            <div>
              <InputDisabled label="Final Price" size="medium" :value="banDetails.negotiationStatus"></InputDisabled>
            </div>
          </div>
          <SummaryNegotiationTable/>
        </div>
        <div class="pb-6">
          <div class="flex flex-wrap items-center justify-between w-full gap-3 mt-3 mb-6">
            <div class="flex items-center pt-3 pr-4">
              <p class="text-2xl font-bold icon-list text-gray"></p>
              <h4 class="pl-3 text-lg font-medium">Negotiation details</h4>
            </div>
            <div class="">
              <div class="flex items-center cursor-pointer" @click="clickToggleMaximizeTable">
                <span class="mr-3 text-lg icon-maximize text-gray hover:text-gray-dark"></span>
                <p class="text-sm font-semibold text-gray hover:text-gray-dark">Maximize table</p>
              </div>
              <MaximizeTable v-if="isOpenMaximizeTable" :isOpen="isOpenMaximizeTable" @clickToggle="clickToggleMaximizeTable"/>
            </div>
          </div>
          <NegotiationTableDetails v-if="menuRfpNegotiationOfferList?.canRead" />
        </div>
      </div>
    </div>
    <BanLoading v-else/>
  </div>
</template>

<script>

import TiptapView from '@/core/components/custom/TiptapView.vue'
import NegotiationTableDetails from '@/core/components/rfp/negotiation/NegotiationTableDetails.vue';
import NegotiationDealTable from '@/core/components/rfp/negotiation/NegotiationDealTable.vue';
import MaximizeTable from '@/core/components/rfp/negotiation/MaximizeTable.vue';
import SummaryNegotiationTable from '@/core/components/rfp/negotiation/SummaryNegotiationTable.vue'
import InputDisabled from '@/core/components/custom/InputDisabled.vue'
import { WF_RFP_GSIT, WF_RFP_GSIT_UKKP, WF_RFP_UKKP } from '@/core/constant/negotiation.js'
import { formatDate3 } from '@/core/components/helpers/format.js'
import BanLoading from '@/core/components/rfp/loading/BanLoading.vue';
import { CANCELED } from '@/core/constant/statusProject'

export default {
  name: 'DetailBan',
  data() {
    return {
      optionWaiverBg: [
              { value: 'TANPA_WAIVER', text: 'No Waiver'}, 
              { value: 'WAIVER_PERFOMANCE_BOND', text: 'Performance Bond' }, 
              { value: 'WAIVER_ADVANCE_PAYMENT_BOND', text: 'Advance Payment Bond' }, 
              { value: 'WAIVER_ADVANCE_PAYMENT_BOND_AND_PERFORMANCE_BOND', text: 'Advance Payment Bond and Performance Bond'}],
      waiverBgMap: {
        TANPA_WAIVER: 'No Waiver',
        WAIVER_PERFOMANCE_BOND: 'Performance Bond',
        WAIVER_ADVANCE_PAYMENT_BOND: 'Advance Payment Bond',
        WAIVER_ADVANCE_PAYMENT_BOND_AND_PERFORMANCE_BOND: 'Advance Payment Bond and Performance Bond'
      },
      summaryWithText: '',
      isOpenMaximizeTable: false,
      isLoading: false,
    }
  },
  computed: {
    menuRfpNegotiationOfferList() {
			return this.$store.getters['dashboard/menuRfpNegotiationOfferList']
    },
    menuRfpNegotiationBanReport() {
			return this.$store.getters['dashboard/menuRfpNegotiationBanReport']
    },
    projectDetailContainerWidth() {
      return this.$store.state.width.projectDetailContainerWidth
    },
    banDetails() {
      return this.$store.state.negotiation.banDetails
    },
    negotiationDetails() {
      return this.$store.state.negotiation.negotiationDetails
    },
    optionInitialPrice() {
      return this.banDetails.negotiationHistories?.map(e => ({ text: e.negotiationStatusText, value: e.rfpNegotiationId }))
    },
    detailBanApprover() {
      return this.$store.state.negotiation.detailBanApprover
    },
    workflowGsit() {
      return this.banDetails.workflow === WF_RFP_GSIT
    },
    workflowGsitUkkp() {
      return this.banDetails.workflow === WF_RFP_GSIT_UKKP
    },
    workflowUkkp() {
      return this.banDetails.workflow === WF_RFP_UKKP
    },
    modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    },
    loading() {
      return this.$store.state.modal.loading
    },
    counterIntervalApproval() {
			return this.$store.state.negotiation.counterInterval
		},
    projectLogRfp() {
      return this.$store.state.projectLog.projectLogRfp
    },
    someInProgress() {
			return this.projectLogRfp?.rfpLogs?.some(e => e.isInProgress || e.additionalLogs?.some(el => el.isInProgress))
		},
    sppProcess() {
			return this.$store.state.spp.sppProcess
		},
    projectCanceledByLogs() {
			return this.projectLogRfp.rfpLogs.some(e => e.statusProject === CANCELED)
		},
		projectCanceled() {
			return this.sppProcess || this.projectCanceledByLogs
		},
  },
  methods: {
    formatDate3,
    clickToggleMaximizeTable() {
			this.isOpenMaximizeTable = !this.isOpenMaximizeTable
		},
    clickBackToVendorDetail() {
      this.$router.go(-1)
      // this.$router.push({ name: 'projectNegotiation', query: { tab: 'BAN' }}).catch(() => ({}))
    },
    textStatusApproved(status) {
      if (status?.toLowerCase().includes('approved')) {
        return 'text-success'
      } else {
        return 'text-error'
      }

    },
    async clickViewBan() {
      try {
        this.modal.modalLoading = true
        const response = await this.$store.dispatch('negotiation/getBanReport', { negotiationId: this.banDetails.negotiationId })
        if (response?.status < 300 && response?.status >= 200) {
          this.modal.modalLoading = false
        } else {
          this.$store.dispatch('modal/throwError', { response })
        }
      } catch (error) {
        this.modal.modalLoading = false
        this.modal.modalError = { value: true, traceid: error.traceid, title: error.title, message: error.message }
      }
    },
    openFile(filename, documentNo) {
      this.$store.dispatch('file/viewAttachment', { filename, documentNo })
    },
    showStatus(status) {
      return status?.toLowerCase().includes('on review') && !this.someInProgress && !this.projectCanceled
    }
  },
  async mounted() {
    this.isLoading = true
    const r1 = this.$store.dispatch('negotiation/getDetailBanApprover', { negotiationId: this.$route.query.negotiation })
    const r2 = this.$store.dispatch('negotiation/getBanDetails', { negotiationId: this.$route.query.negotiation })
    const [res1, res2] = await Promise.all([r1, r2])

		const r3 = this.$store.dispatch('negotiation/getNegotiationDetails', { vendorCandidateId: this.banDetails.vendorCandidateId })
    const r4 = this.$store.dispatch('negotiation/getNegotiationOfferItems', { vendorCandidateId: this.banDetails.vendorCandidateId })
    const r5 = this.$store.dispatch('negotiation/getNegotiationExportMenu', { vendorCandidateId: this.banDetails.vendorCandidateId })
    const r6 = this.$store.dispatch('negotiation/getSummaryWith', { negotiationId: this.banDetails.summaryWith ||this.banDetails.negotiationHistories[0].rfpNegotiationId, vendorCandidateId: this.banDetails.vendorCandidateId })
    const [res3, res4, res5, res6] = await Promise.all([r3, r4, r5, r6])
    if ([res1?.status, res2?.status, res3?.status, res4?.status, res5?.status, res6?.status].every(e => e === 200)) {
      this.isLoading = false
      this.summaryWithText = this.banDetails.summaryWith? this.optionInitialPrice[this.optionInitialPrice.findIndex(e => e.value === this.banDetails.summaryWith)]?.text :this.optionInitialPrice[0]?.text
    }
  },
  destroyed() {
    this.$store.commit('negotiation/SET_SHOW_DETAIL_BAN', false)
    clearInterval(this.counterIntervalApproval)
		this.$store.commit('negotiation/SET_COUNTER_INTERVAL', null)

  },
  components: {
    TiptapView,
    NegotiationTableDetails,
    NegotiationDealTable,
    MaximizeTable,
    SummaryNegotiationTable,
    InputDisabled,
    BanLoading
  }

}
</script>

<style scoped>
.min-width-table {
  min-width: 56rem;
}
.min-w-custom {
  min-width: 72rem;
}
.min-w-custom2 {
  min-width: 36rem;
}
.min-h-file {
  min-height: 3.5rem;
}
</style>