<template>
  <div>
    <ButtonGista 
      type="primary" 
      class="w-full" 
      :color="color" 
      @click="changeIsOpen(true)" 
      :disabled="disabled"
      >Continue Draft</ButtonGista>
    <ModalConfirm 
      title="Lanjutkan Draft?" 
      :message="`Draft terakhir: ${ lastDate }`" 
      icon="saved" textButton="Continue Draft" 
      :isOpen="isOpen" 
      @changeIsOpen="changeIsOpen($event)" 
      @click="clickContinueDraft"
      colorButton="success">
    </ModalConfirm>
  </div>
</template>

<script>
import ModalConfirm from '@/core/components/modal/Confirm'

  export default {
    name: 'ContinueDraft',
    props: ['isOpen', 'lastDate', 'disabled', 'color'],
    methods: {
      changeIsOpen(val) {
        this.$emit('clickToggle', val)
      },
      clickContinueDraft() {
        this.$emit('click')
        this.changeIsOpen(false)
      }
    },
    components: {
      ModalConfirm
    }
  }
</script>