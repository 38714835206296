<template>
  <div>
    <div v-for="vendor, i in dataProcurementApproval?.negotiationResultList" :key="i">
      <div class="w-full mb-3 border rounded-lg border-gray-lightest" v-if="!vendor.localPartnerList?.length && vendor.negotiationResultDetails">
        <div class="flex">
          <div class="flex-1 mb-1 p-3.5">
            <h5 class="mb-1 text-sm font-medium text-tertiary">Vendor #{{ vendor?.vendorSeq }}</h5>
            <h5 class="text-base font-medium text-gray-darkest">{{ vendor?.vendorName }}</h5>
          </div>
          <div class="flex-none flex">
            <div v-if="!vendor.collapse" class="py-3.5">
              <p class="text-sm font-medium text-gray flex justify-end">Grand Total In IDR</p>
              <p class="text-lg font-bold text-primary-darkest flex justify-end">{{ formatCurrency(vendor.negotiationResultDetails.grandTotalIdr) }}</p>
            </div>
            <div v-if="vendor.collapse" class="flex py-4 cursor-pointer" @click="openModal(i, null, vendor)">
              <span class="mr-3 text-lg icon-maximize text-gray hover:text-gray-dark"></span>
              <p class="text-sm font-semibold text-gray hover:text-gray-dark">Maximize Table</p>
            </div>
            <div>
              <p class="text-2xl transition ease-in transform icon-chevron-down text-gray p-4 cursor-pointer" :class="vendor.collapse ? 'rotate-180' : 'rotate-0'" @click="changeCollapseVendor(i)"></p>
            </div>
          </div>
        </div>
        <ResultTable :ref="`resultTable${i}`" :index="i" :indexJ="null" :readOnly="readOnly" v-if="vendor.collapse"/>
        <div class="col-span-2 px-3.5 pt-3.5 my-1">
          <div class="flex items-center">
            <div class="block mb-1 text-sm text-gray" :class="vendor.errorMaxLength['Note Internal BCA']? 'text-error' : 'text-gray'">
              Note Internal BCA
            </div>
          </div>
          <div>
            <Tiptap v-if="(!isSubmitted || isRejected) && !readOnly" @char="(e) => characterCount(e, 'Note Internal BCA', i, null)" v-model="vendor.negotiationResultDetails.noteInternal" :error="!!vendor.errorMaxLength['Note Internal BCA']">
              <template #message>
                <p>{{ vendor.errorMaxLength['Note Internal BCA'] }}</p>
              </template>
            </Tiptap>
            <TiptapView v-else-if="isSubmitted" v-model="vendor.negotiationResultDetails.noteInternal" />
            <InputDisabled value="-" v-else/>
          </div>
        </div>
        <div class="col-span-2 p-3.5 my-1">
          <div class="flex items-center">
            <p class="block mb-1 text-sm text-gray">
              Term of Payment
            </p>
          </div>
          <div class="">
            <TiptapView v-model="vendor.negotiationResultDetails.termOfPayment" />
          </div>
        </div>
      </div>
      <div class="w-full mb-3 border rounded-lg border-gray-lightest" v-else v-for="local,j in vendor.localPartnerList" :key=j>
        <div v-if="local.negotiationResultDetails">
          <div class="flex">
            <div class="flex-1 mb-1 p-3.5">
              <h5 class="mb-1 text-sm font-medium text-tertiary">Vendor #{{ vendor?.vendorSeq }}</h5>
              <h5 class="text-base font-medium text-gray-darkest">{{ vendor?.vendorName }}</h5>
              <div class="w-full pl-4 mt-3 border-l border-gray-light">
                <h5 class="mb-1 text-sm font-medium text-tertiary">Local Partner #{{ local?.vendorSeq }}</h5>
                <h5 class="text-sm font-medium text-gray-darkest">{{ local?.vendorName }}</h5>
              </div>
            </div>
            <div class="flex-none flex">
              <div v-if="!local.collapse" class="py-3.5">
                <p class="text-sm font-medium text-gray flex justify-end">Grand Total In IDR</p>
                <p class="text-lg font-bold text-primary-darkest flex justify-end">{{ formatCurrency(local.negotiationResultDetails.grandTotalIdr) }}</p>
              </div>
              <div v-if="local.collapse" class="flex py-4 cursor-pointer" @click="openModal(i, j, vendor)">
                <span class="mr-3 text-lg icon-maximize text-gray hover:text-gray-dark"></span>
                <p class="text-sm font-semibold text-gray hover:text-gray-dark">Maximize Table</p>
              </div>
              <div>
                <p class="text-2xl transition ease-in transform icon-chevron-down text-gray p-4 cursor-pointer" :class="local.collapse ? 'rotate-180' : 'rotate-0'" @click="changeCollapseVendor(i,j)"></p>
              </div>
            </div>
          </div>
          <ResultTable :ref="`resultTable${i}${j}`" :index="i" :indexJ="j" v-if="local.collapse" :readOnly="readOnly"/>
          <div class="col-span-2 px-3.5 pt-3.5 my-1">
            <div class="flex items-center">
              <div class="block mb-1 text-sm text-gray" :class="local.errorMaxLength['Note Internal BCA']? 'text-error' : 'text-gray'">
                Note Internal BCA
              </div>
            </div>
            <div class="">
              <Tiptap v-if="(!isSubmitted || isRejected) && !readOnly" @char="(e) => characterCount(e, 'Note Internal BCA', i, j)" v-model="local.negotiationResultDetails.noteInternal" :error="!!local.errorMaxLength['Note Internal BCA']">
                <template #message>
                  <p>{{ local.errorMaxLength['Note Internal BCA'] }}</p>
                </template>
              </Tiptap>
              <TiptapView v-else-if="isSubmitted" v-model="local.negotiationResultDetails.noteInternal" />
              <InputDisabled value="-" v-else/>
            </div>
          </div>
          <div class="col-span-2 p-3.5 my-1">
            <div class="flex items-center">
              <p class="block mb-1 text-sm text-gray">
                Term of Payment
              </p>
            </div>
            <div class="">
              <TiptapView v-model="local.negotiationResultDetails.termOfPayment" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <gista-modal
      :value="isOpenMaximize"
			:scrollable="true"
      width="11/12"
			@input="closeModal()"
			customClass="bg-white mt-6 mb-8 max-w-screen-2xl" id="modal">
      <div id="modal-title">
        <div class="flex justify-center py-6">
          <h5 class="text-lg font-medium text-gray-darkest">Negotiation Result</h5>
        </div>
        <div class="mb-1 p-3.5">
          <h5 class="mb-1 text-sm font-medium text-tertiary">Vendor #{{ vendorSelected?.vendorSeq }}</h5>
          <h5 class="text-base font-medium text-gray-darkest">{{ vendorSelected?.vendorName }}</h5>
          <div v-if="vendorSelected?.localPartnerList?.length > 0" class="w-full pl-4 mt-3 border-l border-gray-light">
            <h5 class="mb-1 text-sm font-medium text-tertiary">Local Partner #{{ vendorSelected?.localPartnerList?.[indexJ]?.vendorSeq }}</h5>
            <h5 class="text-sm font-medium text-gray-darkest">{{ vendorSelected?.localPartnerList?.[indexJ]?.vendorName }}</h5>
          </div>
        </div>
      </div>
      <ResultTableModal 
        :index="indexVendor" :indexJ="indexJ" :readOnly="readOnly" 
        :heightModal="heightModal" :heightModalTitle="heightModalTitle" :heightModalSave="heightModalSave || 0" @set-height="setHeight"/>
      <div id="modal-save" class="flex justify-end p-6" v-if="!isSubmitted || isRejected && !readOnly">
        <ButtonGista 
          @click="clickSave(indexVendor, indexJ)"
          color="success"
          customClass="sm:w-56 w-full"
          >
          Save
        </ButtonGista>
      </div>
    </gista-modal>
  </div>
</template>

<script>
import ResultTable from '@/core/components/rfp/procurementApproval/ResultTable.vue'
import ResultTableModal from '@/core/components/rfp/procurementApproval/ResultTableModal.vue'
import Tiptap from '@/core/components/custom/Tiptap.vue'
import InputDisabled from '@/core/components/custom/InputDisabled.vue'
import TiptapView from '@/core/components/custom/TiptapView.vue'
import { deepCloneAdvanced } from '@/core/components/helpers'
import { formatCurrency } from '@/core/components/helpers/formatRupiah'

export default {
  name: "NegotiationResult",
  data(){
    return {
      isOpenMaximize: false,
      vendorSelected: {},
      indexVendor: 0,
      indexJ: null,
      heightModal: 0,
      heightModalTitle: 0,
      heightModalSave: 0
    }
  },
  props: ['collapse', 'readOnly'],
  computed:{
    projectDetailContainerWidth() {
      return this.$store.state.width.projectDetailContainerWidth
    },
    dataProcurementApproval: {
      get() {
        return this.$store.state.procurementApproval.dataProcurementApproval
      },
      set(value) {
        this.$store.commit('procurementApproval/SET_DATA_PROCUREMENT_APPROVAL', value)
      }
    },
    isSubmitted() {
      return this.dataProcurementApproval?.isSubmitted
    },
    logApproval() {
      return this.$store.state.procurementApproval.logApproval
    },
    isRejected() {
      return this.dataProcurementApproval?.isRejected
    },
    negotiationResultList: {
      get() {
        return this.$store.state.procurementApproval.negotiationResultList
      },
      set(value) {
        this.$store.commit('procurementApproval/SET_NEGOTIATION_RESULT_LIST', value)
      }
    }
  },
  methods:{
    formatCurrency,
    changeCollapseVendor(i,j) {
      if (j === undefined) {
        this.dataProcurementApproval.negotiationResultList[i].collapse = !this.dataProcurementApproval.negotiationResultList[i].collapse
      } else {
        this.dataProcurementApproval.negotiationResultList[i].localPartnerList[j].collapse = !this.dataProcurementApproval.negotiationResultList[i].localPartnerList[j].collapse
      }
    },
    openModal(index, indexJ, vendor) {
      this.isOpenMaximize = true
      this.indexVendor = index
      this.indexJ = indexJ
      this.vendorSelected = vendor
      this.negotiationResultList = deepCloneAdvanced(this.dataProcurementApproval.negotiationResultList)
    },
    closeModal() {
      this.isOpenMaximize = false
    },
    characterCount(e, name, i, j) {
      const MAX_CHARACTER = 4500
      if (j === null) {
        if (e > MAX_CHARACTER) this.dataProcurementApproval.negotiationResultList[i].errorMaxLength[name] = `Kolom ${name} Maksimal 4500 Karakter`
        else this.dataProcurementApproval.negotiationResultList[i].errorMaxLength[name] = ''
      } else {
        if (e > MAX_CHARACTER) this.dataProcurementApproval.negotiationResultList[i].localPartnerList[j].errorMaxLength[name] = `Kolom ${name} Maksimal 4500 Karakter`
        else this.dataProcurementApproval.negotiationResultList[i].localPartnerList[j].errorMaxLength[name] = ''
      }
    },
    clickSave(indexVendor, indexJ) {
      this.dataProcurementApproval.negotiationResultList = deepCloneAdvanced(this.negotiationResultList)
      this.$store.dispatch('procurementApproval/getApprovalProcurementAll', { isMaximize: false })
      if (indexJ === null) {
        this.$refs[`resultTable${indexVendor}`][0].checkAllSelected()
      } else {
        this.$refs[`resultTable${indexVendor}${indexJ}`][0].checkAllSelected()
      }
      this.closeModal()
    },
    setHeight() {
      this.heightModal = document.getElementById('modal')?.offsetHeight
      this.heightModalTitle = document.getElementById('modal-title')?.offsetHeight
      this.heightModalSave = document.getElementById('modal-save')?.offsetHeight
      window.addEventListener(
        'resize',
        () => {
          this.heightModal = document.getElementById('modal')?.offsetHeight
          this.heightModalTitle = document.getElementById('modal-title')?.offsetHeight
          this.heightModalSave = document.getElementById('modal-save')?.offsetHeight
        }
      )
    },
  },
  components:{
    ResultTable,
    Tiptap,
    TiptapView,
    InputDisabled,
    ResultTableModal
  }
}
</script>