<template>
	<div class="w-full mt-6">
		<div class="w-full">
			<div class="flex items-center mb-6">
				<span class="mr-6 text-lg icon-file-text"></span>
				<h4 class="text-base font-semibold text-gray-dark">PROPOSAL</h4>
			</div>

			<div class="w-full">
				<div v-for="(n, i) in qualifications"	:key="i" >
					<div v-if="showVendorProposal(i) && notPrequalification(i)" class="mb-4 border border-gray-lightest rounded-xl">
						<div class="flex flex-wrap w-full pl-4 py-3.5">
							<div class="flex-auto pr-4 mb-3" :class="projectDetailContainerWidth > 800? 'w-1/4': 'w-full'">
								<div class="flex flex-wrap">
									<div class="flex mb-1">
										<h6 class="text-tertiary">Vendor #{{ n?.sequence }}</h6>
										<p class="icon-chevron-right text-tertiary"></p>
									</div>
									<div v-if="n.isNewVendor"
										class="flex px-4 py-1 mb-1 text-xs rounded-lg bg-success-lightest text-success">
										<h6>New Vendor</h6>
									</div>
								</div>
								<div>
									<h5 class="font-semibold">{{ n?.vendorName }}</h5>
									<p class="text-xs break-words text-gray-dark">{{ n?.vendorEmail }}</p>
									<div class="flex flex-wrap items-center mr-3">
										<p class="mr-3 text-sm font-medium text-gray">Required Documents:</p>
										<p class="text-sm text-success">{{n.isCompletedRequiredDocuments? 'Completed' : ''}}</p>
									</div>
								</div>
							</div>
							<div class="flex flex-auto" :class="projectDetailContainerWidth > 800? 'w-2/4': 'w-full'">
								<div v-if="n.localPartners?.length === 0 || allLocalPartnerEliminated(i) || everyLocalPartnerCandidate(n)" class="flex flex-col justify-center w-full" :class="`${projectDetailContainerWidth > 800 && n.localPartners?.length === 0? 'border-l border-r border-gray-lightest px-4': 'pr-4'}`" >
									<div class="w-full">
										<label class="text-sm text-gray">Proposal Technical:</label>
										<div class="flex items-center justify-between w-full">
											<div v-if="n.proposalFileAddress && !statusWaitingChanges(i)" class="w-full mt-1 mb-6">
												<div class="flex items-center justify-between w-full border rounded-lg border-primary">
													<div class="p-4 truncate">
														<p class="text-gray truncate">{{n?.proposalFileName}}</p>
													</div>
													<div class="p-4 cursor-pointer" @click="viewAttachment(n.proposalFileName, n.proposalFileDocumentNo)">
														<p class="text-2xl icon-eye text-primary hover:text-primary-dark"></p>
													</div>
												</div>
											</div>
											<div v-else class="w-full">
												<div class="flex items-center justify-center h-20">
													<p class="font-semibold text-warning">Vendor Proposal Not Yet Uploaded by ITP</p>
												</div>
											</div>
										</div>
									</div>
									<div class="w-full">
										<label class="text-sm text-gray">Proposal Price:</label>
										<div class="flex items-center justify-between w-full">
											<div v-if="n.proposalPriceFileAddress && !statusWaitingChanges(i)" class="w-full mt-1 mb-6">
												<div class="flex items-center justify-between w-full border rounded-lg border-primary">
													<div class="p-4 truncate">
														<p class="text-gray truncate">{{n?.proposalPriceFileName}}</p>
													</div>
													<div class="p-4 cursor-pointer" @click="viewAttachment(n.proposalPriceFileName, n.proposalPriceFileDocumentNo)">
														<p class="text-2xl icon-eye text-primary hover:text-primary-dark"></p>
													</div>
												</div>
											</div>
											<div v-else class="w-full">
												<div class="flex items-center justify-center h-20">
													<p class="font-semibold text-warning">Vendor Proposal Not Yet Uploaded by ITP</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div 
								class="flex items-center justify-center flex-auto"
								:class="projectDetailContainerWidth > 800? 'w-1/4': 'w-full'"
								>
								<div class="flex flex-wrap items-center justify-center w-full" :class="projectDetailContainerWidth < 640 ? '' : 'px-4'" v-if="n.localPartners?.length === 0 || allLocalPartnerEliminated(i)">
									<ProposalRevision v-if="menuQualificationActionRevision?.canUpdate && n.proposalFileAddress && proposalSent(i) && !proposalVendorApproved(i) && !statusVendorRejected(i) && !statusWaitingChanges(i)" 
										class="mb-3 mr-3" 
										:class="projectDetailContainerWidth < 640 ? 'w-full' : 'w-40'"
										:qualification="n" :index="i" />
									<ProposalApprove v-if="menuQualificationActionApprove?.canUpdate && n.proposalFileAddress && proposalSent(i) && !proposalVendorApproved(i) && !statusVendorRejected(i) && !statusWaitingChanges(i)" 
										class="mb-3 mr-3" 
										:class="projectDetailContainerWidth < 640 ? 'w-full' : 'w-40'"
										:qualification="n" :index="i" :isLocalPartner="false"/>
									<div v-if="proposalVendorApprovedText(i)" class="flex items-center">
										<p class="text-sm font-medium text-success">Approved</p>
									</div>
									<div v-if="statusVendorRejectedText(i)" class="flex items-center">
										<p class="text-sm font-medium text-gray">Reject note sent</p>
									</div>
									<div v-if="!proposalVendorApprovedText(i) && !statusVendorRejectedText(i) && !roleUser && !roleOPRITP && n.submittedProposal" class="flex items-center">
										<p class="text-sm font-medium text-gray">In Review</p>
									</div>
								</div>
							</div>
						</div>
						<div class="pl-4" :class="{'pb-4': n.localPartners?.length > 0}">
							<div v-for="(local, j) in n.localPartners" :key="j" >
								<div v-if="local.statusVendor !== PRA_QUALIFICATION_ELIMINATED && local.statusVendor !== QUALIFICATION_ELIMINATED" class="border-l border-gray-light">
									<div v-if="statusLocalPartnerNotPraqualification(local)" class="flex flex-wrap w-full py-3.5">
										<div class="flex-auto px-4 mb-3 " :class="projectDetailContainerWidth > 800? 'w-1/4': 'w-full'">
											<div class="flex flex-wrap">
												<div class="flex items-center mb-1 mr-1">
													<h6 class="text-tertiary">Local Partner #{{ j + 1 }}</h6>
													<p class="icon-chevron-right text-tertiary"></p>
												</div>
												<div v-if="local.isNewVendor"
													class="flex px-4 py-1 mb-1 mr-1 text-xs rounded-lg bg-success-lightest text-success">
													<h6>New Vendor</h6>
												</div>
											</div>
											<div>
												<h5 class="font-semibold">{{ local?.vendorName }}</h5>
												<p class="text-xs break-words text-gray-dark">{{ local?.vendorEmail }}</p>
												<div class="flex flex-wrap items-center mr-3">
													<p class="mr-3 text-sm font-medium text-gray">Required Documents:</p>
													<p class="text-sm text-success">{{local?.isCompletedRequiredDocuments? 'Completed' : ''}}</p>
												</div>
											</div>
										</div>

										<div class="flex flex-auto" :class="projectDetailContainerWidth > 800? 'w-2/4': 'w-full px-4'">
											<div class="w-full" :class="`${projectDetailContainerWidth > 800? 'border-l border-r border-gray-lightest px-4': ''}`">
												<div v-if="!statusLocalPartnerEliminated(i, j)" class="w-full">
													<label class="text-sm text-gray">Proposal Technical:</label>
													<div class="flex items-center justify-between w-full">
														<div v-if="local.proposalFileAddress && !statusWaitingChangesLocalPartner(i,j)" class="w-full mt-1 mb-6">
															<div class="flex items-center justify-between w-full border rounded-lg border-primary">
																<div class="p-4 truncate">
																	<p class="text-gray truncate">{{local?.proposalFileName}}</p>
																</div>
																<div class="p-4 cursor-pointer" @click="viewAttachment(local.proposalFileAddress, local.proposalFileDocumentNo)">
																	<p class="text-2xl icon-eye text-primary hover:text-primary-dark"></p>
																</div>
															</div>
														</div>
														<div v-else class="w-full">
															<div class="flex items-center justify-center h-20">
																<p class="font-semibold text-warning">Vendor Proposal Not Yet Uploaded by ITP</p>
															</div>
														</div>
													</div>
												</div>
												<div v-if="!statusLocalPartnerEliminated(i, j)" class="w-full">
													<label class="text-sm text-gray">Proposal Price:</label>
													<div class="flex items-center justify-between w-full">
														<div v-if="local.proposalPriceFileAddress && !statusWaitingChangesLocalPartner(i,j)" class="w-full mt-1 mb-6">
															<div class="flex items-center justify-between w-full border rounded-lg border-primary">
																<div class="p-4 truncate">
																	<p class="text-gray truncate">{{local?.proposalPriceFileName}}</p>
																</div>
																<div class="p-4 cursor-pointer" @click="viewAttachment(local.proposalPriceFileName, local.proposalPriceFileDocumentNo)">
																	<p class="text-2xl icon-eye text-primary hover:text-primary-dark"></p>
																</div>
															</div>
														</div>
														<div v-else class="w-full">
															<div class="flex items-center justify-center h-20">
																<p class="font-semibold text-warning">Vendor Proposal Not Yet Uploaded by ITP</p>
															</div>
														</div>
													</div>
												</div>
												<div v-if="statusLocalPartnerEliminated(i, j) && !statusWaitingChangesLocalPartner(i, j)">
													<div class="flex w-full">
														<div class="w-full mt-1 mb-6">
															<div class="w-full h-full">
																<div class="">
																	<p class="text-sm font-medium text-gray">Eliminate Reason:</p>
																</div>
																<div class="pt-2">
																	<p v-if="local?.disqualifiedReason?.length > 100" class="text-sm break-words whitespace-pre-wrap text-gray">{{local?.disqualifiedReason.slice(0,100)}}... <span class="cursor-pointer text-secondary" @click="readMore(local?.disqualifiedReason)">Read More</span></p>
																	<p v-else class="text-sm break-words whitespace-pre-wrap text-gray">{{local?.disqualifiedReason}}</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div 
											class="flex items-center justify-center flex-auto"
											:class="projectDetailContainerWidth > 800? 'w-1/4': 'w-full'"
											>
											<div class="flex flex-wrap items-center justify-center w-full px-4">
												<ProposalRevision v-if="menuQualificationActionRevision?.canUpdate && local.proposalFileAddress && proposalSentLocalPartner(i,j) && !proposalVendorApprovedLocalPartner(i,j) && !statusVendorRejectedLocalPartners(i,j) && !statusWaitingChangesLocalPartner(i,j) && !statusLocalPartnerEliminated(i, j)" 
													class="mb-3 mr-3" 
													:class="projectDetailContainerWidth < 640 ? 'w-full' : 'w-40'"
													:qualification="local" :index="j" />
												<ProposalApprove v-if="menuQualificationActionApprove?.canUpdate && local.proposalFileAddress && proposalSentLocalPartner(i,j) && !proposalVendorApprovedLocalPartner(i,j) && !statusVendorRejectedLocalPartners(i) && !statusWaitingChangesLocalPartner(i,j) && !statusLocalPartnerEliminated(i, j)" 
													class="mb-3 mr-3" 
													:class="projectDetailContainerWidth < 640 ? 'w-full' : 'w-40'"
													:qualification="local" :index="j" :isLocalPartner="true"/>
												<div v-if="proposalVendorApprovedTextLocalPartner(i, j)" class="flex items-center">
													<p class="text-sm font-medium text-success">Approved</p>
												</div>
												<div v-if="statusVendorRejectedTextLocalPartners(i, j)" class="flex items-center">
													<p class="text-sm font-medium text-gray">Reject note sent</p>
												</div>
												<div v-if="!proposalVendorApprovedTextLocalPartner(i, j) && !statusVendorRejectedTextLocalPartners(i, j) && !roleUser && !roleOPRITP && local.submittedProposal" class="flex items-center">
													<p class="text-sm font-medium text-gray">In Review</p>
												</div>
												<div v-if="statusLocalPartnerEliminated(i, j) && !statusWaitingChangesLocalPartner(i, j)" class="flex flex-row flex-auto"
													:class="{'w-1/4 flex-col justify-center items-center px-4':projectDetailContainerWidth > 800}">
													<div v-if="local?.disqualifiedFileAddress" class="flex" :class="{'justify-center items-center':projectDetailContainerWidth > 800}" >
														<span class="mr-2 icon-file"></span>
														<p class="text-sm underline cursor-pointer text-secondary" @click="viewAttachment(local?.disqualifiedFileName, local?.disqualifiedFileDocumentNo)">View Attachment</p>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div v-if="j < lengthLocalPartnerNotPraqualification(n)" class="border-t-2 border-dashed border-gray-lightest"></div>
								</div>
							</div>

						</div>


					</div>
				</div>
			</div>
		</div>
		<ModalReadMore @click="closeModal" :isOpen="readMoreShow" :text="rejectNotesModal" title="Reject Notes"/>
		
	</div>
</template>
<script>
import ProposalRevision from '@/core/components/modal/ProposalRevision.vue';
import ProposalApprove from '@/core/components/modal/ProposalApprove.vue';
import { RFP_SENT, CANDIDATE, PROPOSAL_REJECTED, PROPOSAL_APPROVED, PROPOSAL_UPLOADED, PROPOSAL_SENT, PROPOSAL_WAITING_CHANGES, QUALIFICATION_ELIMINATED, PRA_QUALIFICATION_ELIMINATED, SCORING_DECISION_ELIMINATED, SCORING_DECISION_NEGOTIATION, SCORING_DECISION_APPROVAL } from '@/core/constant/qualification.js'
import ModalReadMore from '@/core/components/modal/ReadMore.vue'


export default {
	name: "SendProposalUser",
	data() {
		return {
			PRA_QUALIFICATION_ELIMINATED,
			QUALIFICATION_ELIMINATED,
			readMoreShow: false,
			rejectNotesModal: ''
		}
	},
	computed: {
		qualifications: {
			get() {
				return this.$store.state.qualification.qualifications
			},
			set(value) {
				this.$store.commit('projectDetail/SET_QUALIFICATOINS', value)
			}
		},
		roleUser() {
			//only user opr gsit and opr kp
			return this.menuQualificationActionApprove?.canUpdate
		},
    roleOPRITP() {
      //only opr itp
			return this.menuQualificationActionSendProposal?.canCreate
    },
		menu() {
			return this.$store.state.dashboard.menu
		},
		menuQualificationActionSendProposal() {
			return this.$store.getters['dashboard/menuQualificationActionSendProposal']
		},
		menuQualificationActionApprove() {
			return this.$store.getters['dashboard/menuQualificationActionApprove']
		},
		menuQualificationActionRevision() {
			return this.$store.getters['dashboard/menuQualificationActionRevision']
		},
		projectDetailContainerWidth() {
			return this.$store.state.width.projectDetailContainerWidth
		},
		projectLogRfp() {
			return this.$store.state.projectLog.projectLogRfp
		},
		modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    }
	},
	watch: {
		
	},
	methods: {
		showVendorProposal(i) {
			return this.qualifications[i].statusVendor !== QUALIFICATION_ELIMINATED && this.qualifications[i].statusVendor !== PRA_QUALIFICATION_ELIMINATED
		},
		notPrequalification(i) {
			return this.qualifications[i].statusVendor !== CANDIDATE
		},
		proposalSent(i) {
			const statusVendor = [PROPOSAL_SENT]
			return statusVendor.includes(this.qualifications[i].statusVendor)
		},
		proposalSentLocalPartner(i,j) {
			const statusVendor = [PROPOSAL_SENT]
			return statusVendor.includes(this.qualifications[i].localPartners[j]?.statusVendor)
		},
		statusVendorRejected(i) {
			const statusVendor = [PROPOSAL_REJECTED, SCORING_DECISION_ELIMINATED, SCORING_DECISION_NEGOTIATION, SCORING_DECISION_APPROVAL]
			return statusVendor.includes(this.qualifications[i].statusVendor)
		},
		statusVendorRejectedLocalPartners(i,j) {
			const statusVendor = [PROPOSAL_REJECTED, SCORING_DECISION_ELIMINATED, SCORING_DECISION_NEGOTIATION, SCORING_DECISION_APPROVAL]
			return statusVendor.includes(this.qualifications[i].localPartners[j]?.statusVendor)
		},
		proposalVendorApproved(i) {
			const statusVendor = [PROPOSAL_APPROVED, SCORING_DECISION_ELIMINATED, SCORING_DECISION_NEGOTIATION, SCORING_DECISION_APPROVAL]
			return statusVendor.includes(this.qualifications[i].statusVendor)
		},
		proposalVendorApprovedLocalPartner(i,j) {
			const statusVendor = [PROPOSAL_APPROVED, SCORING_DECISION_ELIMINATED, SCORING_DECISION_NEGOTIATION, SCORING_DECISION_APPROVAL]
			return statusVendor.includes(this.qualifications[i].localPartners[j]?.statusVendor)
		},
		statusVendorRejectedText(i) {
			const statusVendor = [PROPOSAL_REJECTED]
			return statusVendor.includes(this.qualifications[i].statusVendor)
		},
		statusVendorRejectedTextLocalPartners(i,j) {
			const statusVendor = [PROPOSAL_REJECTED]
			return statusVendor.includes(this.qualifications[i].localPartners[j]?.statusVendor)
		},
		proposalVendorApprovedText(i) {
			return this.qualifications[i].isProposalApproved
		},
		proposalVendorApprovedTextLocalPartner(i,j) {
			return this.qualifications[i].localPartners[j]?.isProposalApproved
		},
		statusLocalPartnerEliminated(i, j) {
			return this.qualifications[i].localPartners[j]?.statusVendor === QUALIFICATION_ELIMINATED || this.qualifications[i].localPartners[j]?.statusVendor === PRA_QUALIFICATION_ELIMINATED
		},
		allLocalPartnerEliminated(i) {
			return this.qualifications[i].localPartners?.every(e => e.statusVendor === QUALIFICATION_ELIMINATED || e.statusVendor === PRA_QUALIFICATION_ELIMINATED)
		},
		statusFileUploaded(i) {
			return this.qualifications[i].statusVendor === PROPOSAL_UPLOADED
		},
		statusWaitingChanges(i) {
			const list = [PROPOSAL_UPLOADED, PROPOSAL_WAITING_CHANGES, RFP_SENT]
			return list.includes(this.qualifications[i].statusVendor)
		},
		statusWaitingChangesLocalPartner(i,j) {
			const list = [PROPOSAL_UPLOADED, PROPOSAL_WAITING_CHANGES, RFP_SENT]
			return list.includes(this.qualifications[i].localPartners[j].statusVendor)
		},
		everyLocalPartnerCandidate(vendor) {
			return vendor.localPartners?.every(e => e.statusVendor === CANDIDATE)
		},
		statusLocalPartnerNotPraqualification(local) {
			return local.statusVendor !== CANDIDATE
		},
		lengthLocalPartnerNotPraqualification(n) {
			return n.localPartners?.filter(e => e.statusVendor !== CANDIDATE).length-1
		},
		viewAttachment(filename, documentNo) {
			this.$store.dispatch('file/viewAttachment', { filename, documentNo })
		},
		closeModal() {
			this.readMoreShow = false
		},
		readMore(rejectNotes) {
			this.rejectNotesModal = rejectNotes
			this.readMoreShow = true
		},
	},
	components: { 
		ProposalRevision,
		ProposalApprove,
		ModalReadMore,
	},

}

</script>
