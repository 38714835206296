<template>
  <div class="w-full">
    <div class="flex items-start w-full my-6 flex-wrap gap-2.5">
      <h4 class="flex-auto ml-2 text-lg font-medium text-gray-darkest">Procurement Approval</h4>
      <div class="flex-none flex flex-wrap gap-6" :class="projectDetailContainerWidth < 640 ? 'w-full mt-3' : ''">
        <ButtonGista v-if="menuRfpApprovalSecurityCode?.canRead && !openSecurityCode && allApproved && !someInProgress" size="small" type="secondary" color="primary-darkest" class="text-sm font-semibold" :class="projectDetailContainerWidth < 640 ? 'w-full' : ''" @click="clickShowSecurityCode">
          <template #icon-left>
            <span class="icon-key text-2xl pr-3.5"></span>
          </template>
          Show Security Code
        </ButtonGista>
        <ButtonGista v-if="openSecurityCode && !someInProgress" size="small" type="secondary" color="gray" :disabled="true" class="font-semibold" :class="projectDetailContainerWidth < 640 ? 'w-full' : ''">
          {{ securityCode }}
        </ButtonGista>
        <ButtonGista @click="clickPreview" size="small" type="secondary" color="primary-darkest" class="text-sm font-semibold" :class="projectDetailContainerWidth < 640 ? 'w-full' : ''">
          <template #icon-left>
            <span class="icon-eye text-2xl pr-3.5"></span>
          </template>
          Preview
        </ButtonGista>
      </div>
    </div>
    <TableApprovalProcurement @goToUploadSignApproval="goToUploadSignApproval" />
    <div class="relative flex flex-wrap items-center w-full gap-6 my-6" >
      <button @click="expandCollapseAll" class="flex justify-end flex-1 underline cursor-pointer text-tertiary hover:text-tertiary-dark" >{{messageCollapseAll}}</button>
    </div>
    <TemplateFormProcurementApproval :collapse="collapse" @changeCollapse="changeCollapse" :showRecommendation="false" :readOnly="true" />
    <UploadSignedApproval v-if="someReviewManagement" @changeCollapse="changeCollapse('uploadSigned')" :collapse="collapse" ref="uploadSignApproval" />
  </div>
</template>

<script>
import TableApprovalProcurement from '@/core/components/rfp/procurementApproval/TableApprovalProcurement.vue'
import UploadSignedApproval from '@/core/components/rfp/procurementApproval/UploadSignedApproval'
import TemplateFormProcurementApproval from '@/core/components/rfp/procurementApproval/TemplateFormProcurementApproval.vue'
import { APPROVER, PROCUREMENT_APPROVED } from '@/core/constant/procurementApproval.js'

export default {
  name: "ProcurementApprovalDetails",
  data() {
    return {
      messageCollapseAll: 'Collapse All',
      collapse: {
        information: true,
        negoResults: true,
        vendorCandidateScoring: true,
        projectApproval: true,
        costBudget: true,
        recommendation: true,
        checker: true,
        approver: true,
        attachments: true,
        uploadSigned: true
      },
      readOnly: true,
      securityCode: '',
      openSecurityCode: false,
    }
  },
  computed:{
    projectDetailContainerWidth() {
      return this.$store.state.width.projectDetailContainerWidth
    },
    dataProcurementApproval: {
      get() {
        return this.$store.state.procurementApproval.dataProcurementApproval
      },
      set(value) {
        this.$store.commit('procurementApproval/SET_DATA_PROCUREMENT_APPROVAL', value)
      }
    },
    menuRfpApprovalSecurityCode() {
			return this.$store.getters['dashboard/menuRfpApprovalSecurityCode']
		},
    logApproval() {
      return this.$store.state.procurementApproval.logApproval
    },
    allApproved() {
      return this.logApproval?.logDetails?.every(e => e.approvalStatus === PROCUREMENT_APPROVED)
    },
    projectLogRfp() {
      return this.$store.state.projectLog.projectLogRfp
		},
    someInProgress() {
			return this.projectLogRfp?.rfpLogs.some(e => e.isInProgress || e.additionalLogs?.some(el => el.isInProgress))
		},
    approver() {
			return this.logApproval?.logDetails?.filter(e => e.approvalType === APPROVER)
		},
    someReviewManagement() {
      return this.approver?.some(e => e.requiredUploadSignedApproval)
    },
    modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    },
  },
  methods:{
    //function collapse/extend
    changeCollapse(key) {
      this.collapse[key] = !this.collapse[key]
      if (Object.values(this.collapse).every(el => el)) this.messageCollapseAll = 'Collapse All'
      else if (Object.values(this.collapse).every(el => !el)) this.messageCollapseAll = 'Extend All'
    },
    expandCollapseAll() {
      for (const key in this.collapse) {
        if (this.messageCollapseAll === 'Extend All') {
          this.collapse[key] = true
        } else {
          this.collapse[key] = false
        }
      }
      if (this.messageCollapseAll === 'Collapse All') this.messageCollapseAll = 'Extend All'
      else this.messageCollapseAll = 'Collapse All'
    },
    async clickPreview() {
      this.modal.modalLoading = true
      await this.$store.dispatch('procurementApproval/postApprovalPreview', { isSubmit: true })
      this.modal.modalLoading = false
    },
    async clickShowSecurityCode() {
      const res = await this.$store.dispatch('procurementApproval/getApprovalSecurityCode', { approvalId: this.dataProcurementApproval.id })
      if (res?.status < 300) {
        this.securityCode = res.data.result
        this.openSecurityCode = true
        setTimeout(() => {
          this.securityCode = ''
          this.openSecurityCode = false
        }, 10000)
      }
    },
    goToUploadSignApproval() {
      let element = this.$refs.uploadSignApproval;
      let top = element.$el.offsetTop;
      document.getElementById('scrollArea').scroll({
          top: top,
          behavior: 'smooth'
        })
    }
  },
  async mounted() {
    const res = await this.$store.dispatch('procurementApproval/getApproval', { rfpId: this.$route.params.projectId })
    if (res?.status === 200) {
      if (this.dataProcurementApproval.id > 0) {
        this.$store.dispatch('procurementApproval/getApprovalLog', { approvalId: this.dataProcurementApproval.id })
      }
    }
  },
  components:{
    TableApprovalProcurement,
    UploadSignedApproval,
    TemplateFormProcurementApproval
  }
}
</script>

