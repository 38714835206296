import { render, staticRenderFns } from "./ScoringDecision.vue?vue&type=template&id=6d506da6&scoped=true"
import script from "./ScoringDecision.vue?vue&type=script&lang=js"
export * from "./ScoringDecision.vue?vue&type=script&lang=js"
import style0 from "./ScoringDecision.vue?vue&type=style&index=0&id=6d506da6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d506da6",
  null
  
)

export default component.exports