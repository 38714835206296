<template>
	<div class="p-0 mt-4 sm:mt-0">
		<ButtonGista @click="changeIsOpen(true)"
			color="success"
			:disabled="proposalInformation?.isCanceled"
			customClass="sm:w-44 w-full">
			Send Proposal
		</ButtonGista>

		<ModalConfirm 
			title="Kirim Proposal Ke Technical Coordinator" 
			message="Apakah Anda yakin?" 
			icon="saved" 
			textButton="Send Proposals" 
			:isOpen="isOpen" 
			@changeIsOpen="changeIsOpen($event)" 
			@click="clickSendProposal"
			colorButton="success">
		</ModalConfirm>
		
	</div>
</template>
<script>
import ModalConfirm from '@/core/components/modal/Confirm'
import { MESSAGE_SUCCESS_SEND_PROPOSAL } from '@/core/constant/successMessage'

export default {
	name: 'ProposalSend',
	data() {
		return {
			isOpen: false,
		}
	},
	computed: {
		qualifications() {
			return this.$store.state.qualification.qualifications
		},
		modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    },
		proposalInformation() {
      return this.$store.state.projectDetail.proposalInformation
    },
	},
	methods: {
		changeIsOpen(val) {
			this.isOpen = val
		},
		async clickSendProposal() {
			try {
				this.modal.modalLoading = true
				const projectId = this.proposalInformation.projectId
				const response = await this.$store.dispatch('qualification/postProposalSend', projectId)
				if (response?.status < 300 && response?.status >= 200) {
					this.isOpen = false
					this.modal.modalLoading = false
					//show modal success send proposal
					this.modal.modalSuccessSubmitted = { value: true, title: MESSAGE_SUCCESS_SEND_PROPOSAL }
					this.$store.dispatch('qualification/getQualifications', this.$route.params.projectId)
					this.$store.dispatch('projectLog/refetchProjectLogByRfpId', this.$route.params.projectId)
				} else {
					this.$store.dispatch('modal/throwError', { response })
				}
			} catch (error) {
				console.log(error);
				//show modal error send proposal
				this.modal.modalLoading = false
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
			}
		},
		

	},
	components: {
		ModalConfirm
	}
}

</script>