<template>
  <div>
    <div class="flex mt-6">
      <div class="w-full">
        <div class="animate-pulse">
          <div class="flex justify-center">
            <div class="h-12 bg-gray-light rounded max-w-40 mb-3.5"></div>
          </div>
          <Card v-for="n in 2" :key="n" class="flex border border-gray-lightest mt-6">
            <div class="w-full">
              <div class="animate-pulse">
                <div class="grid grid-cols-2 gap-4">
                  <div class="grid grid-cols-1 gap-4 border-r border-gray-lightest">
                    <div class="h-4 bg-gray-light rounded w-40"></div>
                    <div class="h-4 bg-gray-light rounded w-40"></div>
                  </div>
                  <div class="grid grid-cols-1 gap-4">
                    <div class="h-4 bg-gray-light rounded w-40"></div>
                    <div class="h-4 bg-gray-light rounded w-40"></div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from '@/core/components/custom/Card'

export default {
    name: "NegotiationLoading",
    components: {Card}
}
</script>
<style scoped>
.max-w-40 {
  max-width: 20rem;
  width: 20rem;
}
</style>