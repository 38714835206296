<template>
	<div>
		<div v-if="!isLoading">
			<div class="flex justify-between my-6">
				<div>
					<span class="text-2xl font-bold cursor-pointer icon-arrow-left text-gray"
						@click="backToVendorList"></span>
				</div>
				<div>
						<h4 class="text-lg font-medium text-gray-darkest">{{negotiationDetails?.isLocalPartner? 'Local Partner': 'Vendor'}} #{{ negotiationDetails.vendorCandidateSequence }} Details</h4>
				</div>
				<div></div>
			</div>
			<div class="flex flex-wrap w-full mb-6" :class="projectDetailContainerWidth < 640 ? 'flex-col' : 'flex-row'">
				<div class="border border-gray-lightest p-2.5 rounded-xl items-start mr-2.5 mb-4">
					<label class="text-sm text-gray">Project ID</label>
					<h5 class="font-semibold text-gray-dark">{{ negotiationDetails.projectId }}</h5>
				</div>
				<div class="border border-gray-lightest p-2.5 rounded-xl items-start mr-2.5 mb-4">
					<label class="text-sm text-gray">No. RFP</label>
					<h5 class="font-semibold text-gray-dark">{{ negotiationDetails.noRfp }}</h5>
				</div>
				<div class="flex-1 border border-gray-lightest p-2.5 rounded-xl items-start mb-4">
					<label class="text-sm text-gray">Project Name</label>
					<h5 class="font-semibold text-gray-dark">{{ negotiationDetails.projectName }}</h5>
				</div>
			</div>
			<div v-if="negotiationDetails?.isLocalPartner">
				<div class="w-full mb-1">
					<label class="text-sm text-tertiary">Vendor #{{ negotiationDetails.principalVendorSequence }}</label>
					<label v-if="negotiationDetails.isNewPrincipalVendor" class="text-xs text-success bg-success-lightest rounded py-1 px-2 ml-3.5">New Vendor</label>
				</div>
				<h5 class="text-base text-gray-darkest">{{ negotiationDetails.principalVendor }}</h5>
			</div>
			<div class="flex flex-wrap justify-between gap-6 pb-6 border-b border-gray-lightest" :class="projectDetailContainerWidth < 640 ? 'flex-col' : 'flex-row'">
				<div class="pr-4">
					<div :class="{'border-l border-gray-light pl-4 mt-3.5 py-2': negotiationDetails?.isLocalPartner}">
						<div class="w-full mb-1">
							<label class="text-sm text-tertiary">{{negotiationDetails?.isLocalPartner?'Local Partner':'Vendor'}} #{{ negotiationDetails.vendorCandidateSequence }}</label>
							<label v-if="negotiationDetails.isNewVendor" class="text-xs text-success bg-success-lightest rounded py-1 px-2 ml-3.5">New Vendor</label>
						</div>
						<h5 class="text-base text-gray-darkest">{{ negotiationDetails.vendorName }}</h5>
					</div>
				</div>
				<div class="flex flex-wrap gap-6" :class="projectDetailContainerWidth < 640 ? 'flex-col' : 'flex-row'">
					<div class="border-gray-lightest" :class="projectDetailContainerWidth < 640 ? '' : 'pl-6 border-l'">
						<label class="text-sm text-gray">Negotiation status</label>
						<div class="w-max mt-2.5 flex items-center rounded-md py-2 px-2.5" :class="negotiationStatusBg(negotiationDetails.negotiationStatus)">
							<span class="mr-3 text-2xl font-bold icon-tag" :class="negotiationStatusText(negotiationDetails.negotiationStatus)"></span>
							<h5 class="text-base" :class="negotiationStatusText(negotiationDetails.negotiationStatus)">{{ negotiationDetails.negotiationStatus}}</h5>
						</div>
					</div>
					<div v-if="negotiationDetails?.logApprovalStatus" class="border-gray-lightest" :class="projectDetailContainerWidth < 640 ? '' : 'pl-6 border-l'">
						<label class="text-sm text-gray">{{negotiationDetails?.logApprovalTitle}}</label>
						<div class="w-max mt-2.5 flex items-center rounded-md py-2 px-2.5" :class="logApprovalStatusBg(negotiationDetails?.logApprovalStatus)">
							<span class="mr-3 text-2xl font-bold" :class="logApprovalStatusIcon(negotiationDetails?.logApprovalStatus)"></span>
							<h5 class="text-base" :class="logApprovalStatusText(negotiationDetails?.logApprovalStatus)">{{ negotiationDetails?.logApprovalStatus}}</h5>
						</div>
					</div>
				</div>
			</div>
			<div class="w-full">
				<div class="flex flex-wrap items-center justify-between w-full mt-3 mb-6 title" :class="projectDetailContainerWidth < 640 ? 'flex-col' : 'flex-row'">
					<div class="flex items-center pt-3 pr-4" :class="projectDetailContainerWidth < 640 ? 'w-full justify-start' : ''">
						<p class="text-2xl font-bold icon-list text-gray"></p>
						<h4 class="pl-3 text-lg font-medium">Negotiation details</h4>
					</div>
					<div class="pt-3" :class="projectDetailContainerWidth < 640 ? 'w-full' : ''" >
						<div class="flex items-center" :class="projectDetailContainerWidth < 640 ? 'flex-col' : 'flex-row'" >
							<div class="flex items-center cursor-pointer" :class="projectDetailContainerWidth < 640 ? 'w-full' : ''"  v-if="menuRfpNegotiationUploadOffer?.canCreate">
								<button-gista
									@click="clickUploadOffer" 
									size="small"
									v-if="!sppProcess"
									:customClass="projectDetailContainerWidth < 640 ? 'w-full mb-3' : ''"
									:disabled="!negotiationDetails.isEnableUploadOffer || negotiationDetails?.isCanceled"
									>
									<template #icon-left>
										<span class="mr-3 text-2xl icon-file-plus"></span>
									</template>
									Upload Offer
								</button-gista>
							</div>
							<button class="flex items-center" :class="[disableMaximizeTable? 'cursor-not-allowed': 'cursor-pointer hover:text-gray-dark active:text-gray-darkest', projectDetailContainerWidth < 640 ? '' : 'pl-4']" @click="clickToggleMaximizeTable" :disabled="disableMaximizeTable">
								<span class="mr-3 text-lg icon-maximize text-gray"></span>
								<p class="text-sm font-semibold text-gray" >Maximize table</p>
							</button>
						</div>
						<UploadOffer v-if="showUploadOffer"/>
						<MaximizeTable v-if="isOpenMaximizeTable" :isOpen="isOpenMaximizeTable" @clickToggle="clickToggleMaximizeTable"/>
						<EditOffer v-if="showEditOffer !== false"/>
					</div>
				</div>
				<NegotiationTable @clickEditBan="clickEditBan" @clickToggle="clickToggleIsOpenContinueDraftBan" @clickRevise="clickRevise($event)" @reviseDraft="setContinueDraftData" />
	
				<div class="flex flex-wrap items-center justify-between w-full gap-6 mt-6" v-if="!sppProcess">
					<div class="flex flex-wrap " :class="projectDetailContainerWidth > 640 ? 'w-max mr-6' : 'w-full'">
						<NegotiationScheduled @click="clickNegotiationScheduled" v-if="menuRfpNegotiationScheduleConfirm?.canUpdate && negotiationDetails.negotiationStatus !== negotiationStatus.FINAL_DEAL"/>
						<div v-if="negotiationDetails?.isEnableScheduleReminderNotes" class="flex py-4">
							<p class="mr-2 text-lg font-medium icon-info text-warning"></p>
							<p class="text-sm font-medium text-warning">it's been 7 days, are negotiations scheduled?</p>
						</div>
					</div>
					<div class="flex flex-wrap gap-4" :class="projectDetailContainerWidth < 640 ? 'flex-col w-full' : ''">
						<ContinueDraft 
							v-if="menuRfpNegotiationOfferRevision?.canCreate && negotiationDetails.isEnableContinueDraftBanReviseOffer && negotiationDetails.negotiationStatus !== negotiationStatus.FINAL_DEAL" 
							:isOpen="isOpenContinueDraftReviseOffer" 
							:disabled="!negotiationDetails.isEnableReviseOffer || negotiationDetails?.isCanceled" 
							:lastDate="negotiationDetails.lastBanReviseOfferModifiedDate" 
							color="error"
							@clickToggle="clickToggleIsOpenContinueDraftReviseOffer($event)"
							@click="clickRevise"/>
						<ButtonGista @click="clickRevise"
							v-if="menuRfpNegotiationOfferRevision?.canCreate && !negotiationDetails.isEnableContinueDraftBanReviseOffer && negotiationDetails.negotiationStatus !== negotiationStatus.FINAL_DEAL"
							type="primary"
							color="error"
							:disabled="!negotiationDetails.isEnableReviseOffer || negotiationDetails?.isCanceled"
							customClass="">
							Revise Offer
						</ButtonGista>
						<ContinueDraft 
							v-if="menuRfpNegotiationBan?.canCreate && negotiationDetails.isEnableContinueDraftBanDeal && negotiationDetails.negotiationStatus !== negotiationStatus.FINAL_DEAL" 
							:isOpen="isOpenContinueDraftBan" 
							:disabled="!negotiationDetails.isEnableDeal || negotiationDetails?.isCanceled" 
							:lastDate="negotiationDetails.lastBanDealModifiedDate" 
							color="success"
							@clickToggle="clickToggleIsOpenContinueDraftBan($event)"
							@click="clickDealBan"/>
						<ButtonGista 
							v-if="menuRfpNegotiationBan?.canCreate && !negotiationDetails.isEnableContinueDraftBanDeal && negotiationDetails.negotiationStatus !== negotiationStatus.FINAL_DEAL"
							color="success"
							customClass=""
							:disabled="!negotiationDetails.isEnableDeal || negotiationDetails?.isCanceled"
							@click="clickDealBan">
							Deal
						</ButtonGista>
						<ReNegotiation 
							v-if="menuRfpNegotiationRenegotiation?.canUpdate && negotiationDetails?.isEnableRenegotiation" 
							@click="clickReNegotiation" 
							@clickToggle="clickToggleIsOpenReNegotiation($event)" 
							:isOpen="isOpenReNegotiation" 
							:disabled="negotiationDetails?.isCanceled"/>
						<ButtonGista
							v-if="menuRfpNegotiationRenegotiation?.canUpdate && negotiationDetails?.isEnableRenegotiation && !negotiationDetails.isEnableContinueDraftBanDeal"
							color="secondary"
							:disabled="negotiationDetails?.isCanceled"
							@click="clickEditBan">
							Continue
						</ButtonGista>
						<ContinueDraft 
							v-if="menuRfpNegotiationRenegotiation?.canUpdate && negotiationDetails?.isEnableRenegotiation && negotiationDetails.isEnableContinueDraftBanDeal" 
							:isOpen="isOpenContinueDraftBan" 
							:disabled="!negotiationDetails.isEnableContinueDraftBanDeal || negotiationDetails?.isCanceled" 
							:lastDate="negotiationDetails.lastBanDealModifiedDate" 
							color="success"
							@clickToggle="clickToggleIsOpenContinueDraftBan($event)"
							@click="clickEditBan"/>
					</div>
				</div>
			</div>
		</div>
		<NegotiationDetailLoading v-else/>
		<ModalConfirm 
			title="Lanjutkan Draft?" 
			:message="`Draft terakhir: ${ continueDraftData }`" 
			icon="saved" textButton="Continue Draft" 
			:isOpen="!!continueDraftData" 
			@changeIsOpen="setContinueDraftData($event)" 
			@click="clickRevise"
			colorButton="success">
		</ModalConfirm>
	</div>
</template>

<script>
import NegotiationTable from '@/core/components/rfp/negotiation/NegotiationTable.vue';
import NegotiationScheduled from '@/core/components/rfp/negotiation/NegotiationScheduled.vue';
import MaximizeTable from '@/core/components/rfp/negotiation/MaximizeTable.vue';
import UploadOffer from '@/core/components/rfp/negotiation/UploadOffer.vue';
import EditOffer from '@/core/components/rfp/negotiation/EditOffer.vue';
import ContinueDraft from '@/core/components/modal/negotiation/ContinueDraft.vue';
import ReNegotiation from '@/core/components/modal/negotiation/ReNegotiation.vue';
import NegotiationDetailLoading from '@/core/components/rfp/loading/NegotiationDetailLoading.vue';
import ModalConfirm from '@/core/components/modal/Confirm'

export default {
	name: "VendorDetail",
	data() {
		return {
			isOpenContinueDraftReviseOffer: false,
			isOpenContinueDraftBan: false,
			isOpenReNegotiation: false,
			isOpenMaximizeTable: false,
			negotiationStatus: {
				NO_INITIAL_OFFER: 'No Initial Offer',
				INITIAL_OFFER: 'Initial Offer',
				NEGOTIATION1: 'Negotiation1',
				NEGOTIATION2: 'Negotiation2',
				FINAL_DEAL: 'Final Deal',
			},
			isLoading: true,
			continueDraftData: ''
		}
	},
	computed: {
		projectDetailContainerWidth() {
			return this.$store.state.width.projectDetailContainerWidth
		},
		vendorDetail: {
			get() {
				return this.$store.state.negotiation.vendorDetail
			},
			set(value) {
				this.$store.commit('negotiation/SET_VENDOR_DETAIL', value)
			}
    	},
		showUploadOffer() {
			return this.$store.state.negotiation.showUploadOffer
		},
		showEditOffer() {
			return this.$store.state.negotiation.showEditOffer
		},
		negotiationDetails() {
			return this.$store.state.negotiation.negotiationDetails
		},
		menuRfpNegotiationDetails() {
			return this.$store.getters['dashboard/menuRfpNegotiationDetails']
		},
		menuRfpNegotiationUploadOffer() {
			return this.$store.getters['dashboard/menuRfpNegotiationUploadOffer']
		},
		menuRfpNegotiationScheduleConfirm() {
			return this.$store.getters['dashboard/menuRfpNegotiationScheduleConfirm']
		},
		menuRfpNegotiationOfferRevision() {
			return this.$store.getters['dashboard/menuRfpNegotiationOfferRevision']
		},
		menuRfpNegotiationBan() {
			return this.$store.getters['dashboard/menuRfpNegotiationBan']
		},
		menuRfpNegotiationRenegotiation() {
			return this.$store.getters['dashboard/menuRfpNegotiationRenegotiation']
		},
		disableMaximizeTable() {
			return this.negotiationDetails?.offers?.length === 0
		},
		counterInterval() {
			return this.$store.state.projectLog.counterInterval
		},
		sppProcess() {
			return this.$store.state.spp.sppProcess
		},
		modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    },
	},
	methods: {
		clickRevise() {
			setTimeout(() => {
				this.$store.commit('negotiation/SET_SHOW_REVISE_OFFER', true)
			}, 100)
		},
		clickUploadOffer() {
			this.$store.commit('negotiation/SET_SHOW_UPLOAD_OFFER', true)
		},
		clickDealBan() {
			setTimeout(() => {
				this.$store.commit('negotiation/SET_SHOW_DEAL_BAN', true)
			}, 100)
		},
		clickEditBan() {
			setTimeout(() => {
				this.$store.commit('negotiation/SET_SHOW_DEAL_BAN', true)
				this.$store.commit('negotiation/SET_SHOW_EDIT_BAN', true)
			}, 100)
		},
		clickToggleIsOpenContinueDraftReviseOffer(val) {
			this.isOpenContinueDraftReviseOffer = val
		},
		clickToggleIsOpenContinueDraftBan(val) {
			this.isOpenContinueDraftBan = val
		},
		clickToggleMaximizeTable() {
			this.isOpenMaximizeTable = !this.isOpenMaximizeTable
		},
		clickToggleIsOpenReNegotiation(val) {
			this.isOpenReNegotiation = val
		},
		setContinueDraftData(val) {
			this.continueDraftData = val
		},
		negotiationStatusBg(negStatus) {
			if (negStatus?.toLowerCase()?.includes('negotiation')) {
				return 'bg-secondary-lightest'
			}
			switch (negStatus) {
				case this.negotiationStatus.NO_INITIAL_OFFER:
          return 'bg-gray-lightest'
        case this.negotiationStatus.INITIAL_OFFER:
          return 'bg-warning-lightest'
				case this.negotiationStatus.FINAL_DEAL:
					return 'bg-success-lightest'
				default: 
					return 'bg-gray-lightest'
			}
		},
		negotiationStatusText(negStatus) {
			if (negStatus?.toLowerCase()?.includes('negotiation')) {
				return 'text-secondary-darkest'
			}
			switch (negStatus) {
				case this.negotiationStatus.NO_INITIAL_OFFER:
          return 'text-gray-darkest'
        case this.negotiationStatus.INITIAL_OFFER:
          return 'text-warning-darkest'
				case this.negotiationStatus.FINAL_DEAL:
					return 'text-success-darkest'
				default: 
					return 'text-gray-darkest'
			}
		},
		logApprovalStatusBg(status) {
			const statusLower = status?.toLowerCase()
			if (statusLower.includes('waiting')) {
				return 'bg-warning-lightest'
			}
			if (statusLower.includes('rejected')) {
				return 'bg-error-lightest'
			}
			if (statusLower.includes('approved')) {
				return 'bg-success-lightest'
			}
			return 'bg-gray-lightest'
		},
		logApprovalStatusText(status) {
			const statusLower = status?.toLowerCase()
			if (statusLower.includes('waiting')) {
				return 'text-warning-darkest'
			}
			if (statusLower.includes('rejected')) {
				return 'text-error-darkest'
			}
			if (statusLower.includes('approved')) {
				return 'text-success-darkest'
			}
			return 'text-gray-darkest'
		},
		logApprovalStatusIcon(status) {
			const statusLower = status?.toLowerCase()
			if (statusLower.includes('waiting')) {
				return 'icon-clock text-warning-darkest'
			}
			if (statusLower.includes('rejected')) {
				return 'icon-x text-error-darkest'
			}
			if (statusLower.includes('approved')) {
				return 'icon-check text-success-darkest'
			}
			return ''
		},
		backToVendorList() {
			this.vendorDetail = null
			this.$router.push({ name: 'projectNegotiation' }).catch(() => ({}))
		},
		clickNegotiationScheduled() {
			setTimeout(() => {
				this.$store.dispatch('negotiation/getNegotiationDetails', { vendorCandidateId: this.vendorDetail })
			}, 1000)
		},
		async clickReNegotiation() {
			try {
				this.modal.modalLoading = true
				const response = await this.$store.dispatch('negotiation/putReNegotiation', { vendorCandidateId: this.negotiationDetails.vendorCandidateId })
				if (response?.status < 300 && response?.status >= 200) {
					this.modal.modalLoading = false
					this.$store.dispatch('negotiation/getNegotiationDetails', { vendorCandidateId: this.negotiationDetails.vendorCandidateId })
				} else {
					this.$store.dispatch('modal/throwError', { response })
				}
			} catch (error) {
				console.log(error);
        this.modal.modalLoading = false
        this.modal.modalError = { value: true, traceid: error.traceid, title: error.title, message: error.message }
			}
		},
		
	},
	async mounted() {
		this.isLoading = true
		const response = await this.$store.dispatch('negotiation/getNegotiationDetails', { vendorCandidateId: this.vendorDetail })
		if (response?.status === 200) {
			this.isLoading = false
		}
		this.$store.commit('negotiation/SET_SHOW_VENDOR_DETAILS', true)
	},
	destroyed() {
		this.$store.commit('negotiation/SET_SHOW_VENDOR_DETAILS', false)
		clearInterval(this.counterInterval)
		this.$store.commit('projectLog/SET_COUNTER_INTERVAL', null)
	},
	components: {
    NegotiationTable,
    NegotiationScheduled,
    MaximizeTable,
    UploadOffer,
    EditOffer,
    ContinueDraft,
    ReNegotiation,
    NegotiationDetailLoading,
	ModalConfirm
}
}
</script>