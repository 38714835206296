import { render, staticRenderFns } from "./NegotiationDetailLoading.vue?vue&type=template&id=08e7e3f4&scoped=true"
import script from "./NegotiationDetailLoading.vue?vue&type=script&lang=js"
export * from "./NegotiationDetailLoading.vue?vue&type=script&lang=js"
import style0 from "./NegotiationDetailLoading.vue?vue&type=style&index=0&id=08e7e3f4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08e7e3f4",
  null
  
)

export default component.exports