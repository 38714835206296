<template>
  <div>
    <div v-if="!isLoading">
      <div class="flex justify-between my-6">
        <div>
          <span class="text-2xl font-bold cursor-pointer icon-arrow-left text-gray"
            @click="clickBackToVendorDetail"></span>
        </div>
        <div>
            <h4 class="text-lg font-medium text-gray-darkest">BAN - {{reviseOffer?.negotiationStatus}}</h4>
        </div>
        <div></div>
      </div>
      <div>
        <div class="flex justify-between">
          <div class="mb-6">
            <div class="w-full mb-1">
              <label class="text-sm text-tertiary">{{negotiationDetails?.isLocalPartner? 'Local Partner' : 'Vendor'}} #{{negotiationDetails.vendorCandidateSequence}}</label>
              <label v-if="negotiationDetails.isNewVendor" class="text-xs text-success bg-success-lightest rounded py-1 px-2 ml-3.5">New Vendor</label>
            </div>
            <h5 class="text-base text-gray-darkest">{{reviseOffer.vendorName}}</h5>
          </div>
          <div v-if="menuRfpNegotiationBanReport?.canRead">
            <ButtonGista size="small" type="secondary" color="success" @click="clickViewBan">
              <template #icon-left>
                <span class="icon-eye text-lg pr-3.5"></span>
              </template>
              View BAN
            </ButtonGista>
          </div>
        </div>
        <div class="flex items-center mb-6">
          <span class="mr-4 icon-list"></span>
          <h5 class="text-lg font-medium text-gray-darkest">Revisi Penawaran Harga</h5>
        </div>
        <div class="grid gap-x-6 gap-y-3.5 mb-3.5 pb-4 border-b border-gray-lightest"
          :class="{
            'grid-cols-1': projectDetailContainerWidth < 768,
            'grid-cols-2': projectDetailContainerWidth >= 768
          }">
          <InputDisabled label="Project ID" :value="reviseOffer.projectId"></InputDisabled>
          <InputDisabled label="Request No." :value="reviseOffer.requestNo"></InputDisabled>
          <InputDisabled label="Vendor Name" :value="reviseOffer.vendorName"></InputDisabled>
          <InputDisabled label="Project Name" :value="reviseOffer.projectName"></InputDisabled>
          <InputDisabled label="Request By" :value="reviseOffer.requestByName?.toLowerCase()" class="capitalize"></InputDisabled>
          <InputDisabled label="Created By" :value="reviseOffer.createdBy?.toLowerCase()" class="capitalize"></InputDisabled>
          <InputDisabled v-if="workflowGsit || workflowGsitUkkp" label="PIC GSIT" :value="reviseOffer.picGsitName?.toLowerCase()" class="capitalize"></InputDisabled>
          <InputDisabled v-if="workflowUkkp || workflowGsitUkkp" label="PIC UKKP" :value="reviseOffer.picUkkpName?.toLowerCase()" class="capitalize"></InputDisabled>
          <InputDisabled label="Offer No" :value="reviseOffer.offerNo"></InputDisabled>
          <InputDisabled label="Offer Date" :value="reviseOffer.offerDate"></InputDisabled>
          <InputDisabled label="Date Created" :value="reviseOffer.createdDate"></InputDisabled>
          </div>
        <div class="mb-3">
          <div class="mb-1">
            <p class="text-sm font-medium text-gray">Project Type</p>
          </div>
          <div class="flex flex-wrap mt-3">
            <div class="flex items-center mb-3">
              <span v-if="reviseOffer.projectType?.includes('Hardware')" class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
              <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
              <p class="ml-2 mr-6 font-medium text-gray">Hardware</p>
            </div>
            <div class="flex items-center mb-3">
              <span v-if="reviseOffer.projectType?.includes('Software')" class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
              <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
              <p class="ml-2 mr-6 font-medium text-gray">Software</p>
            </div>
            <div class="flex items-center mb-3">
              <span v-if="reviseOffer.projectType?.includes('Service')" class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
              <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
              <p class="ml-2 mr-6 font-medium text-gray">Service</p>
            </div>
            <div class="flex items-center mb-3">
              <span v-if="reviseOffer.projectType?.includes('Maintenance')"  class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
              <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
              <p class="ml-2 mr-6 font-medium text-gray">Maintenance</p>
            </div>
            <div class="flex items-center mb-3">
              <span v-if="reviseOffer.projectType?.includes('Link-Communication')" class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
              <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
              <p class="ml-2 mr-6 font-medium text-gray">Link-Communication</p>
            </div>
          </div>
        </div>
        <div class="grid gap-x-6 gap-y-3.5 mb-3.5"
          :class="{
            'grid-cols-1': projectDetailContainerWidth < 768,
            'grid-cols-2': projectDetailContainerWidth >= 768
          }">
          <div>
            <div class="flex">
              <label class="block mb-1 text-sm font-medium text-gray">Meeting</label>
            </div>
            <GroupRadioButton class="h-11"
              :options="['Online', 'Offline']"
              disabled
              name="meeting"
              :selected="reviseOffer.meeting" />
          </div>
          <InputDisabled label="Date" :value="reviseOffer.meetingDate"></InputDisabled>
          <InputDisabled label="Time (Start)" :value="reviseOffer.timeStart"></InputDisabled>
          <InputDisabled label="Time (End)" :value="reviseOffer.timeEnd"></InputDisabled>
          <InputDisabled v-if="!meetingOnline" label="Location" :value="reviseOffer.location || '-'"></InputDisabled>
          <InputDisabled v-if="meetingOnline" label="Via Online" :value="reviseOffer.viaOnline || '-'"></InputDisabled>
          <InputDisabled label="Negotiator 1" class="capitalize" :value="reviseOffer.firstNegotiatorName?.toLowerCase() || '-'"></InputDisabled>
          <InputDisabled label="Negotiator 2" class="capitalize" :value="reviseOffer.secondNegotiatorName?.toLowerCase() || '-'"></InputDisabled>
        </div>
        <div class="grid gap-y-3.5 mb-6"
          :class="{
            'grid-cols-1': projectDetailContainerWidth < 768,
            'grid-cols-2': projectDetailContainerWidth >= 768
          }">
          <div class="col-span-2">
            <div class="mb-1">
              <p class="text-sm text-gray">Negotiation Participant</p>
            </div>
            <div class="flex flex-wrap col-span-2">
              <div v-for="(n,i) in reviseOffer.negotiationParticipant" :key="i" class="bg-gray-lightest rounded-lg px-4 py-3 mr-3.5 mb-3.5 flex items-center">
                <div class="text-base font-medium capitalize text-gray">{{ n.fullName?.toLowerCase() }}</div>
              </div> 
            </div>
          </div>
          <div class="col-span-2">
            <div class="flex items-center">
              <label class="block mb-1 text-sm text-gray">
                Term of Payment
              </label>
            </div>
            <div class="">
              <TiptapView v-if="reviseOffer.termOfPayment" v-model="reviseOffer.termOfPayment" />
              <InputDisabled value="-" v-else/>
            </div>
          </div>
          <div class="col-span-2">
            <div class="flex items-center">
              <label class="block mb-1 text-sm text-gray">
                Negotiation Note/ BCA Request
              </label>
            </div>
            <div class="">
              <TiptapView v-if="reviseOffer.negotiationNote" v-model="reviseOffer.negotiationNote" />
              <InputDisabled value="-" v-else/>
            </div>
          </div>
          <div class="col-span-2">
            <div class="flex items-center">
              <label class="block mb-1 text-sm text-gray">
                Note Internal BCA
              </label>
            </div>
            <div class="">
              <TiptapView v-if="reviseOffer.internalNote" v-model="reviseOffer.internalNote" />
              <InputDisabled value="-" v-else/>
            </div>
          </div>
          <div class="col-span-2">
            <div class="mb-1">
              <p class="mb-1 text-sm text-gray">Proof Of Negotiation Meeting</p>
            </div>
            <div class="flex items-center justify-between w-full border rounded-lg border-primary min-h-file">
              <div class="px-4 py-2">
                <p class="text-base font-medium text-gray">{{ reviseOffer.proofOfMeetingFileName }}</p>
              </div>
              <div v-if="reviseOffer.proofOfMeetingFileName" class="flex items-center justify-center w-14">
                <p class="text-xl cursor-pointer icon-eye text-primary hover:text-primary-darkest" @click="openFile(reviseOffer.proofOfMeetingFileName, reviseOffer.proofOfMeetingDocumentNo)"></p>
              </div>
            </div>
          </div>
          <div class="col-span-2">
            <div class="mb-1">
              <p class="mb-1 text-sm text-gray">File Quotation</p>
            </div>
            <div class="flex items-center justify-between w-full border rounded-lg border-primary h-14 min-h-file">
              <div class="px-4 py-2">
                <p class="text-base font-medium text-gray">{{ findFileQuotiation?.fileName }}</p>
              </div>
              <div class="flex items-center px-4">
                <p class="text-xl cursor-pointer icon-eye text-primary hover:text-primary-darkest" @click="openFile(findFileQuotiation?.fileName, findFileQuotiation?.documentNo)"></p>
              </div>
            </div>
          </div>
          <div v-for="n,i in reviseOffer?.attachments" :key="i" class="col-span-2">
            <div class="mb-1">
              <p class="mb-1 text-sm text-gray">Attachment #{{ i+1 }}</p>
            </div>
            <div class="flex items-center justify-between w-full border rounded-lg border-primary h-14 min-h-file">
              <div class="px-4 py-2">
                <p class="text-base font-medium text-gray">{{ n?.fileName }}</p>
              </div>
              <div class="flex items-center px-4">
                <p class="text-xl cursor-pointer icon-eye text-primary hover:text-primary-darkest" @click="openFile(n?.fileName, n?.documentNo)"></p>
              </div>
            </div>
          </div>
          <div :class="projectDetailContainerWidth < 640? 'col-span-2': 'col-span-1'">
            <div class="mb-1">
              <p class="text-sm font-medium text-gray">Cost Type</p>
            </div>
            <div class="flex h-12">
              <radio-gista disabled :modelValue="negotiationOfferGet.costType === 'FIX_COST'? 'FIX_COST': ''" nativeValue="FIX_COST" class="pr-4 text-gray">Fixed</radio-gista>
              <radio-gista disabled :modelValue="negotiationOfferGet.costType === 'RATE_COST'? 'RATE_COST': ''" nativeValue="RATE_COST" class="pr-4 text-gray">Rate</radio-gista>
              <radio-gista disabled :modelValue="negotiationOfferGet.costType === 'MIX_COST'? 'MIX_COST': ''" nativeValue="MIX_COST" class="text-gray">Mixed</radio-gista>
            </div>
          </div>
          <div :class="projectDetailContainerWidth < 640? 'col-span-2': 'col-span-1'">
            <InputDisabled label="VAT" :value="taxText"></InputDisabled>
          </div>
        </div>
        <div class="mb-6">
          <div class="flex justify-between mb-6">
            <div class="flex items-center">
              <span class="mr-4 icon-list"></span>
              <h5 class="text-lg font-medium text-gray-darkest">Negotiation Detail</h5>
            </div>
            <div class="flex items-center cursor-pointer" @click="clickToggleMaximizeTable">
              <span class="mr-3 text-lg icon-maximize text-gray hover:text-gray-dark"></span>
              <p class="text-sm font-semibold text-gray hover:text-gray-dark" >Maximize table</p>
            </div>
          </div>
          <MaximizeTableDetail v-if="menuRfpNegotiationOfferGet?.canRead && isOpenMaximizeTable" :isOpen="isOpenMaximizeTable" @clickToggle="clickToggleMaximizeTable" />
          <NegotiationTableDetail v-if="menuRfpNegotiationOfferGet?.canRead"/>
        </div>
      </div>
    </div>
    <BanLoading v-else/>
  </div>
</template>

<script>
import NegotiationTableDetail from "@/core/components/rfp/negotiation/NegotiationTableDetail.vue";
import MaximizeTableDetail from "@/core/components/rfp/negotiation/MaximizeTableDetail.vue";
import TiptapView from '@/core/components/custom/TiptapView.vue'
import GroupRadioButton from '@/core/components/custom/GroupRadioButton.vue'
import InputDisabled from '@/core/components/custom/InputDisabled.vue'
import { WF_RFP_GSIT, WF_RFP_GSIT_UKKP, WF_RFP_UKKP } from '@/core/constant/negotiation.js'
import BanLoading from '@/core/components/rfp/loading/BanLoading.vue'

export default {
  name: 'DetailOffer',
  data() {
    return {
      onlineText: 'Online',
      isOpenMaximizeTable: false,
      isLoading: false,
    }
  },
  computed: {
    reviseOffer: {
      get() {
        return this.$store.state.negotiation.reviseOffer
      },
      set(value) {
        this.$store.commit('negotiation/SET_REVISE_OFFER', value)
      }
    },
    menuRfpNegotiationOfferGet() {
			return this.$store.getters['dashboard/menuRfpNegotiationOfferGet']
    },
    menuRfpNegotiationBanReport() {
			return this.$store.getters['dashboard/menuRfpNegotiationBanReport']
    },
    negotiationOfferGet() {
      return this.$store.state.negotiation.negotiationOfferGet
    },
    findFileQuotiation() {
      return this.negotiationOfferGet?.attachments?.find(e => e.fileRef === 'fileQuotation')
    },
    negotiationDetails() {
			return this.$store.state.negotiation.negotiationDetails
		},
    projectDetailContainerWidth() {
      return this.$store.state.width.projectDetailContainerWidth
    },
    taxText() {
      return this.negotiationOfferGet?.tax?.ppn === 0 ? this.negotiationOfferGet?.tax?.taxType : `${this.negotiationOfferGet?.tax?.taxType} - ${this.negotiationOfferGet?.tax?.ppn}%`  
    },
    meetingOnline() {
      return this.reviseOffer.meeting === this.onlineText
    },
    workflowGsit() {
      return this.reviseOffer?.workflow === WF_RFP_GSIT
    },
    workflowGsitUkkp() {
      return this.reviseOffer?.workflow === WF_RFP_GSIT_UKKP
    },
    workflowUkkp() {
      return this.reviseOffer?.workflow === WF_RFP_UKKP
    },
    modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    },
  },
  methods: {
    clickBackToVendorDetail() {
      this.$router.go(-1)
    },
    openFile(filename, documentNo) {
      this.$store.dispatch('file/viewAttachment', { filename, documentNo })
    },
    async clickViewBan() {
      try {
        this.modal.modalLoading = true
        const response = await this.$store.dispatch('negotiation/getBanReport', { negotiationId: this.reviseOffer.negotiationId })
        this.modal.modalLoading = false
        if (response?.status >= 300) {
          this.$store.dispatch('modal/throwError', { response })
        }
      } catch (error) {
        this.modal.modalLoading = false
        this.modal.modalError = { value: true, traceid: error.traceid, title: error.title, message: error.message }
      }
    },
    clickToggleMaximizeTable() {
			this.isOpenMaximizeTable = !this.isOpenMaximizeTable
		},
  },
  async mounted() {
    this.isLoading = true
    const res1 = await this.$store.dispatch('negotiation/getNegotiationOfferRevision', { negotiationId: this.$route.query.negotiation })
		const r2 = this.$store.dispatch('negotiation/getNegotiationDetails', { vendorCandidateId: this.reviseOffer.vendorCandidateId })
		const r3 = this.$store.dispatch('negotiation/getNegotiationOffer', { negotiationId: this.reviseOffer.negotiationId })
    const [res2, res3] = await Promise.all([r2, r3])
    if ([res1?.status, res2?.status, res3?.status].every(e => e === 200)) {
      this.isLoading = false
    }
  },
  destroyed() {
    this.$store.commit('negotiation/SET_SHOW_DETAIL_OFFER', false)
  },
  components: {
    GroupRadioButton,
    NegotiationTableDetail,
    MaximizeTableDetail,
    TiptapView,
    InputDisabled,
    BanLoading
}
}
</script>

<style scoped>
.min-h-file {
  min-height: 3.5rem;
}
</style>