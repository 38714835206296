<template>
  <div>
    <div class="flex items-center justify-between mb-3 cursor-pointer" @click="changeCollapse('information')">
      <div class="flex items-center">
        <span class="mr-4 icon-list"></span>
        <h5 class="text-lg font-medium text-gray-darkest">INFORMATION &nbsp;</h5>
        <span class="text-error" v-if="(!isSubmitted || isRejected) && !readOnly && !readOnly">*</span>
      </div>
      <div>
        <p class="text-2xl transition ease-in transform icon-chevron-down text-gray" :class="collapse.information ? 'rotate-180' : 'rotate-0'"></p>
      </div>
    </div>
    <Information v-show="collapse.information" :readOnly="readOnly"/>
    <div class="w-full pt-6 mt-6 border-t-2 border-gray-lightest">
      <div class="flex items-center justify-between mb-3 cursor-pointer" @click="changeCollapse('negoResults')">
        <div class="flex items-center">
          <span class="mr-4 icon-list"></span>
          <h5 class="text-lg font-medium text-gray-darkest">NEGOTIATION RESULTS &nbsp;</h5>
          <span v-if="(!isSubmitted || isRejected) && !readOnly" class="text-error">*</span>
        </div>
        <div>
          <p class="text-2xl transition ease-in transform icon-chevron-down text-gray" :class="collapse.negoResults ? 'rotate-180' : 'rotate-0'"></p>
        </div>
      </div>
    </div>
    <NegotiationResult v-show="collapse.negoResults" :readOnly="readOnly" />
    <div class="w-full pt-6 mt-6 border-t-2 border-gray-lightest">
      <div class="flex items-center justify-between mb-3 cursor-pointer" @click="changeCollapse('vendorCandidateScoring')" >
        <div class="flex items-center">
          <span class="mr-4 icon-list"></span>
          <h5 class="text-lg font-medium text-gray-darkest">VENDOR CANDIDATES & SCORING &nbsp;</h5>
          <span v-if="(!isSubmitted || isRejected) && !readOnly" class="text-error">*</span>
        </div>
        <div>
          <p class="text-2xl transition ease-in transform icon-chevron-down text-gray" :class="collapse.vendorCandidateScoring ? 'rotate-180' : 'rotate-0'"></p>
        </div>
      </div>
      <div v-show="collapse.vendorCandidateScoring">
        <VendorCandidateScoringTable :readOnly="readOnly"/>
        <div class="col-span-2 py-3.5">
            <TextareaGista label="Reason for Vendor Selection"
              placeholder="Leave a comment here"
              v-model="dataProcurementApproval.vendorSelectionReason"
              @input="(e) => characterCount(e.length, 'Reason for Vendor Selection', 1000)"
              :mandatory="!isSubmitted"
              :disabled="isSubmitted"
              :error="!!errorMaxLength['Reason for Vendor Selection']">
              <template #message>
                <p>{{ errorMaxLength['Reason for Vendor Selection'] }}</p>
              </template>
            </TextareaGista>
        </div>
      </div>
    </div>
    <div class="w-full pt-6 mt-6 border-t-2 border-gray-lightest">
      <div class="flex items-center justify-between mb-3 cursor-pointer" @click="changeCollapse('projectApproval')">
        <div class="flex items-center">
          <span class="mr-4 icon-list"></span>
          <h5 class="text-lg font-medium text-gray-darkest">PROJECT PROCUREMENT APPROVAL</h5>
        </div>
        <div>
          <p class="text-2xl transition ease-in transform icon-chevron-down text-gray" :class="collapse.projectApproval ? 'rotate-180' : 'rotate-0'"></p>
        </div>
      </div>
    </div>
    <ProjectProcurementApprovalTable v-show="collapse.projectApproval" />
    <div class="w-full pt-6 my-6 border-t-2 border-gray-lightest">
      <div class="flex items-center justify-between mb-3 cursor-pointer" @click="changeCollapse('costBudget')">
        <div class="flex items-center">
          <span class="mr-4 icon-list"></span>
          <h5 class="text-lg font-medium text-gray-darkest">COST & BUDGET INFORMATION &nbsp;</h5>
          <span v-if="(!isSubmitted || isRejected) && !readOnly" class="text-error">*</span>
        </div>
        <div>
          <p class="text-2xl transition ease-in transform icon-chevron-down text-gray" :class="collapse.costBudget ? 'rotate-180' : 'rotate-0'"></p>
        </div>
      </div>
      <CostBudgetInformation v-show="collapse.costBudget" :readOnly="readOnly"/>
    </div>
    <div class="w-full pt-6 my-6 border-t-2 border-gray-lightest">
      <div class="flex mb-3.5 cursor-pointer"
        @click="changeCollapse('attachments')">
        <div class="flex flex-auto items-center">
          <span class="mr-4 icon-list"></span>
          <h4 class="text-lg font-medium text-gray-darkest">ATTACHMENTS</h4>
        </div>
        <div class="flex-none">
          <p :class="collapse.attachments? 'rotate-180': 'rotate-0'"
            class="icon-chevron-down text-gray text-2xl transform transition ease-in"></p>
        </div>
      </div>
    </div>
    <Attachment v-show="collapse.attachments" :readOnly="readOnly"></Attachment>
    <Recommendation @changeCollapse="changeCollapse" :collapse="collapse" v-if="showRecommendation" />
  </div>
</template>

<script>

import Information from '@/core/components/rfp/procurementApproval/Information.vue'
import NegotiationResult from '@/core/components/rfp/procurementApproval/NegotiationResult.vue'
import VendorCandidateScoringTable from '@/core/components/rfp/procurementApproval/VendorCandidateScoringTable.vue'
import ProjectProcurementApprovalTable from '@/core/components/rfp/procurementApproval/ProjectProcurementApprovalTable.vue'
import CostBudgetInformation from '@/core/components/rfp/procurementApproval/CostBudgetInformation.vue'
import Attachment from '@/core/components/rfp/procurementApproval/Attachment.vue'
import Recommendation from '@/core/components/rfp/procurementApproval/Recommendation.vue'
import { MESSAGE_EMPTY_FIELD} from '@/core/constant/errorMessage.js'

export default {
  name: "TemplateFormProcurementApproval",
  props: ['collapse', 'showRecommendation', 'readOnly'],
  data() {
    return {
      messageCollapseAll: 'Collapse All',
    }
  },
  computed:{
    projectDetailContainerWidth() {
      return this.$store.state.width.projectDetailContainerWidth
    },
    modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    },
    errorMaxLength: {
      get() {
        return this.$store.state.procurementApproval.errorMaxLength
      },
      set(val) {
        this.$store.commit('procurementApproval/SET_ERROR_MAX_LENGTH', val)
      }
    },
    dataProcurementApproval: {
      get() {
        return this.$store.state.procurementApproval.dataProcurementApproval
      },
      set(value) {
        this.$store.commit('procurementApproval/SET_DATA_PROCUREMENT_APPROVAL', value)
      }
    },
    isSubmitted() {
      return this.dataProcurementApproval?.isSubmitted
    },
    logApproval() {
      return this.$store.state.procurementApproval.logApproval
    },
    isRejected() {
      return this.dataProcurementApproval?.isRejected
    }
  },
  methods:{
    //function collapse/extend
    changeCollapse(key) {
      this.$emit('changeCollapse', key)
    },
    expandCollapseAll() {
      for (const key in this.collapse) {
        if (this.messageCollapseAll === 'Extend All') {
          this.collapse[key] = true
        } else {
          this.collapse[key] = false
        }
      }
      if (this.messageCollapseAll === 'Collapse All') this.messageCollapseAll = 'Extend All'
      else this.messageCollapseAll = 'Collapse All'
    },
    characterCount(e, name, MAX_CHARACTER) {
      if (e > MAX_CHARACTER) this.errorMaxLength[name] = `Kolom ${name} Maksimal ${MAX_CHARACTER} Karakter`
      else if (e === 0) this.errorMaxLength[name] = MESSAGE_EMPTY_FIELD
      else this.errorMaxLength[name] = ''
    },
  },
  async mounted() {
    const res = await this.$store.dispatch('procurementApproval/getApproval', { rfpId: this.$route.params.projectId })
    if (res?.status === 200) {
      if (this.dataProcurementApproval.id > 0) {
        this.$store.dispatch('procurementApproval/getApprovalLog', { approvalId: this.dataProcurementApproval.id })
      }
    }
  },
  components:{
    Information,
    NegotiationResult,
    VendorCandidateScoringTable,
    Recommendation,
    ProjectProcurementApprovalTable,
    CostBudgetInformation,
    Attachment,
  }
}
</script>

