<template>
  <div class="my-6">
    <div class="flex justify-between mb-6" :class="projectDetailContainerWidth < 640 ? 'flex-col' : 'items-center flex-row'">
      <h1 class="text-xl font-medium">SPP</h1>
    </div>
    <ApprovalTable/>
    <Information/>
    <CardSpp v-if="!collapse.spp"/>
    <div class="w-full pt-6 my-6 border-t-2 border-gray-lightest">
      <div class="flex mb-3.5 cursor-pointer"
        @click="changeCollapse('attachments')">
        <div class="flex items-center flex-auto">
          <span class="mr-4 icon-list"></span>
          <h4 class="text-lg font-medium text-gray-darkest">ATTACHMENTS</h4>
        </div>
        <div class="flex-none">
          <p :class="!collapse.attachments? 'rotate-180': 'rotate-0'"
            class="text-2xl transition ease-in transform icon-chevron-down text-gray"></p>
        </div>
      </div>
    </div>
    <Attachment v-if="!collapse.attachments" :readOnly="true"></Attachment>
  </div>
</template>

<script>
import ApprovalTable from '@/core/components/rfp/spp/ApprovalTable.vue'
import Attachment from '@/core/components/rfp/spp/Attachment.vue'
import Information from '@/core/components/rfp/spp/Information.vue'
import CardSpp from '@/core/components/rfp/spp/CardSpp.vue'

export default {
  name: 'SppDetails',
  data() {
    return {
      collapse: {
        spp: false,
        attachments: false,
      },
      spv1and2: true
    }
  },
  computed: {
    projectDetailContainerWidth() {
      return this.$store.state.width.projectDetailContainerWidth
    },
    dataSpp: {
      get() {
        return this.$store.state.spp.dataSpp
      },
      set(value) {
        this.$store.commit('spp/SET_DATA_SPP', value)
      }
    },
  },
  methods: {
    changeCollapse(key) {
      this.collapse[key] = !this.collapse[key] 
    }
  },
  components: {
    ApprovalTable,
    Attachment,
    Information,
    CardSpp,
  }
}
</script>