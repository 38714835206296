<template>
	<div>
		<ButtonGista
			@click="toggle"
			type="tertiary"
			color="error"
			:disabled="proposalInformation?.isCanceled"
			:customClass="projectDetailContainerWidth > 768 ? 'underline w-40' : 'underline w-full'">
			Eliminate
		</ButtonGista>

		<GistaModal :value="isOpen"
			:hideClose="false"
			width="2/3"
			@input="toggle"
			customClass="bg-white top-0 sm:top-auto bottom-0 sm:bottom-auto mt-28 mb-8 max-w-7xl">
			<div class="px-6 py-6">
				<ValidationObserver v-slot="{ handleSubmit }">
					<div class="flex justify-center mb-6">
						<h4 class="text-base font-semibold text-gray-dark">Eliminate</h4>
					</div>
					<div class="border border-gray-lightest px-6 py-3.5 rounded-xl mb-6 w-full">
						<div class="w-full pr-2.5">
							<div class="flex flex-wrap mb-1">
								<h6 class="text-secondary-blue">Vendor #{{  index + 1  }}</h6>
								<div v-if="qualification.isNewVendor" class="flex px-6 py-1 ml-10 text-xs rounded-lg bg-success-lightest text-success">
									<h6>New Vendor</h6>
								</div>
							</div>
							<h5 class="text-sm break-words">{{  qualification.vendorName  }}</h5>
							<p class="break-words text-gray-dark">{{  qualification.vendorEmail  }}</p>
						</div>
					</div>
					<div class="w-full mt-1 mb-6">
						<div>
							<label class="block mb-1 text-sm text-gray">Dokumen Pendukung:</label>
						</div>
						<div class="flex items-center justify-between w-full border rounded-lg h-14"
							:class="`${errorFileType ? 'border-error' : 'border-primary'}`">
							<input type="file"
								id="upload-file-eliminate"
								ref="file1"
								hidden
								@change="chooseFile($event)" />
							<div class="flex justify-between" style="width: calc(100% - 7rem);">
								<span class="px-4 text-sm truncate text-gray" :title="files?.name">{{ files?.name }}</span>
								<span v-if="files?.name" class="pr-2 cursor-pointer icon-trash-2 text-error hover:text-error-dark" @click="removeFile"></span>
							</div>
							<label for="upload-file-eliminate"
								class="flex items-center justify-center p-2 text-white rounded-r-lg cursor-pointer bg-primary hover:bg-primary-dark w-28 h-14">
								Pilih File
							</label>
						</div>
						<div>
							<p class="mt-1 text-xs text-tertiary"
								:class="{ 'text-error': errorFileType }">
								Supported file: .pdf, max file size: 10 MB
							</p>
						</div>
					</div>
					<div class="w-full mb-6">
						<ValidationProvider name="reason"
							rules="required|max:300"
							v-slot="{ errors }">
							<TextareaGista label="Reason"
								v-model="reason"
								:mandatory="true"
								:error="!!errors[0]">
								<template #message>
									<p>{{ errors[0] }}</p>
								</template>
							</TextareaGista>
						</ValidationProvider>
					</div>
	
					<div class="flex justify-end">
	
						<ButtonGista @click="handleSubmit(clickEliminate)"
							class="w-full sm:w-60"
							color="error">
							Eliminate</ButtonGista>
	
					</div>
				</ValidationObserver>

			</div>
		</GistaModal>
		
	</div>
</template>
<script>
import { MESSAGE_SUCCESS_ELIMINATE } from '@/core/constant/successMessage'

export default {
	name: 'EliminateDocuments',
	props: ['qualification', 'index', 'rfpId', 'praQualification'],
	data() {
		return {
			isOpen: false,
			files: {},
			reason: '',
			errorFileType: false
		}
	},
	computed: {
		//menu details - action submit
		menuQualification() {
			return this.$store.getters['dashboard/menuQualification']
		},
		proposalInformation() {
			return this.$store.state.projectDetail.proposalInformation
		},
		modal: {
			get() {
				return this.$store.state.modal.modal
			},
			set(value) {
				this.$store.commit('modal/SET_MODAL', value)
			}
    	},
		projectDetailContainerWidth() {
			return this.$store.state.width.projectDetailContainerWidth
		},
	},
	methods: {
		toggle() {
			this.files = {}
			this.reason = ''
			this.errorFileType = false
			this.isOpen = !this.isOpen
		},
		async clickEliminate() {
			try {
				//show loading
				this.modal.modalLoading = true
				this.isOpen = !this.isOpen
				const file = this.files
				//hash file checksum md5
				const formData = new FormData()
				const rfpId = this.rfpId
				formData.append('rfpId', rfpId)
				formData.append('vendorCandidateId', this.qualification.vendorCandidateId)
				formData.append('reason', this.reason)
				formData.append('type', this.praQualification ? '' : 'qualification')

				if (this.files.name) {
					const encrypted = await this.$store.dispatch('file/hashMD5',file)
					formData.append('file', this.files)
					//checksum encrypted md5
					formData.append('checksum', encrypted)
				}
				const response = await this.$store.dispatch('qualification/putQualificationEliminateVendor', formData)
				if (response?.status < 300 && response?.status >= 200) {
					//loading out
					this.modal.modalLoading = false
					//show modal success eliminate
					this.modal.modalSuccessSaved = {value: true, title: MESSAGE_SUCCESS_ELIMINATE }
					this.$store.dispatch('qualification/getQualifications', this.$route.params.projectId)
					this.$store.dispatch('projectLog/refetchProjectLogByRfpId', this.$route.params.projectId)
					//reset state
					this.reason = ''
					this.files = {}
				} else {
					this.$store.dispatch('modal/throwError', { response })
				}
				
			} catch (error) {
				//loading out when error
				this.modal.modalLoading = false
				//show modal error eliminate
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
			}

		},
		async chooseFile(e) {
			console.log(e.target.files[0]);
			const file = e.target.files[0]
			const checkFileValidation = await this.$store.dispatch('file/checkFileValidation', { file, fileType: ['application/pdf'] })
			if(checkFileValidation) {
				if(!this.files?.name) this.errorFileType = true
			} else {
				this.files = file
				this.errorFileType = false
			}
			if (this.$refs['file1']) {
				this.$refs['file1'].value = ''
			}
		},
		removeFile() {
			if (this.$refs['file1']) {
				this.$refs['file1'].value = ''
			}
			this.files = {}
		},

	},
}

</script>