<template>
	<div class="overflow-auto">
    <div class="relative w-full overflow-auto border rounded-lg min-w-table-mobile max-h-custom border-primary border-spacing-custom">
      <div class="w-full min-width-custom">
        <table class="w-full h-px border-collapse" aria-describedby="projectProcurementApproval">
          <thead class="z-20 h-10 py-1 bg-white">
            <tr class="h-12 border-b text-gray-darkest border-primary">
              <th scope="col" class="border-r border-primary min-w-64 ">
                <h5 class="flex items-center h-10 ml-2 text-sm font-semibold text-left">Selected Vendor</h5>
              </th>
              <th scope="col" class="pl-2 border-r border-primary min-w-64">
                <h5 class="flex items-center h-10 text-sm font-semibold text-left">Waiver BG</h5>
              </th>
              <th scope="col" class="pl-2 min-w-64">
                <h5 class="flex items-center h-10 text-sm font-semibold text-left">Grand Total in IDR</h5>
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="item, i in data">
              <tr class="h-12 border-b border-primary" v-if="item.localPartnerList === null" :key="i">
                <td class="pl-2 pr-3 border-r border-primary">
                  <div class="py-2 min-w-60">
                    <h5 class="text-sm font-medium text-tertiary ">Vendor #{{ item.vendorSeq }}</h5>
                    <h5 class="text-sm font-medium text-gray-darkest">{{ item.vendorName }}</h5>
                  </div>
                </td>
                <td class="pl-2 pr-3 border-r border-primary ">
                  <h5 class="text-sm text-gray-darkest">{{ item.waiverBg }}</h5>
                </td>
                <td class="pl-2 pr-3">
                  <h5 class="text-sm font-bold text-gray-darkest">{{ formatCurrency(item.procurementApprovalDetails.grandTotalIdr, 'idr', null, 0) }}</h5>
                </td>
              </tr>
              <tr class="h-12 border-b border-primary" v-else v-for="local, j in item.localPartnerList" :key="`${i}${j}`" >
                <td class="pl-2 pr-3 border-r border-primary">
                  <div class="py-2 min-w-60">
                    <div class="flex flex-wrap gap-x-1">
                      <h5 class="text-sm font-medium truncate text-tertiary">Local Partner #{{ local.vendorSeq }}</h5>
                      <h5 class="text-sm font-medium text-gray">of {{ item.vendorName }}</h5>
                    </div>
                    <h5 class="text-sm font-medium text-gray-darkest">{{ local.vendorName }}</h5>
                  </div>
                </td>
                <td class="pl-2 pr-3 border-r border-primary ">
                  <h5 class="text-sm text-gray-darkest">{{ local.waiverBg }}</h5>
                </td>
                <td class="pl-2 pr-3">
                  <h5 class="text-sm font-bold text-gray-darkest">{{ formatCurrency(local.procurementApprovalDetails.grandTotalIdr, 'idr', null, 0) }}</h5>
                </td>
              </tr>
            </template>
          </tbody>
          <tbody>
            <tr class="h-12">
              <td colspan="2" class="pl-2 pr-3 border-r border-primary">
                <div class="py-2 min-w-60">
                  <h5 class="text-sm font-semibold text-gray-darkest">Total Nominal Project in IDR</h5>
                </div>
              </td>
              <td class="pl-2 pr-3 py-2.5">
                <h5 class="text-2xl font-bold text-gray-darkest">{{ formatCurrency(selectedCandidate?.totalNominalProject, 'idr', null, 0) }}</h5>
              </td>
            </tr>
          </tbody>
        </table>
			</div>
		</div>
	</div>
</template>

<script>
import { formatCurrency, parseLocaleNumber } from '@/core/components/helpers/formatRupiah'
import { TERPILIH } from '@/core/constant/procurementApproval.js'
export default {
	name: 'ProjectProcurementApprovalTable',
  computed: {
    selectedCandidate() {
      return this.$store.state.procurementApproval.selectedCandidate
    },
    dataProcurementApproval: {
      get() {
        return this.$store.state.procurementApproval.dataProcurementApproval
      },
      set(value) {
        this.$store.commit('procurementApproval/SET_DATA_PROCUREMENT_APPROVAL', value)
      }
    },
    data() {
      return this.selectedCandidate?.procurementApprovalVendor?.filter(e => {
          const index = this.dataProcurementApproval.candidateScoringList.findIndex(fe => fe.candidateId === e.candidateId)
          return index >= 0 && (this.dataProcurementApproval.candidateScoringList[index].candidateScoringDetails.status === TERPILIH || e.localPartnerList !== null)
        }).map(e => {
          return {
            ...e,
            localPartnerList: e.localPartnerList === null? e.localPartnerList : e.localPartnerList.filter(el => {
              const index = this.dataProcurementApproval.candidateScoringList.findIndex(fe => fe.candidateId === e.candidateId)
              const indexJ = this.dataProcurementApproval.candidateScoringList[index].localPartnerList.findIndex(fe => fe.candidateId === el.candidateId)
              return indexJ >= 0 && this.dataProcurementApproval.candidateScoringList[index].localPartnerList[indexJ].candidateScoringDetails.status === TERPILIH
            })
          }
        })
      },
    },
  methods: {
    formatCurrency,
    parseLocaleNumber
	}
}
</script>
<style scoped>
.border-spacing-custom {
  border-spacing: 0px 0px;
}
.min-width-custom {
	min-width: 40rem;
}
.min-w-60 {
	min-width: 15rem;
}
.min-w-40 {
  min-width: 10rem;
}
.min-w-table-mobile {
  min-width: 25rem;
}
</style>
