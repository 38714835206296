<template>
  <div>
    <div class="col-span-2 mb-4" v-if="!readOnly">
      <p class="mb-1 text-sm text-gray" :class="(errorCancellationReq || dataSpp?.error?.['Cancellation Request'])? 'text-error' : 'text-gray'">Cancellation Request <span class="text-sm text-error">*</span></p>
      <div class="flex items-center justify-between w-full border rounded-lg h-14"
        :class="`${errorCancellationReq || dataSpp?.error?.['Cancellation Request'] ? 'border-error' : 'border-primary'}`">
        <input type="file"
          id="upload-file-cancellationRequest"
          :ref="cancellationRequestRef"
          hidden
          @change="addAttachment($event, 'cancellationRequest')" />
        <div class="flex justify-between" style="width: calc(100% - 7rem);">
          <span class="px-4 text-sm text-gray" :class="projectDetailContainerWidth < 640 ? '' : 'truncate'">{{ cancellationRequest[0]?.fileName }}</span>
          <span v-if="cancellationRequest[0]?.fileName" class="flex items-center pr-4 text-xl cursor-pointer icon-trash-2 text-error hover:text-error-dark" @click="() => clickRemoveFileAttachment()"></span>
        </div>
        <label for="upload-file-cancellationRequest"
          class="flex items-center justify-center p-2 text-white rounded-r-lg cursor-pointer bg-primary hover:bg-primary-dark w-28 h-14">
          Pilih File
        </label>
      </div>
      <div>
        <p class="mt-1 text-xs text-tertiary"
          :class="{ 'text-error': errorCancellationReq || dataSpp?.error?.['Cancellation Request'] }">
          Supported file: .pdf, max file size: 10 MB
        </p>
      </div>
    </div>
    <div class="mb-3.5" v-else>
      <div>
        <p class="mb-1 text-sm font-medium text-gray">Cancellation Request</p>
      </div>
      <div class="flex items-center justify-between p-2 border rounded-lg border-primary h-14">
        <div class="text-base text-gray">{{ cancellationRequest[0]?.fileName }}</div>
        <span class="text-2xl cursor-pointer icon-eye text-primary hover:text-primary-dark" @click="() => openFile()" ></span>
      </div>
    </div>
    <div class="mb-3.5" v-for="n,i in attachments?.filter(e => (!e.isDeleted))" :key="i">
      <div>
        <p class="mb-1 text-sm font-medium text-gray">Attachment #{{i+1}}</p>
      </div>
      <div class="flex items-center justify-between p-2 border rounded-lg border-primary h-14">
        <div class="text-base text-gray">{{ n?.fileName }}</div>
        <span v-if="!readOnly" class="cursor-pointer icon-trash-2 text-error" @click="clickRemoveFileAttachment(n)"></span>
        <span v-else class="text-2xl cursor-pointer icon-eye text-primary hover:text-primary-dark" @click="openFile(n)" ></span>
      </div>
    </div>
    <div class="mb-6" v-if="!readOnly">
      <ValidationProvider
        ref="lampiranSpp"
        >
        <div class="flex flex-wrap items-center">
          <div class="flex" v-if="maxAttachment">
            <span class="mr-2 icon-file-plus text-secondary"></span>
            <input type="file"
              id="upload-file-attachment"
              :ref="sppRef"
              hidden
              @change="addAttachment($event, sppRef)" />
            <label for="upload-file-attachment"
              class="mr-4 text-base font-medium underline cursor-pointer text-secondary">
              Add Attachment
            </label>
          </div>
          <p class="mr-3.5" :class="{ 'text-error': errorFileType, 'text-gray': !errorFileType }">Max Attachment: 10 </p>
          <p :class="{ 'text-error': errorFileType, 'text-gray': !errorFileType }">Supported file: .pdf, max file size: 10 MB</p>
        </div>
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
export default {
  name: "Attachments",
  props: ['readOnly'],
  data() {
    return {
      sppRef: 'spp',
      cancellationRequestRef: 'cancellationRequest',
			errorFileType: false,
      errorCancellationReq: false,
      loadingCancellationRequest: false
    }
  },
  computed: {
    attachments() {
      const attachments = this.dataSpp?.attachments ?? []
      return attachments?.filter(e => e.fileRef === 'spp' && !e.isDeleted)
    },
    cancellationRequest() {
      const attachments = this.dataSpp?.attachments ?? []
      return attachments?.filter(e => e.fileRef === 'cancellationRequest' && !e.isDeleted)
    },
    maxAttachment() {
      return this.attachments?.filter(e => !e.isDeleted).length < 10 
    },
    dataSpp: {
      get() {
        return this.$store.state.spp.dataSpp
      },
      set(value) {
        this.$store.commit('spp/SET_DATA_SPP', value)
      }
    },
    isSubmitted() {
      return this.dataSpp.statusDocument !== 'SPP_DRAFT' && this.dataSpp.statusDocument !== null
    },
    modal: {
			get() {
				return this.$store.state.modal.modal
			},
			set(value) {
				this.$store.commit('modal/SET_MODAL', value)
			}
		},
    projectDetailContainerWidth() {
      return this.$store.state.width.projectDetailContainerWidth
    },
  },
  methods: {
    setErrorFileType(checkFileValidation, fileRef) {
      if (checkFileValidation) {
        if(fileRef !== this.cancellationRequestRef) this.errorFileType = true
      } else {
        if(fileRef === this.cancellationRequestRef) { 
          this.errorCancellationReq = false; 
          this.loadingCancellationRequest = true 
        } else this.errorFileType = false
      }
    },
    async addAttachment(e, fileRef) {
      const file = e.target?.files[0]
			const checkFileValidation = await this.$store.dispatch('file/checkFileValidation', { file, fileType: ['application/pdf'] })
      if(checkFileValidation){
        this.setErrorFileType(checkFileValidation, fileRef)
      } else {
				try {
					this.modal.modalLoading = true
          this.setErrorFileType(checkFileValidation, fileRef)
					const encrypted = await this.$store.dispatch('file/hashMD5',file)
					const formData = new FormData()
					formData.append('file', file)
					formData.append('fileRef', fileRef)
					//checksum encrypted md5
					formData.append('checksum', encrypted)
					formData.append('projectType', 'RFP')
					const response = await this.$store.dispatch('file/uploadFile', formData)
					if (response?.status < 300 && response?.status >= 200) {
						const data = response.data.result
						const fileAfterUpload = {
							fileName: data.fileName,
							filePath: data.filePath,
							documentNo: data.documentNo,
							fileRef: fileRef,
							isDeleted: false,
							attachmentId: null
						}
            if(fileRef === this.cancellationRequestRef) {
              this.dataSpp.error['Cancellation Request'] = false
              if(this.cancellationRequest.length > 0) this.clickRemoveFileAttachment()
            }
					  this.dataSpp.attachments = [ ...this.dataSpp.attachments, fileAfterUpload ]
          }
					this.modal.modalLoading = false
        } catch (error) {
					this.modal.modalLoading = false
					this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
        }
      }
      if (this.$refs[fileRef]) {
        this.$refs[fileRef].value = ''
      }
    },
    openFile(n) {
      const data = n ?? this.cancellationRequest[0]
			const { fileName, documentNo } = data
      this.$store.dispatch('file/viewAttachment', { filename: fileName, documentNo })
    },
    async clickRemoveFileAttachment(data){
      const n = data ?? this.cancellationRequest[0]
      this.modal.modalLoading = true
      if (n.attachmentId !== null) {
        this.dataSpp.attachments = this.dataSpp.attachments?.map(e => (e.filePath === n.filePath? ({ ...e, isDeleted: true }): e))
        this.modal.modalLoading = false
      } else {
        const response = await this.$store.dispatch('file/deleteFile', n.documentNo || n.fileName)
        if (response?.status < 300) {
          this.dataSpp.attachments = this.dataSpp.attachments?.filter(e => e.filePath !== n.filePath)
        }
        this.modal.modalLoading = false
      }
    },
  }
}
</script>