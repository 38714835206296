<template>
  <div>
    <ButtonGista 
      type="primary" 
      color="success" 
      @click="changeIsOpen(true)" 
      customClass="w-full sm:w-max"
      :disabled="!isEnableSubmitScoringPrice || proposalInformation?.isCanceled || disabled"
      >Submit Scoring Price
    </ButtonGista>
    <ModalConfirm 
      title="Apakah anda yakin?" 
      :message="`Pastikan semua penilaian sudah sesuai,\nInformasi tidak dapat diubah setelah dikirim`" 
      textButton="Submit Scoring Price" 
      :isOpen="isOpenModalSubmitScoringPrice" 
      @changeIsOpen="changeIsOpen($event)" 
      :isBackButton="true"
      @click="clickSubmitScoringPrice"
      colorButton="success">
    </ModalConfirm>
  </div>
</template>

<script>
import ModalConfirm from '@/core/components/modal/Confirm'

export default {
  name: 'SubmitScoringPrice',
  props: ['isOpenModalSubmitScoringPrice', 'isEnableSubmitScoringPrice', 'disabled'],
  computed: {
    proposalInformation() {
			return this.$store.state.projectDetail.proposalInformation
		},
  },
  methods: {
    changeIsOpen(val) {
      this.$emit('clickToggle', val)
    },
    clickSubmitScoringPrice() {
      this.$emit('click')
    }
  },
  components: {
    ModalConfirm
  }
}
</script>