<template>
	<div>
		<div v-if="!isLoadingDocumentVendor" class="grid w-full grid-cols-1 gap-6 p-6 lg:grid-cols-2">
			<div v-for="(n,i) in vendorDocumentsDetail?.documents" :key="i">
				<Card v-if="n.filePath" class="p-4 border border-gray-lightest shadow-secondary-s">
					<div class="flex items-center justify-between w-full mb-4">
						<h4 class="text-base font-medium text-center">{{n.documentName}}</h4>
						<div class="flex">
							<div
								v-if="n.statusFile === 'Expired'"
								class="flex items-center px-2 py-1 ml-10 text-xs border rounded-lg bg-warning-lightest text-warning-dark border-warning-dark">
								<span class="pr-2 text-lg icon-x text-warning"></span>
								<h5>{{n.statusFile}}</h5>
							</div>
							<div
								v-if="n.statusFile !== 'Expired'"
								class="flex items-center px-2 py-1 ml-10 text-xs border rounded-lg bg-success-lightest text-success border-success-light">
								<span class="pr-2 text-lg icon-check text-success"></span>
								<h5>{{n.statusFile}}</h5>
							</div>
						</div>
					</div>
					<InputGista type=text
						disabled
						size="small"
						:value="n.fileName">
						<template #icon-right>
							<p @click="viewFile(n.fileName, n.documentNo)" class="text-xl icon-eye text-gray"></p>
						</template>
					</InputGista>
					<div class="flex justify-between mt-4">
						<span class="text-xs text-gray">Last upload: {{n.lastUpload}}</span>					
						<input
							type="file"
							:ref="`change-${n.fileRef}`"
							:id="`change-file-${n.fileRef}`"
							hidden
							@change="chooseFile($event, n.fileRef, i, `change-${n.fileRef}`)"
							/>
						<label :for="`change-file-${n.fileRef}`" class="text-sm font-bold underline cursor-pointer decoration-1" 
							:class="`${errors[i] && errors[i].value? 'text-error' : 'text-secondary'}`">Change file</label>
					</div>
				</Card>
				<div v-else class="flex flex-col justify-center h-full p-3 border border-dashed rounded-lg border-secondary">
					<h4 class="mb-3 text-base font-medium text-center">{{n.documentName}}</h4>
					<div class="flex justify-center">
						<div class="flex flex-col items-center">
							<input
								type="file"
								:ref="`file-${n.fileRef}`"
								:id="`upload-file-${n.fileRef}`"
								hidden
								@change="chooseFile($event, n.fileRef, i, `file-${n.fileRef}`)"
								/>
							<label :for="`upload-file-${n.fileRef}`" 
								class="px-6 py-3 mb-3 text-sm font-semibold text-white transition rounded-lg cursor-pointer bg-secondary hover:bg-secondary-dark active:bg-secondary-darkest shadow-secondary-sm hover:shadow-secondary-lg active:shadow-secondary-sm">
									Browse File
							</label>
							<div>
								<p :class="`text-center text-xs ${errors[i]?.value?'text-error':''}`">Supported file: (.pdf) , max. file size: 10MB</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<div class="grid w-full grid-cols-1 gap-6 p-6 lg:grid-cols-2 animate-pulse">
				<div v-for="n in 10" :key="n" class="w-full h-32 rounded-lg bg-gray-light"></div>
			</div>
		</div>
	</div>
</template>

<script>
import Card from '@/core/components/custom/Card.vue'

export default {
  name: "DocumentVendor",
  props: {
    vendor: Object,
	},
	data() {
		return {
			errors: [],
			isLoadingDocumentVendor: false,
			timeout: null,
		}
	},
	computed: {
		vendorDocumentsDetail() {
			return this.$store.state.vendor.vendorDocumentsDetail
		},
		modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    },
	},
	watch: {
		vendorDocumentsDetail() {
			this.errors = this.vendorDocumentsDetail?.documents?.map(() => ({ value: false }))
		}
	},
	methods: {
		async chooseFile(e, ref, i, fileRefInput) {
			//show loading
			const file = e.target.files[0]
			const checkFileValidation = await this.$store.dispatch('file/checkFileValidation', { file, fileType: ['application/pdf'] })
			if (checkFileValidation) {
				this.setErrorFile(i)
				this.resetFileInputValue(fileRefInput)
			} else {
				try {
					this.modal.modalLoading = true;
					//hash file checksum md5
					const encrypted = await this.$store.dispatch('file/hashMD5', file)
					const formData = new FormData()
					formData.append('file', file)
					formData.append('documentName', ref)
					formData.append('vendorId', this.vendor.vendorId)
					formData.append('isNewVendor', this.vendor.isNewVendor)
					formData.append('checksum', encrypted)
					const response = await this.$store.dispatch('vendor/postVendorDocumentsUpload', formData)
					if (response?.status < 300 && response?.status >= 200) {
						//loading out
						this.modal.modalLoading = false;
					} else {
						this.$store.dispatch('modal/throwError', { response })
					}
				} catch (error) {
					//loading out when error
					this.modal.modalLoading = false;
					//show modal error rfi document vendor
					this.modal.modalError = { value: true, traceid: error.traceid, title: error.title, message: error.message }
					
				}
				this.resetFileInputValue(fileRefInput)
				await this.$store.dispatch('vendor/getVendorDocumentsDetail', this.vendor.vendorId)
			}
		},
		resetFileInputValue(fileRefInput) {
			//reset input file ref
			if (this.$refs[fileRefInput]) {
				this.$refs[fileRefInput][0].value = ''
			}
		},
		setErrorFile(i) {
			if (this.timeout) {
				clearTimeout(this.timeout)
			}
			this.errors = this.errors.map((el, idx) => {
					return idx === i ? { value: true } : el
				})
			this.timeout = setTimeout(() => {
				this.errors = this.errors.map((el, idx) => {
					return idx === i ? { value: false } : el
				})
			}, 5000)
		},
		viewFile(filename, documentNo) {
			this.$store.dispatch('file/viewAttachment', { filename, documentNo })
		}
	},
	async mounted() {
		this.isLoadingDocumentVendor = true
		const res = await this.$store.dispatch('vendor/getVendorDocumentsDetail', this.vendor.vendorId)
		if (res?.status === 200) {
			this.isLoadingDocumentVendor = false
		}
	},
	components: {
		Card,
	}

}

</script>