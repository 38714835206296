<template>
  <div class="w-full">
    <div class="relative flex w-full my-6" :class="projectDetailContainerWidth < 640 && 'items-center '">
      <span class="text-2xl cursor-pointer icon-arrow-left text-gray" @click="() => isOpenCancel = true"></span>
      <ModalConfirm 
        title="Batalkan Perubahan?" 
        message="Perubahan tidak akan tersimpan" 
        icon="rejected" 
        textButton="Yes" 
        :isOpen="isOpenCancel" 
        @changeIsOpen="() => isOpenCancel = false" 
        @click="clickCancel"
        colorButton="error"></ModalConfirm>
      <div class="flex justify-center w-full">
        <h4 class="text-lg font-medium text-gray-darkest">Edit SPP</h4>
      </div>
    </div>
    <div>
      <ValidationObserver ref="formSpp">
        <Information />
        <CardSpp />
        <Approval />
      </ValidationObserver>
    </div>
    <div class="pt-6 mt-6 border-t-2 border-gray-lightest ">
      <div class="flex justify-end">
        <div class="flex" :class="projectDetailContainerWidth > 640 ? '' : 'flex-col gap-6 w-full'">
          <SubmitSpp 
            title="Kirim Perubahan SPP?"
            @click="submitSpp" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import SubmitSpp from '@/core/components/rfp/spp/SubmitSpp.vue'
import { MESSAGE_SUCCESS_CREATE_SPP } from '@/core/constant/successMessage'
import Information from '@/core/components/rfp/spp/Information.vue'
import Approval from '@/core/components/rfp/spp/Approval.vue'
import CardSpp from '@/core/components/rfp/spp/CardSpp.vue'
import ModalConfirm from '@/core/components/modal/Confirm'
import { MESSAGE_POPUP_VALIDATION_SUBMIT, MESSAGE_EMPTY_FIELD } from '@/core/constant/errorMessage.js'

export default {
  name: "EditSpp",
  data() {
    return {
      isOpenCancel: false,
    }
  },
  computed:{
    projectDetailContainerWidth() {
      return this.$store.state.width.projectDetailContainerWidth
    },
    dataSpp: {
      get() {
        return this.$store.state.spp.dataSpp
      },
      set(value) {
        this.$store.commit('spp/SET_DATA_SPP', value)
      }
    },
    modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    },
    approvals() {
      return this.$store.state.spp.approvals
    },
  },
  methods:{
    setErrorApprovals() {
      if (!this.approvals.APPROVER_DLOG?.search) {
        this.dataSpp.error['Approver DLOG'] = MESSAGE_EMPTY_FIELD
      }
      if (!this.approvals.APPROVER_GSIT?.search) {
        this.dataSpp.error['Approver GSIT'] = MESSAGE_EMPTY_FIELD
      }
    },
    setErrorVendors() {
      for (const s of this.dataSpp.sppVendors) {
        if (!s.vendorPic) s.error['PIC Vendor Principal'] = MESSAGE_EMPTY_FIELD
        if (!s.vendorEmail) s.error['Email Vendor Principal'] = MESSAGE_EMPTY_FIELD
        if (s.localPartnerName) {
          if (!s.localPartnerPic) s.error['PIC Local Partner'] = MESSAGE_EMPTY_FIELD
          if (!s.localPartnerEmail) s.error['Email Local Partner'] = MESSAGE_EMPTY_FIELD
        }
      }
    },
    setErrorAll(isSubmit) {
      this.setErrorApprovals()
      this.setErrorVendors()
      if(isSubmit && this.dataSpp?.attachments?.filter(e => e.fileRef === 'cancellationRequest' && !e.isDeleted).length === 0) {
        this.dataSpp.error['Cancellation Request'] = true
      }
    },
    async checkErrorSubmit(isSubmit) {
      await this.setErrorAll(isSubmit)
      const errorApprovals = Object.keys(this.dataSpp.error).filter(e => this.dataSpp.error[e])

      let errorSpp = []
      for (const s of this.dataSpp.sppVendors) {
        if (Object.values(s.error).some(el => ((el && el !== MESSAGE_EMPTY_FIELD && !isSubmit) || (el && isSubmit)))) {
          const text = s.localPartnerName? `SPP Local Partner #${s.sequence} of ${s.vendorName} (${s.localPartnerName})` : `SPP Vendor #${s.principalSequence} (${s.vendorName})`
          errorSpp.push(text)
        }
      }
      
      return [ ...errorSpp, ...errorApprovals ]
      
    },
    async submitSpp() {
      this.modal.modalLoading = true
      const checkErrorSubmit = await this.checkErrorSubmit(true)
      const messageError = checkErrorSubmit.join(', ')
      if (checkErrorSubmit.length) {
        this.modal.modalLoading = false
        this.modal.modalErrorValidate = { value: true, message: MESSAGE_POPUP_VALIDATION_SUBMIT + ' pada field ' + messageError }
        return
      }
      const response = await this.$store.dispatch('spp/updateSubmitSpp')
      if (response?.status < 300) {
        await this.$store.dispatch('spp/getSpp', { rfpId: this.$route.params.projectId })
        this.$store.dispatch('projectLog/refetchSppRfpLog', { rfpId: this.$route.params.projectId })
        this.modal.modalSuccessSubmitted = { value: true, title: MESSAGE_SUCCESS_CREATE_SPP }
        this.$store.commit('spp/SET_MAIN_PAGE', '')
      }
      this.modal.modalLoading = false
    },
    async clickCancel() {
      this.$store.commit('spp/SET_MAIN_PAGE', '')
      await this.$store.dispatch('spp/getSpp', { rfpId: this.$route.params.projectId })
    },
  },
  components:{
    SubmitSpp,
    Information,
    Approval,
    CardSpp,
    ModalConfirm,
  }
}
</script>

<style scoped>
.min-w-custom {
	min-width: 20rem;
}
</style>