<template>
  <div class="mt-6">
    <div v-if="!loading" >
      <div v-for="n,i in banList" :key="i" class="border-b border-gray-lightest mb-3.5">
        <div>
          <div class="flex justify-between">
            <div>
              <div class="flex items-center">
                <p class="mr-2 text-sm font-medium text-tertiary">Vendor #{{ n.vendorSequence }}</p>
                <div v-if="n.isNewVendor" class="px-2 py-1 rounded-lg bg-success-lightest">
                  <p class="text-sm text-success">New Vendor</p>
                </div>
              </div>
              <div class="py-1.5">
                <p class="text-base font-semibold text-gray-darkest">{{ n.vendorName }}</p>
              </div>
            </div>
            <div>
              <div @click="changeCollapse(i)">
                <p :class="n.collapse? 'rotate-180': 'rotate-0'"
                  class="text-2xl transition ease-in transform cursor-pointer icon-chevron-down text-gray hover:text-gray-darkest"></p>
              </div>
            </div>
          </div>
          <div class="py-3.5" v-if="!n.collapse">
            <div v-if="n.banList.length === 0 && n.localPartners.length === 0">
              <p class="text-sm font-medium text-gray-light">No Ban yet</p>
            </div>
            <div v-if="n.banList.length > 0" class="flex flex-wrap gap-y-4">
              <div v-for="col,j in n.banList" :key="j" class="mr-4 flex flex-col justify-between bg-white border border-gray-lightest rounded-xl shadow-gray-sm p-3.5">
                <div class="w-48">
                  <div class="flex justify-center mb-4">
                    <img src="@/core/assets/icons/message/saved.svg" alt="saved" class="w-12">
                  </div>
                  <div class="pb-4 border-b border-gray-lightest">
                    <p class="text-base font-medium text-center text-gray" >{{ col.title }}</p>
                    <p class="text-base font-medium text-center" :class="{'text-error': col.statusDeal === 'Offer Revision', 'text-success': col.statusDeal === 'Final Deal'}">{{ col.statusDeal }}</p>
                  </div>
                  <div class="py-4">
                  <div class="text-center">
                    <p class="text-sm font-medium" :class="statusApproved(col.statusApproval)">{{col.statusApproval}}</p>
                    <p class="text-sm font-medium text-gray">{{col.approver}}</p>
                  </div>
                </div>
                </div>
                <div>
                  <router-link
                    :to="toViewBan(col.negotiationId, col.statusDeal)"
                    v-if="(col.approverId !== userProfile.username || !statusWaitingApproval(col.statusApproval)) && (!col.isRenegotiation || !menuRfpNegotiationRenegotiation?.canUpdate || sppProcess) && menuRfpNegotiationDetailsBan?.canRead"
                    >
                    <button-gista 
                      type="tertiary"
                      color="gray"
                      size="small"
                      customClass="border border-primary shadow-primary-sm w-full font-semibold"
                    >View BAN</button-gista>
                  </router-link>
                  <router-link
                    :to="toViewBan(col.negotiationId, col.statusDeal)"
                    v-if="col.approverId === userProfile.username && statusWaitingApproval(col.statusApproval) && menuRfpNegotiationDetailsBan?.canRead && !sppProcess"
                    >
                    <button-gista 
                      color="secondary"
                      size="small"
                      customClass="w-full"
                    >Approve / Reject</button-gista>
                  </router-link>
                  <router-link
                    :to="toTakeAction(n.rfpVendorCandidateId)"
                    v-if="col.isRenegotiation && menuRfpNegotiationRenegotiation?.canUpdate && !sppProcess"
                    >
                    <button-gista 
                      color="secondary"
                      size="small"
                      customClass="w-full"
                    >Take Action</button-gista>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-6" v-if="!n.collapse">
            <div v-for="(local, idx) in n.localPartners" :key="idx" class="border-l border-gray">
              <div :class="{ 'border-b-2 border-dashed border-gray-lightest pt-6 mb-6': idx !== 0}"></div>
              <div class="pl-4 ">
                <div class="flex items-center">
                  <p class="mr-2 text-sm font-medium text-tertiary">Local Partner #{{ local.vendorSequence }}</p>
                  <div v-if="local.isNewVendor" class="px-2 py-1 rounded-lg bg-success-lightest">
                    <p class="text-sm text-success">New Vendor</p>
                  </div>
                </div>
                <div class="py-1.5">
                  <p class="text-base font-semibold text-gray-darkest">{{ local.vendorName }}</p>
                </div>
                <div class="pt-3.5">
                  <div v-if="local.banList.length === 0">
                    <p class="text-sm font-medium text-gray-light">No Ban yet</p>
                  </div>
                  <div v-else class="flex flex-wrap gap-y-4">
                    <div v-for="col,j in local.banList" :key="j" class="mr-4 flex flex-col justify-between bg-white border border-gray-lightest rounded-xl shadow-gray-sm p-3.5">
                      <div class="w-48">
                        <div class="flex justify-center mb-4">
                          <img src="@/core/assets/icons/message/saved.svg" alt="saved" class="w-12">
                        </div>
                        <div class="pb-4 border-b border-gray-lightest">
                          <p class="text-base font-medium text-center text-gray" >{{ col.title }}</p>
                          <p class="text-base font-medium text-center" :class="{'text-error': col.statusDeal === 'Offer Revision', 'text-success': col.statusDeal === 'Final Deal'}">{{ col.statusDeal }}</p>
                        </div>
                        <div class="py-4">
                          <div class="text-center">
                            <p class="text-sm font-medium" :class="statusApproved(col.statusApproval)">{{col.statusApproval}}</p>
                            <p class="text-sm font-medium text-gray">{{col.approver}}</p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <router-link 
                          :to="toViewBan(col.negotiationId, col.statusDeal)" 
                          v-if="(col.approverId !== userProfile.username || !statusWaitingApproval(col.statusApproval)) && (!col.isRenegotiation || !menuRfpNegotiationRenegotiation?.canUpdate || sppProcess) && menuRfpNegotiationDetailsBan?.canRead">
                          <button-gista 
                            type="tertiary"
                            color="gray"
                            size="small"
                            customClass="border border-primary shadow-primary-sm w-full font-semibold"
                          >View BAN</button-gista>
                        </router-link>
                        <router-link 
                          :to="toViewBan(col.negotiationId, col.statusDeal)"
                          v-if="col.approverId === userProfile.username && statusWaitingApproval(col.statusApproval) && menuRfpNegotiationDetailsBan?.canRead && !sppProcess"
                          >
                          <button-gista 
                            color="secondary"
                            size="small"
                            customClass="w-full"
                          >Approve / Reject</button-gista>
                        </router-link>
                        <router-link
                          :to="toTakeAction(local.rfpVendorCandidateId)"
                          v-if="col.isRenegotiation && menuRfpNegotiationRenegotiation?.canUpdate && !sppProcess"
                          >
                          <button-gista 
                            color="secondary"
                            size="small"
                            customClass="w-full"
                          >Take Action</button-gista>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="animate-pulse">
        <div class="w-40 h-4 mb-2 rounded bg-gray-light"></div>
        <div class="w-40 h-4 mb-2 rounded bg-gray-light"></div>
        <div class="flex flex-wrap gap-y-4">
          <div v-for="n,i in 3" :key="i" class="mr-4 flex flex-col justify-between bg-white border border-gray-lightest rounded-xl shadow-gray-sm p-3.5">
            <div class="flex flex-col items-center w-48">
              <div class="w-16 h-16 mb-4 rounded bg-gray-light"></div>
              <div class="w-32 h-4 mb-2 rounded bg-gray-light"></div>
              <div class="w-32 h-4 mb-10 rounded bg-gray-light"></div>
              <div class="h-8 rounded bg-gray-light w-44"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "BanTab",
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    banList: {
      get() {
        return this.$store.state.negotiation.banList
      },
      set(val) {
        this.$store.commit('negotiation/SET_BAN_LIST', val)
      }
    },
    userProfile() {
			return this.$store.state.dashboard.userProfile
		},
    menuRfpNegotiationRenegotiation() {
			return this.$store.getters['dashboard/menuRfpNegotiationRenegotiation']
		},
    menuRfpNegotiationDetailsBan() {
			return this.$store.getters['dashboard/menuRfpNegotiationDetailsBan']
    },
		sppProcess() {
			return this.$store.state.spp.sppProcess
		},
  },
  methods: {
    toViewBan(negotiationId, statusDeal) {
      const OFFER_REVISION = 'Offer Revision'
      if (statusDeal === OFFER_REVISION) {
        return ({ name: 'projectNegotiation', query: { tab: 'BAN', detail: 'offer', negotiation: negotiationId }})
      } else {
        return ({ name: 'projectNegotiation', query: { tab: 'BAN', negotiation: negotiationId }})
      }
    },
    statusApproved(status) {
      if (status?.toLowerCase().includes('approved')) {
        return 'text-success'
      } else if (status?.toLowerCase().includes('rejected')) {
        return 'text-error'
      } else {
        return 'text-warning'
      }
    },
    statusWaitingApproval(status) {
      return status?.toLowerCase().includes('waiting')
    },
    toTakeAction(rfpVendorCandidateId) {
      return ({ name: 'projectNegotiation', query: { vendor: rfpVendorCandidateId }})
    },
    changeCollapse(i) {
			this.banList[i].collapse = !this.banList[i].collapse
		},
  },
  async mounted() {
    this.loading = true
    const response = await this.$store.dispatch('negotiation/getBanList', { rfpId: this.$route.params.projectId })
    if (response?.status === 200) {
      this.loading = false
    }
  },
  components: {
  }
}
</script>