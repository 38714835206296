<template>
	<div class="vendor-c w-full mt-6">
		<div class="flex justify-between mb-3 cursor-pointer" @click="changeCollapse">
			<div class="flex">
				<span class="icon-user text-xl text-gray-dark font-semibold mr-6"></span>
				<h4 class="text-base text-gray-dark font-semibold">VENDOR CANDIDATE</h4>
			</div>
			<div>
				<p :class="collapse.vendorCandidate ? 'rotate-180' : 'rotate-0'"
					class="icon-chevron-down text-gray text-2xl transform transition ease-in"
					></p>
			</div>
		</div>
		<div v-show="collapse.vendorCandidate">
			<div :class="`${projectDetailContainerWidth < 600? 'grid-cols-1' : projectDetailContainerWidth < 1024 ? 'grid-cols-2' : vendorCandidate.length < 3? 'grid-cols-2' : 'grid-cols-3' } mb-4 grid gap-3 w-full`">
				<div v-for="(n, i) in qualifications"
					:key="i"
					class="card-vc border border-gray-lightest rounded-xl">
					<div class=" p-2.5 w-full">
						<h5 class="text-tertiary mb-1 font-medium">Vendor #{{  n?.sequence  }}</h5>
						<p class="text-gray-darkest text-xs mb-1 break-words">{{  n?.vendorName  }} - {{  n?.vendorEmail  }}</p>
						<p class="text-gray text-xs"><span class="text-gray-dark">Reason:</span> {{  n?.reason  }}</p>
					</div>
					<div v-for="(local, j) in n.localPartners" :key="j">
						<div class=" p-2.5 w-full">
							<h5 class="text-tertiary mb-1 font-medium">Local Partner #{{  local?.sequence  }}</h5>
							<p class="text-gray-darkest text-xs mb-1 break-words">{{  local?.vendorName  }} - {{  local?.vendorEmail  }}</p>
							<p class="text-gray text-xs"><span class="text-gray-dark">Reason:</span> {{  local?.reason  }}</p>
						</div>
					</div>
				</div>
			</div>
			<div class="flex justify-center mt-2 w-full">
				<router-link :to="{ name: 'AddVendorCandidate', params: { projectId: $route.params.projectId }}" exact v-if="menuVendorCandidate?.canUpdate && updateVendorCandidatesHide" >
					<ButtonGista type="secondary"
						color="secondary">
						Update Vendor Candidates
					</ButtonGista>
				</router-link>
			</div>
		</div>
	</div>
</template>
<script>
import { PATH_ROOT } from '@/core/constant/routeName'
import { UKKP_07_AWAITING_MEMO_CREATION, GSIT_07_AWAITING_MEMO_CREATION, CANCELED, COMPLETED } from '@/core/constant/statusProject'

export default {

	name: "VendorCandidateDetail",
	data() {
		return {
			PATH_ROOT,
		}
	},
	computed: {
		vendorCandidate() {
			return this.$store.state.projectDetail.vendorCandidate
		},
		qualifications() {
			return this.$store.state.qualification.qualifications
		},
		collapse() {
			return this.$store.state.projectDetail.collapse
		},
		menuVendorCandidate() {
			return this.$store.getters['dashboard/menuRfpDetailsVendorCandidate']
		},
		projectLogRfp() {
			return this.$store.state.projectLog.projectLogRfp
		},
		updateVendorCandidatesHide() {
			const statusProject = [UKKP_07_AWAITING_MEMO_CREATION, GSIT_07_AWAITING_MEMO_CREATION, CANCELED, COMPLETED]
      return !this.projectLogRfp?.rfpLogs?.some(e => statusProject.includes(e.statusProject))
		},
		projectDetailContainerWidth() {
			return this.$store.state.width.projectDetailContainerWidth
		},
	},

	methods: {
		changeCollapse() {
			this.$emit("collapse")
		},
	}
}
</script>