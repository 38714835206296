<template>
  <div class="project-i w-full mt-6">
    <div class="flex justify-between mb-3 cursor-pointer" @click="changeCollapse">
      <div class="flex">
        <span class="icon-list text-xl text-gray-dark font-semibold mr-6"></span>
        <h4 class="text-base text-gray-dark font-semibold">PROJECT REQUIREMENT</h4>
      </div>
      <div>
        <p :class="collapse.projectRequirement? 'rotate-180': 'rotate-0'" class="icon-chevron-down text-gray text-2xl transform transition ease-in"  ></p>
      </div>
    </div>
    <div v-show="collapse.projectRequirement" class="mb-4 w-full">
      <div class="border border-gray-lightest p-2.5 rounded-xl items-start w-full">
        <div class="mb-3 w-full">
          <h5 class="text-tertiary text-sm font-medium mb-1">Persyaratan Produk dan Jasa</h5>
					<TiptapView :value="projectRequirement?.persyaratanProdukDanJasa" v-if="projectRequirement?.persyaratanProdukDanJasa" :grayColor="false"/>
          <p v-else class="text-gray-dark">-</p>
          <div v-if="attachments1" class="flex items-center mt-1">
            <span class="icon-file mr-2"></span>
            <p class="text-secondary underline text-sm cursor-pointer" @click="viewAttachment('persyaratanProdukDanJasa')">View Attachment</p>
          </div>
        </div>
        <div class="mb-3 w-full">
          <h5 class="text-tertiary text-sm font-medium mb-1">Persyaratan Teknis</h5>
					<TiptapView :value="projectRequirement?.persyaratanTeknis" v-if="projectRequirement?.persyaratanTeknis" :grayColor="false"/>
          <p v-else class="text-gray-dark">-</p>
          <div v-if="attachments2" class="flex items-center mt-1">
            <span class="icon-file mr-2"></span>
            <p class="text-secondary underline text-sm cursor-pointer" @click="viewAttachment('persyaratanTeknis')">View Attachment</p>
          </div>
        </div>
        <div class="mb-3 w-full">
          <h5 class="text-tertiary text-sm font-medium mb-1">Arsitektur dan Standar</h5>
					<TiptapView :value="projectRequirement?.arsitekturDanStandar" v-if="projectRequirement?.arsitekturDanStandar" :grayColor="false"/>
          <p v-else class="text-gray-dark">-</p>
          <div v-if="attachments3" class="flex items-center mt-1">
            <span class="icon-file mr-2"></span>
            <p class="text-secondary underline text-sm cursor-pointer" @click="viewAttachment('arsitekturDanStandar')">View Attachment</p>
          </div>
        </div>
        <div class="mb-3 w-full">
          <h5 class="text-tertiary text-sm font-medium mb-1">Implementasi</h5>
          <TiptapView :value="projectRequirement?.implementasi" v-if="projectRequirement?.implementasi" :grayColor="false"/>
          <p v-else class="text-gray-dark">-</p>
        </div>
        <div class="mb-3 w-full">
          <h5 class="text-tertiary text-sm font-medium mb-1">Pengembangan dan Interface</h5>
          <TiptapView :value="projectRequirement?.pengembanganDanInterface" v-if="projectRequirement?.pengembanganDanInterface" :grayColor="false"/>
          <p v-else class="text-gray-dark">-</p>
        </div>
        <div class="mb-3 w-full">
          <h5 class="text-tertiary text-sm font-medium mb-1">Project Status Reporting</h5>
          <TiptapView :value="projectRequirement?.projectStatusReporting" v-if="projectRequirement?.projectStatusReporting" :grayColor="false"/>
          <p v-else class="text-gray-dark">-</p>
        </div>
        <div class="mb-3 w-full">
          <h5 class="text-tertiary text-sm font-medium mb-1">Sumber Daya yang Tersedia</h5>
          <TiptapView :value="projectRequirement?.sumberDayaYangTersedia" v-if="projectRequirement?.sumberDayaYangTersedia" :grayColor="false"/>
          <p v-else class="text-gray-dark">-</p>
        </div>
        <div class="mb-3 w-full">
          <h5 class="text-tertiary text-sm font-medium mb-1">Service Level</h5>
          <TiptapView :value="projectRequirement?.serviceLevel" v-if="projectRequirement?.serviceLevel" :grayColor="false"/>
          <p v-else class="text-gray-dark">-</p>
        </div>
        <div class="mb-3 w-full">
          <h5 class="text-tertiary text-sm font-medium mb-1">Pengamanan TI</h5>
          <TiptapView :value="projectRequirement?.pengamanTi" v-if="projectRequirement?.pengamanTi" :grayColor="false"/>
          <p v-else class="text-gray-dark">-</p>
        </div>

        <div class="mb-3 w-full">
          <h5 class="text-tertiary text-sm font-medium mb-1">Dokumentasi</h5>
          <TiptapView :value="projectRequirement?.dokumentasi" v-if="projectRequirement?.dokumentasi" :grayColor="false"/>
          <p v-else class="text-gray-dark">-</p>
        </div>

        <div class="mb-3 w-full">
          <h5 class="text-tertiary text-sm font-medium mb-1">Pelatihan</h5>
          <TiptapView :value="projectRequirement?.pelatihan" v-if="projectRequirement?.pelatihan" :grayColor="false"/>
          <p v-else class="text-gray-dark">-</p>
        </div>

        <div class="mb-3 w-full">
          <h5 class="text-tertiary text-sm font-medium mb-1">Dukungan Pemeliharaan (After Sales Support)</h5>
          <TiptapView :value="projectRequirement?.dukunganPemeliharaan" v-if="projectRequirement?.dukunganPemeliharaan" :grayColor="false"/>
          <p v-else class="text-gray-dark">-</p>
        </div>

        <div class="mb-3 w-full">
          <h5 class="text-tertiary text-sm font-medium mb-1">Acceptance Test</h5>
          <TiptapView :value="projectRequirement?.acceptanceTest" v-if="projectRequirement?.acceptanceTest" :grayColor="false"/>
          <p v-else class="text-gray-dark">-</p>
        </div>

        <div class="mb-3 w-full">
          <h5 class="text-tertiary text-sm font-medium mb-1">Referensi/Pengalaman</h5>
          <TiptapView :value="projectRequirement?.referensi" v-if="projectRequirement?.referensi" :grayColor="false"/>
          <p v-else class="text-gray-dark">-</p>
        </div>

      </div>
    </div>
    <ModalReadMore @click="closeModal" :isOpen="readMoreShow" :text="modalText"/>
  </div>
</template>

<script>
import ModalReadMore from '@/core/components/modal/ReadMore.vue'
import TiptapView from '@/core/components/custom/TiptapView.vue'

export default {
  name: "ProjectRequirementDetail",
  data() {
		return {
			modalText: '',
			readMoreShow: false,
		}
	},
  computed: {
    projectRequirement() {
      return this.$store.state.projectDetail.projectRequirement
    },
    collapse() {
      return this.$store.state.projectDetail.collapse
    },
    attachments1() {
      return this.projectRequirement.rfpAttachments.find(e => e.fileRef === 'persyaratanProdukDanJasa')
    },
    attachments2() {
      return this.projectRequirement.rfpAttachments.find(e => e.fileRef === 'persyaratanTeknis')
    },
    attachments3() {
      return this.projectRequirement.rfpAttachments.find(e => e.fileRef === 'arsitekturDanStandar')
    }
  },
  methods: {
    async viewAttachment(key) {
      const find = this.projectRequirement.rfpAttachments.find(e => e.fileRef === key)
      const documentNo = find.documentNo
      const filename = find.fileName
      this.$store.dispatch('file/viewAttachment', { filename, documentNo })
    },
    changeCollapse() {
      this.$emit("collapse")
    },
    closeModal() {
			this.readMoreShow = false
		},
		readMore(text) {
			this.readMoreShow = true;
			this.modalText = text
		}
  },
  components: {
    ModalReadMore,
    TiptapView,
  }
}
</script>