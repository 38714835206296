<template>
	<div>
		<div v-if="vendorDocumentsDetailProject.length === 0" class="flex justify-center py-6">
			<p class="font-medium text-gray">No Data</p>
		</div>
		<div v-for="(n,i) in vendorDocumentsDetailProject" :key="i" class="px-6 pt-6 border-b border-gray-lightest">
			<div v-if="n" class="flex justify-between mb-6 cursor-pointer" @click="changeCollapse(i)">
				<div class="flex">
					<span class="mr-6 text-lg icon-file-text text-gray"></span>
					<div>
						<div class="mb-2">{{n?.projectId || '' }} - {{n?.projectName || ''}}</div>
						<div v-if="n?.eliminated" class="text-sm text-error">Eliminated</div>
					</div>
				</div>
				<div>
					<p :class="collapse[i]?.value? 'rotate-180' : 'rotate-0'" class="text-2xl transition ease-in transform icon-chevron-down text-gray"></p>
				</div>
			</div>
			<div v-if="n.projectId && collapse[i]?.value && !isLoading" class="grid w-full grid-cols-1 gap-6 mb-4 lg:grid-cols-2">
				<div v-for="(col,j) in n?.documents" :key="j" >
					<Card v-if="col.filePath" class="p-4 border border-gray-lightest shadow-secondary-s">
						<div class="flex items-center justify-center w-full mb-4">
							<h4 class="text-base font-medium text-center">{{col?.documentName}}</h4>
						</div>
						<InputGista type=text
							disabled
							size="small"
							:value="col?.fileName">
							<template #icon-right>
								<p @click="viewFile(col?.fileName, col?.documentNo)" class="text-xl icon-eye text-gray"></p>
							</template>
						</InputGista>
						<div class="flex justify-between mt-4">
							<span class="text-xs text-gray">Last upload: {{col?.lastUpload}}</span>
							<input
								v-if="!n?.eliminated"
								type="file"
								:ref="`change-${i}-${j}-${col?.fileRef}`"
								:id="`change-file-${i}-${j}-${col?.fileRef}`"
								hidden
								@change="chooseFile($event, col?.fileRef, i, j, `change-${i}-${j}-${col?.fileRef}`)"
							/>
							<label v-if="!n?.eliminated" :for="`change-file-${i}-${j}-${col?.fileRef}`" 
								class="text-sm font-bold underline cursor-pointer decoration-1"
								:class="`${errors[i] && errors[i][j] && errors[i][j].value? 'text-error' : 'text-secondary'}`" >Change file</label>
						</div>
					</Card>
					<div v-else class="h-full p-3 border border-dashed rounded-lg border-secondary">
						<h4 class="mb-3 text-base font-medium text-center">{{col?.documentName}}</h4>
						<div class="flex justify-center">
							<div class="flex flex-col items-center">
								<input
									v-if="!n?.eliminated"
									type="file"
									:ref="`file-${i}-${j}-${col?.fileRef}`"
									:id="`upload-file-${i}-${j}-${col?.fileRef}`"
									hidden
									@change="chooseFile($event, col?.fileRef, i, j, `file-${i}-${j}-${col?.fileRef}`)"
									/>
								<label :for="n?.eliminated? '' :`upload-file-${i}-${j}-${col?.fileRef}`"
									class=""
									:class="{ 
										'bg-secondary-light shadow-secondary-sm px-6 py-3 rounded-lg text-white font-semibold text-sm mb-3 cursor-not-allowed': n?.eliminated, 
										'bg-secondary hover:bg-secondary-dark active:bg-secondary-darkest shadow-secondary-sm hover:shadow-secondary-lg active:shadow-secondary-sm transition px-6 py-3 rounded-lg text-white font-semibold text-sm mb-3 cursor-pointer' :!n?.eliminated
									}">Browse File</label>
								<div>
									<p :class="`text-center text-xs ${errors[i] && errors[i][j] && errors[i][j].value ? 'text-error':''}`">Supported file: (.pdf) , max. file size: 10MB</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-else-if="isLoading" >
				<div class="grid w-full grid-cols-1 gap-6 mb-4 lg:grid-cols-2 animate-pulse">
					<div v-for="n in 2" :key="n" class="w-full h-32 rounded-lg bg-gray-light"></div>
				</div>
			</div>
			<div class="flex justify-center mb-3 ">
				<div class="flex cursor-pointer" @click="clickViewQualification(n)">
					<p class="text-sm font-semibold text-tertiary">View Qualification</p>
					<p class="text-2xl icon-chevron-right text-tertiary"></p>
				</div>
			</div>
				
		</div>
	</div>
</template>

<script>
import Card from '@/core/components/custom/Card.vue'
import { PATH_ROOT } from '@/core/constant/routeName'

export default {
  name: "DocumentProject",
  props: {
    vendor: Object,
		projectId: String,
		isLoading: Boolean,
	},
	data() {
		return {
			errors: [],
			collapse: [{value: false}],
			timeout: null,
		}
	},
	computed: {
		vendorDocumentsDetailProject() {
			return this.$store.state.vendor.vendorDocumentsDetailProject
		},
		modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    },
	},
	watch: {
		vendorDocumentsDetailProject() {
			console.log(this.vendorDocumentsDetailProject)
			this.errors = this.vendorDocumentsDetailProject?.map(e => e.documents?.map(() => ({ value: false })))
			this.collapse = this.vendorDocumentsDetailProject?.map(() => ({ value:true }))
		},
	},
	methods: {
		async chooseFile(e, ref, i, j, fileRefInput) {
			//show loading
			const file = e.target.files[0]
			const checkFileValidation = await this.$store.dispatch('file/checkFileValidation', { file, fileType: ['application/pdf'] })
			if (checkFileValidation) {
				this.setErrorFile(i, j)
				this.resetFileInputValue(fileRefInput)
			} else {
				const projectId = this.vendorDocumentsDetailProject[i].projectId
				try {
					this.modal.modalLoading = true;
					//hash file checksum md5
					const encrypted = await this.$store.dispatch('file/hashMD5', file)
					const formData = new FormData()
					formData.append('file', e.target.files[0])
					formData.append('documentName', ref)
					formData.append('vendorId', this.vendor.vendorId)
					formData.append('isNewVendor', this.vendor.isNewVendor)
					//project Id
					formData.append('projectId', projectId)
					//checksum encrypted md5
					formData.append('checksum', encrypted)
					const response = await this.$store.dispatch('vendor/postVendorDocumentsUploadProject', formData)
					if (response?.status < 300 && response?.status >= 200) {
						//loading out
						this.modal.modalLoading = false;
					} else {
						this.$store.dispatch('modal/throwError', { response })
					}
				} catch (error) {
					//loading out when error
					this.modal.modalLoading = false;
					//show modal error rfi document project
					this.modal.modalError = { value: true, traceid: error.traceid, title: error.title, message: error.message }
				}
				this.resetFileInputValue(fileRefInput)
				if (this.vendorDocumentsDetailProject.length === 1) {
					await this.$store.dispatch('vendor/getVendorDocumentsDetailProject', { vendorId: this.vendor.vendorId, projectId: projectId })
				} else {
					await this.$store.dispatch('vendor/getVendorDocumentsDetailProject', { vendorId: this.vendor.vendorId, projectId: '' })
				}
			}
			
		},
		resetFileInputValue(fileRefInput) {
			//reset input file ref
			if (this.$refs[fileRefInput]) {
				this.$refs[fileRefInput][0].value = ''
			}
		},
		setError(i,j, value) {
			this.errors = this.errors?.map((el, index) => {
					if (index === i) {
						return el.map((ele, idx) => {
							if (idx === j) {
								return ({ value })
							} else if (!value) {
								return { value: false }
							} else {
								return ele
							}
						})
					} else {
						return el.map((ele) => (ele))
					}
				})
		},
		setErrorFile(i, j) {
			if (this.timeout) {
				clearTimeout(this.timeout)
			}
			this.setError(i,j, true)
			this.timeout = setTimeout(() => {
				this.setError(i,j, false)
			}, 5000)
		},
		clickViewQualification(n) {
			this.$emit('closeModal')
			setTimeout(() => {
				this.$router.push(`/${PATH_ROOT}/project-qualification/${n.projectRequestId}`).catch(() => ({}))
			}, 200)
		},
		changeCollapse(i) {
			this.collapse[i].value = !this.collapse[i].value
		},
		viewFile(filename, documentNo) {
			this.$store.dispatch('file/viewAttachment', { filename, documentNo })
		}
	},
	mounted() {
		this.errors = this.vendorDocumentsDetailProject?.map(e => e.documents?.map(() => ({value: false})))
		this.collapse = this.vendorDocumentsDetailProject?.map(() => ({value: true}))
	},
	components: {
		Card,
	}

}

</script>