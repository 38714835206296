<template>
  <div class="w-full">
    <div class="grid gap-x-6 gap-y-2.5 mb-2.5"
      :class="{
        'grid-cols-1': projectDetailContainerWidth < 768,
        'grid-cols-2': projectDetailContainerWidth >= 768
      }"
    >
      <InputDisabled 
        v-if="isSubmitted"
        label="Approval No." 
        value="PRJ2200001"
      />
      <InputDisabled 
        v-else
        label="Project ID" 
        :value="proposalInformation.projectId"
      />
      <InputDisabled 
        label="Request No."
        :value="dataSpp.noRfp"
      />
    </div>
    <div class="mb-6">
      <InputDisabled 
        label="Project Name"
        :value="dataSpp.projectName"
      />
    </div>
    
  </div>
</template>
  
<script>
import InputDisabled from '@/core/components/custom/InputDisabled.vue'

export default {
  name: "Information",
  props: ['readOnly'],
  data(){
    return {
    }
  },
  computed:{
    projectDetailContainerWidth() {
      return this.$store.state.width.projectDetailContainerWidth
    },
    proposalInformation() {
      return this.$store.state.projectDetail.proposalInformation
    },
    dataSpp: {
      get() {
        return this.$store.state.spp.dataSpp
      },
      set(value) {
        this.$store.commit('spp/SET_DATA_SPP', value)
      }
    },
    isSubmitted() {
      return !!(this.dataSpp.statusDocument === 'SUBMITTED')
    },
  },
  methods: {
  },
  mounted() {
  },
  components:{
    InputDisabled,
  }
}
</script>