<template>
  <div>
    <ApprovalProcurementLoading v-if="loading.loadingApprovalProcurement"></ApprovalProcurementLoading>
    <div v-if="memoShow && !loading.loadingApprovalProcurement">
      <div v-if="!dataProcurementApproval.isNew && (!isSubmitted && !isRejected) && showButtonContinueDraft && !mainPage && menuRfpApprovalDetail?.canCreate">
        <div class="flex flex-col items-center justify-center gap-12 h-96">
          <img src="@/core/assets/icons/file/others.svg" alt="other" class="w-28" />
          <ButtonGista type="success" class="w-full sm:w-56" @click="() => isOpenContinueDraft = true">Continue Draft</ButtonGista>
          <ModalConfirm 
            title="Lanjutkan Draft?" 
            :message="`Draft terakhir: ${dataProcurementApproval.lastModifiedAt}`" 
            icon="saved" textButton="Continue Draft" 
            :isOpen="isOpenContinueDraft" 
            @changeIsOpen="() => isOpenContinueDraft = false" 
            @click="setShowButtonContinueDraft(false)"
            colorButton="success">
          </ModalConfirm>
        </div>
      </div>
      <div v-if="!sppProcess">
        <CreateProcurementApproval 
          v-if="(!showButtonContinueDraft || dataProcurementApproval.isNew) && !isSubmitted && !mainPage && menuRfpApprovalDetail?.canCreate"
          @setShowButtonContinueDraft="setShowButtonContinueDraft" 
          @refetchApprovalLog="refetchApprovalLog"
        />
        <ChangeApprover v-if="mainPage === CHANGE && menuRfpApprovalDetail?.canCreate" @refetchApprovalLog="refetchApprovalLog"/>
        <EditProcurementApproval v-if="mainPage === EDIT && menuRfpApprovalDetail?.canCreate" @refetchApprovalLog="refetchApprovalLog"/>
      </div>
      <div v-else-if="!isSubmitted && !isRejected"
        class="flex items-center justify-center height-custom">
        <div class="flex">
          <p class="mr-3 text-2xl icon-alert-circle text-gray"></p>
          <p class="text-base font-medium text-gray">Proses SPP sedang di mulai</p>
        </div>
      </div>
      <ProcurementApprovalDetails v-if="(isSubmitted || isRejected) && !mainPage"/>
    </div>
    <div v-if="!memoShow && !loading.loadingApprovalProcurement"
      class="flex items-center justify-center height-custom">
      <div class="flex">
        <p class="mr-3 text-2xl icon-alert-circle text-gray"></p>
        <p class="text-base font-medium text-gray">Proses Procurement Approval belum di mulai</p>
      </div>
    </div>
    <div v-if="memoShow && !loading.loadingApprovalProcurement && !menuRfpApprovalDetail?.canCreate && !isSubmitted && !sppProcess"
      class="flex items-center justify-center height-custom">
      <div class="flex">
        <p class="mr-3 text-2xl icon-alert-circle text-gray"></p>
        <p class="text-base font-medium text-gray">Menunggu kelengkapan form Procurement Approval</p>
      </div>
    </div>
  </div>
</template>

<script>
import { UKKP_07_AWAITING_MEMO_CREATION, GSIT_07_AWAITING_MEMO_CREATION, USER_04_AWAITING_MEMO_CREATION } from '@/core/constant/statusProject'
import CreateProcurementApproval from '@/core/components/rfp/procurementApproval/CreateProcurementApproval.vue'
import ProcurementApprovalDetails from '@/core/components/rfp/procurementApproval/ProcurementApprovalDetails.vue'
import ModalConfirm from '@/core/components/modal/Confirm'
import ChangeApprover from '@/core/components/rfp/procurementApproval/ChangeApprover.vue';
import EditProcurementApproval from '@/core/components/rfp/procurementApproval/EditProcurementApproval.vue'
import ApprovalProcurementLoading from '@/core/components/rfp/loading/ApprovalProcurementLoading.vue';
import { CHANGE, EDIT } from '@/core/constant/procurementApproval.js'
import { VENDOR_01_AWAITING_PRE_QUALIFICATION_DOCUMENT, PROCUREMENT_PROCESS, CANCELED } from '@/core/constant/statusProject'
import { PATH_ROOT, PROJECT_DETAIL } from '@/core/constant/routeName'
export default {
  name: "ProcurementApproval",
  data() {
    return {
      isContinueDraft: false,
      showButtonContinueDraft: false,
      isOpenContinueDraft: false,
      CHANGE,
      EDIT
    };
  },
  computed: {
    projectLogRfp() {
      return this.$store.state.projectLog.projectLogRfp
    },
    memoShow() {
      const statusProject = [ UKKP_07_AWAITING_MEMO_CREATION, GSIT_07_AWAITING_MEMO_CREATION, USER_04_AWAITING_MEMO_CREATION ]
      return this.projectLogRfp?.rfpLogs.some(e => statusProject.includes(e.statusProject))
    },
    menuRfpApprovalDetail() {
			return this.$store.getters['dashboard/menuRfpApprovalDetail']
		},
    loading: {
      get() {
        return this.$store.state.modal.loading
      },
      set(value) {
        this.$store.commit('modal/SET_LOADING', value)
      }
    },
    dataProcurementApproval: {
      get() {
        return this.$store.state.procurementApproval.dataProcurementApproval
      },
      set(value) {
        this.$store.commit('procurementApproval/SET_DATA_PROCUREMENT_APPROVAL', value)
      }
    },
    isRejected() {
      return this.dataProcurementApproval.isRejected
    },
    isSubmitted() {
      return this.dataProcurementApproval.isSubmitted
    },
    mainPage() {
      return this.$store.state.procurementApproval.mainPage
    },
    sppProcess() {
			return this.$store.state.spp.sppProcess
		},
    counterInterval() {
			return this.$store.state.projectLog.counterInterval
		},
    projectCanceledByLogs() {
			return this.projectLogRfp.rfpLogs.some(e => e.statusProject === CANCELED)
		},
		projectCanceled() {
			return this.sppProcess || this.projectCanceledByLogs
		},
    pradetailShow() {
			const statusProject = [VENDOR_01_AWAITING_PRE_QUALIFICATION_DOCUMENT]
			return this.projectLogRfp?.rfpLogs.some(e => statusProject.includes(e.statusProject))
		},
    memoDetailShow() {
			const statusProject = [ PROCUREMENT_PROCESS ]
      const statusProjectMemo = this.projectLogRfp?.rfpLogs.some(e => statusProject.includes(e.statusProject))
			return this.pradetailShow && (!this.projectCanceled || statusProjectMemo)
		},
    activePage: {
			get() {
				return this.$store.state.projectDetail.activePage
			},
			set(value) {
				this.$store.commit('projectDetail/SET_ACTIVE_PAGE', value)
			}
		},
  },
  methods: {
    changeIsContinueDraft(value) {
      this.isContinueDraft = value
    },
		changeSubmittedProcurementApproval(value) {
			this.dataProcurementApproval.isSubmitted = value
		},
    setShowButtonContinueDraft(value) {
      this.showButtonContinueDraft = value
    },
    refetchApprovalLog() {
      this.$store.dispatch('projectLog/refetchApprovalProcurementLog', { rfpId: this.$route.params.projectId })
    },
    setActivePage(paramProjectId) {
			if (!this.memoDetailShow) {
				this.activePage = PROJECT_DETAIL
				this.$router.push({ path: `/${PATH_ROOT}/${PROJECT_DETAIL}/${paramProjectId}` }).catch(() => ({}))
			}
		},
  },
  async created() {
    this.loading.loadingProjectLog = true
    this.loading.loadingApprovalProcurement = true
    const res = await this.$store.dispatch('projectLog/getCheckAccess', { projectRequestId: this.$route.params.projectId, projectRequestType: 'rfp' })
    if (res?.data.code !== 200) return
    const res1 = await this.$store.dispatch('projectLog/getProjectLogByRfpId', { rfpId: this.$route.params.projectId })
    this.setActivePage(this.$route.params.projectId)
    if (res1?.status === 200) {
      if (this.memoShow) {
        const res2 = await this.$store.dispatch('procurementApproval/getApproval', { rfpId: this.$route.params.projectId })
        if (res2?.status === 200) {
          if (!this.dataProcurementApproval.isNew || this.isSubmitted) {
            this.setShowButtonContinueDraft(true)
          }
          this.loading.loadingApprovalProcurement = false
        }
      } else {
        this.loading.loadingApprovalProcurement = false
      }
      this.loading.loadingProjectLog = false
    }
  },
  destroyed() {
    this.$store.dispatch('procurementApproval/resetStateProcurement')
    clearInterval(this.counterInterval)
		this.$store.commit('projectLog/SET_COUNTER_INTERVAL', null)
  },
  components:{
    CreateProcurementApproval,
		ProcurementApprovalDetails,
    ModalConfirm,
    ChangeApprover,
    EditProcurementApproval,
    ApprovalProcurementLoading,
  }
}
</script>

<style scoped>
.height-custom {
	min-height: 30rem;
}
</style>