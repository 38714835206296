<template>
  <div class="flex" :class="projectDetailContainerWidth < 640 ? 'flex-col gap-3' : 'flex-row gap-0'">
    <div class="flex items-center mr-6 text-base font-medium text-gray">Need to create LOI?</div>
    <div class="flex justify-center gap-3" >
      <button-gista type="secondary" color="primary" customClass="w-full sm:w-28 sm:mr-3.5" @click="click(false)">
        NO
      </button-gista>
      <button-gista color="primary"
        customClass="w-full sm:w-28"
        @click="click(true)">YES</button-gista>
    </div>
  </div>
</template>
<script>
export default {
  name: "NeedToCreateLoi",
  data() {
    return {
      selected: this.value
    }
  },
  watch: {
    value() {
      this.selected = this.value 
    }
  },
  computed:{
    projectDetailContainerWidth() {
      return this.$store.state.width.projectDetailContainerWidth
    },
  },
  methods: {
    click(param) {
      this.$emit('click', param)
    },
  }
}
</script>