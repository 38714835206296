<template>
  <div>
    <gista-modal
      :value="isOpenExport"
      width="8/12"
			scrollable
			@input="toggle"
      customClass="bg-white mt-6 mb-8 max-w-7xl"
      >
      <div>
        <div class="flex justify-center p-6">
          <h3 class="text-xl font-semibold text-gray">Export</h3>
        </div>
        <div class="flex justify-center mb-6 text-center">
          <p class="text-base font-medium text-gray">Negotiation details will be shown as .xls file within the selected offer</p>
        </div>
        <div class="flex flex-wrap justify-center px-6">
          <div class="mb-6 mr-24">
            <h4 class="text-base font-medium text-gray">Offer<span class="text-sm text-error">*</span></h4>
            <div class="grid">
              <gista-checkbox v-for="n,i in exportMenu?.offers" :key="i" class="text-gray" :modelValue="n.negotiationId" @change="changeOffer(n.negotiationId)">{{n?.negotiationStatus}}</gista-checkbox>
            </div>
          </div>
          <div class="mb-6">
            <h4 class="text-base font-medium text-gray">Columns<span class="text-sm text-error">*</span></h4>
            <div class="flex flex-wrap">
              <div class="grid grid-flow-col grid-rows-6 gap-x-4">
                <gista-checkbox v-for="n,i in exportMenu?.options" :key="i" class="text-gray" :modelValue="n.value" :trueValue="n.value" @change="changeColumn(n.value)">{{ n?.label }}</gista-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-center mb-6">
          <button-gista color="success" customClass="w-60" @click="clickExport" :disabled="disabledExport"><span class="text-sm">Export</span></button-gista>
        </div>
      </div>
    </gista-modal>
  </div>
</template>

<script>
export default {
  name: 'ExportToExcel',
  props: ['isOpenExport'],
  data() {
    return {
      negotiationIds: [],
      options: []
    }
  },
  computed: {
    vendorDetail() {
      return this.$store.state.negotiation.vendorDetail
    },
    negotiationDetails() {
			return this.$store.state.negotiation.negotiationDetails
		},
    exportMenu() {
      return this.$store.state.negotiation.exportMenu
    },
    disabledExport() {
      return this.negotiationIds?.length === 0 || this.options?.length === 0
    },
    modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    },
  },
  methods: {
    toggle() {
      this.$emit('toggleExport')
    },
    changeOffer(negotiationId) {
      if (this.negotiationIds.includes(negotiationId)) {
        this.negotiationIds = this.negotiationIds.filter(e => e !== negotiationId )
      } else {
        this.negotiationIds = [ ...this.negotiationIds, negotiationId ]
      }
    },
    changeColumn(value) {
      console.log(value);
      if (this.options.includes(value)) {
        this.options = this.options.filter(e => e !== value )
      } else {
        this.options = [ ...this.options, value ]
      }
      console.log(this.options);
    },
    async clickExport() {
      try {
        this.modal.modalLoading = true
        const dataPayload = {
          negotiationIds: this.negotiationIds,
          options: this.options,
        }
        const response = await this.$store.dispatch('negotiation/postNegotiationDetailsExportExcel', dataPayload)
        if (response?.status < 300 && response?.status >= 200) {
          this.modal.modalLoading = false
        } else {
          this.$store.dispatch('modal/throwError', { response })
        }
      } catch (error) {
        console.log(error);
        this.modal.modalLoading = false
        this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
      }
    }
  },
  async mounted() {
    await this.$store.dispatch('negotiation/getNegotiationExportMenu', { vendorCandidateId: this.negotiationDetails.vendorCandidateId })
    this.options = this.exportMenu?.options?.map(e => e.value)
  }
}
</script>