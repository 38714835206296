<template>
	<div>
		<ModalInfo :modalInfo="modalInfo.criticalApplication"/>
		<ModalInfo :modalInfo="modalInfo.applicationComplexity"/>
		<ModalInfo :modalInfo="modalInfo.userImpact"/>
		<ModalInfo :modalInfo="modalInfo.totalCost"/>
		<ModalInfo :modalInfo="modalInfo.availabilityOfSubstituteProducts"/>
		<ModalInfo :modalInfo="modalInfo.rebuildProducts"/>
		<ModalInfo :modalInfo="modalInfo.vendorServiceLongevity"/>
		<ModalInfo :modalInfo="modalInfo.vendorPortofolio"/>
		<ModalInfo :modalInfo="modalInfo.affiliateWithBCA"/>
	</div>
</template>

<script>
import ModalInfo from './ModalInfo.vue'
export default {
	name: "InfoEscrow",
  props: ['modalInfo'],
  components: {
    ModalInfo,
  }
}
</script>