<template>
	<div class="w-full" v-if="eliminatedVendorExist">
		<div class="w-full mt-6">
      <div>
        <div class="flex items-center mb-6">
          <span class="mr-6 icon-slash"></span>
          <h4 class="text-base font-semibold text-gray-dark">ELIMINATED VENDORS</h4>
        </div>
        <div v-for="(n, i) in qualifications" :key="i" >
          <div v-if="showEliminateVendor(i)" class="mb-4 border border-gray-lightest bg-gray-lightest rounded-xl">
            <div class="flex flex-wrap px-4 py-3.5 w-full">
              <div class="flex-auto pr-3" :class="{'w-1/4':projectDetailContainerWidth > 800}">
                <div class="flex flex-wrap">
                  <div class="flex items-center mb-1">
                    <h6 class="mr-1 cursor-pointer text-tertiary hover:text-tertiary-dark" @click="openVendorDetail(n.vendorId, n.isNewVendor)">Vendor #{{ n?.sequence }}</h6>
                    <p class="icon-chevron-right text-tertiary"></p>
                  </div>
                  <div v-if="n.isNewVendor"
                    class="flex px-4 py-1 mb-1 text-xs rounded-lg bg-success-lightest text-success">
                    <h6>New Vendor</h6>
                  </div>
                </div>
                <div class="mb-3">
                  <h5 class="font-semibold">{{ n?.vendorName }}</h5>
                  <p class="text-xs break-words text-gray-dark">{{ n?.vendorEmail }}</p>
                </div>
              </div>
              <div v-if="n.statusVendor === QUALIFICATION_ELIMINATED || n.statusVendor === PRA_QUALIFICATION_ELIMINATED" class="flex-auto" :class="{'w-2/4':projectDetailContainerWidth > 800}">
                <div class="w-full h-full" :class="{'px-3 border-l border-r border-gray-light':projectDetailContainerWidth > 800}" >
                  <div class="w-full h-full">
                    <div class="">
                      <p class="text-sm font-medium text-gray ">Eliminated Reason:</p>
                    </div>
                    <div class="pt-2">
                      <div v-if="n?.disqualifiedReason?.length > 200">
                        <p class="text-sm text-gray">{{n?.disqualifiedReason.slice(0,200)}}... </p>
                        <p class="text-sm cursor-pointer text-secondary" @click="readMore(n?.disqualifiedReason)">Read More</p>
                      </div>
                      <div v-else>
                        <p class="text-sm text-gray">{{n?.disqualifiedReason}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="n.statusVendor === QUALIFICATION_ELIMINATED || n.statusVendor === PRA_QUALIFICATION_ELIMINATED" class="flex flex-row flex-auto"
                :class="{'w-1/4 flex-col justify-center items-center px-3':projectDetailContainerWidth > 800}">
                <div v-if="n?.disqualifiedFileAddress" class="flex" :class="{'justify-center items-center':projectDetailContainerWidth > 800}" >
                  <span class="mr-2 icon-file"></span>
                  <p class="text-sm underline cursor-pointer text-secondary" @click="viewAttachment(n?.disqualifiedFileName, n?.disqualifiedFileDocumentNo)">View Attachment</p>
                </div>
              </div>
            </div>
            <div class="px-4 pb-4" v-if="n.localPartners?.length > 0">
              <div v-for="(local, j) in n.localPartners" :key="j" >
                <div v-if="local.statusVendor === PRA_QUALIFICATION_ELIMINATED || local.statusVendor === QUALIFICATION_ELIMINATED" class="flex flex-wrap w-full py-3.5 border-l border-gray-light ">
                  <div class="flex-auto px-4" :class="projectDetailContainerWidth > 800? 'w-1/4': 'w-full'">
                    <div class="flex flex-wrap">
                      <div class="flex items-center mb-1 mr-1">
                        <h6 class="cursor-pointer text-tertiary hover:text-tertiary-dark" @click="openVendorDetail(local.vendorId, local.isNewVendor)">Local Partner #{{ j + 1 }}</h6>
                        <p class="icon-chevron-right text-tertiary"></p>
                      </div>
                      <div v-if="local.isNewVendor"
                        class="flex px-4 py-1 mb-1 mr-1 text-xs rounded-lg bg-success-lightest text-success">
                        <h6>New Vendor</h6>
                      </div>
                    </div>
                    <div>
                      <h5 class="font-semibold">{{ local?.vendorName }}</h5>
                      <p class="text-xs break-words text-gray-dark">{{ local?.vendorEmail }}</p>
                    </div>
                  </div>
                  <div class="flex flex-auto" :class="projectDetailContainerWidth > 800? 'w-2/4 border-l border-r border-gray-light': 'w-full'">
                    <div v-if="statusLocalPartnerEliminated(i, j) && n?.localPartners?.length > 0" class="w-full" :class="projectDetailContainerWidth > 800? 'px-3': 'pl-4'">
                      <div class="flex w-full">
                        <div class="w-full mt-1 mb-6">
                          <div class="w-full h-full">
                            <div class="">
                              <p class="text-sm font-medium text-gray">Eliminated Reason:</p>
                            </div>
                            <div class="pt-2">
                              <p v-if="local?.disqualifiedReason.length > 100" class="text-sm break-words whitespace-pre-wrap text-gray">{{local?.disqualifiedReason.slice(0,100)}}... <span class="cursor-pointer text-secondary" @click="readMore(local?.disqualifiedReason)">Read More</span></p>
                              <p v-else class="text-sm break-words whitespace-pre-wrap text-gray">{{local?.disqualifiedReason}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div 
                    class="flex flex-wrap items-center justify-center flex-auto px-3"
                    :class="projectDetailContainerWidth > 800? 'w-1/4': 'w-full'"
                    >
                    <div v-if="statusLocalPartnerEliminated(i, j) && n?.localPartners?.length > 0" class="flex flex-row flex-auto"
                      :class="{'w-1/4 flex-col justify-center items-center px-3 truncate':projectDetailContainerWidth > 800}">
                      <div v-if="local?.disqualifiedFileAddress" class="flex" :class="{'justify-center items-center':projectDetailContainerWidth > 800}" >
                        <span class="mr-2 icon-file"></span>
                        <p class="text-sm underline cursor-pointer text-secondary" @click="viewAttachment(local?.disqualifiedFileName, local?.disqualifiedFileDocumentNo)">View Attachment</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <GistaModal 
					:value="modalVendorDetail"
					width="2/3"
					scrollable
					@input="closeModalVendorDetail"
					customClass="bg-white top-0 bottom-0 mt-6 mb-8 max-w-7xl">
					<VendorDetail @closeModal="closeModalVendorDetail" :vendor="modalVendorProps" :projectId="proposalInformation.projectId" ></VendorDetail>
				</GistaModal>
      </div>
		</div>
    <ModalReadMore @click="closeModal" :isOpen="readMoreShow" :text="reason"/>
	</div>
</template>
<script>
import { QUALIFICATION_ELIMINATED, PRA_QUALIFICATION_ELIMINATED, SCORING_DECISION_ELIMINATED } from '@/core/constant/qualification.js'
import VendorDetail from '@/core/components/vendors/VendorDetail'
import ModalReadMore from '@/core/components/modal/ReadMore.vue'
export default {
	name: "QualificationElimindated",
	data() {
		return {
      reason: '',
      readMoreShow: false,
      modalVendorDetail: false,
			modalVendorProps: {
				vendorId: '',
				isNewVendor: true,
			},
            QUALIFICATION_ELIMINATED,
            PRA_QUALIFICATION_ELIMINATED
		}
	},
	computed: {
		qualifications: {
			get() {
				return this.$store.state.qualification.qualifications
			},
			set(value) {
				this.$store.commit('qualification/SET_QUALIFICATOINS', value)
			}
		},
    proposalInformation() {
      return this.$store.state.projectDetail.proposalInformation
    },
		//menu send rfi
		menu() {
			return this.$store.getters['dashboard/menuSendRFI']
		},
    projectDetailContainerWidth() {
			return this.$store.state.width.projectDetailContainerWidth
		},
		eliminatedVendorExist() {
			const eliminateVendors = this.qualifications.filter(e => e.statusVendor === QUALIFICATION_ELIMINATED || e.statusVendor === PRA_QUALIFICATION_ELIMINATED)
			let eliminateLocalVendors = []
            this.qualifications.forEach(el => {
                eliminateLocalVendors = [...eliminateLocalVendors, ...el.localPartners?.filter(e => e.statusVendor === QUALIFICATION_ELIMINATED || e.statusVendor === PRA_QUALIFICATION_ELIMINATED)]
            })
      
			return eliminateVendors.length > 0 || eliminateLocalVendors.length > 0
		},

	},
	methods: {
    openVendorDetail(vendorId, isNewVendor) {
			this.modalVendorProps = { vendorId: vendorId, isNewVendor: isNewVendor }
			this.modalVendorDetail = !this.modalVendorDetail
		},
		closeModalVendorDetail() {
			this.modalVendorDetail = !this.modalVendorDetail
			this.$store.dispatch('qualification/getQualifications', this.$route.params.projectId)
		},
    showEliminateVendor(i) {
      const checkParentVendor = (this.qualifications[i].statusVendor === QUALIFICATION_ELIMINATED || this.qualifications[i].statusVendor === PRA_QUALIFICATION_ELIMINATED) && this.qualifications[i].statusVendor !== SCORING_DECISION_ELIMINATED
      const checkEliminatedLocalPartner = this.qualifications[i].localPartners?.some((el) => el.statusVendor === PRA_QUALIFICATION_ELIMINATED || el.statusVendor === QUALIFICATION_ELIMINATED)
      return checkParentVendor || checkEliminatedLocalPartner
    },
    statusLocalPartnerEliminated(i, j) {
			return this.qualifications[i].localPartners[j]?.statusVendor === QUALIFICATION_ELIMINATED || this.qualifications[i].localPartners[j]?.statusVendor === PRA_QUALIFICATION_ELIMINATED
		},
		async viewAttachment(filename, documentNo) {
      this.$store.dispatch('file/viewAttachment', { filename, documentNo })
		},
    closeModal() {
      this.readMoreShow = false
    },
    readMore(reason) {
      this.reason = reason
      this.readMoreShow = true
    }
	},
	components: { 
    VendorDetail,
    ModalReadMore
   }
}

</script>