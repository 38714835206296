<template>
  <div class="p-3.5 bg-white rounded-2xl shadow-gray-sm" :class="padding">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    padding: {
      type: String,
      default: 'p-3.5'
    },
  }
};
</script>
