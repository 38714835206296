<template>
  <div class="" v-if="data !== null">
    <div class="w-full overflow-auto" :class="`${heightModal? 'min-h-custom': 'max-h-custom'}`" :style="`height: ${heightModal - heightModalTitle - 96}px;`">
      <div class="w-full">
        <table class="relative w-full border-collapse" id="negotiationTable" aria-describedby="negotiationTable">
          <thead class="sticky top-0 z-20 h-10 py-1 bg-white">
            <tr>
              <th scope="col" class="min-w-12">
                <GistaCheckbox :modelValue="allSelected" :disabled="readOnly" class="h-10 px-2 border-b-2 text-gray text-gray-darkest border-primary"></GistaCheckbox>
              </th>
              <th scope="col" class="min-w-12">
                <h5 class="flex items-center h-10 p-2 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">No.</h5>
              </th>
              <th scope="col" class="min-w-64">
                <h5 class="flex items-center h-10 p-2 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">Product/Service</h5>
              </th>
              <th scope="col" class="min-w-64">
                <h5 class="flex items-center h-10 p-2 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">Sub Product</h5>
              </th>
              <th scope="col" class="min-w-20">
                <h5 class="flex items-center h-10 p-2 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">Qty</h5>
              </th>
              <th scope="col" class="min-w-24">
                <h5 class="flex items-center h-10 p-2 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">UoM</h5>
              </th>
              <th scope="col" class="min-w-48">
                <h5 class="flex items-center h-10 p-2 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">Initial Price</h5>
              </th>
              <th scope="col" class="min-w-48">
                <h5 class="flex items-center h-10 p-2 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">Final Price</h5>
              </th>
            </tr>
          </thead>
          <tbody >
            <template>
              <tr v-for="item, i in data?.resultItemList" :key="i" class="h-10" :class="i % 2 === 0 ? 'bg-white' : 'bg-primary-lightest bg-opacity-25'">
                  <td class="align-top">
                    <GistaCheckbox :modelValue="item.isSelected" :disabled="readOnly" class="px-2 text-gray"></GistaCheckbox>
                  </td>
                  <td class="align-top">
                    <h5 class="p-2 text-sm text-gray-darkest">{{i+1}}</h5>
                  </td>
                  <td class="align-top">
                    <h5 v-if="heightModal" class="p-2 text-sm text-gray-darkest">{{item?.productService}}</h5>
                    <h5 v-else-if="item.productService?.length > 34" class="p-2 text-sm break-words whitespace-pre-wrap text-gray-darkest">{{item.productService.slice(0,20)}}... <span class="cursor-pointer text-secondary" @click="readMore(item.productService)">Read More</span></h5>
										<h5 v-else class="p-2 text-sm break-words whitespace-pre-wrap text-gray-darkest">{{item?.productService}}</h5>
                  </td>
                  <td class="align-top">
                    <h5 v-if="heightModal" class="p-2 text-sm text-gray-darkest">{{item?.subProduct}}</h5>
                    <h5 v-else-if="item.subProduct?.length > 34" class="p-2 text-sm break-words whitespace-pre-wrap text-gray-darkest">{{item.subProduct.slice(0,20)}}... <span class="cursor-pointer text-secondary" @click="readMore(item.subProduct)">Read More</span></h5>
										<h5 v-else class="p-2 text-sm break-words whitespace-pre-wrap text-gray-darkest">{{item?.subProduct}}</h5>
                  </td>
                  <td class="align-top">
                    <h5 class="p-2 text-sm text-right text-gray-darkest">{{ item?.qty }}</h5>
                  </td>
                  <td class="align-top">
                    <h5 v-if="heightModal" class="p-2 text-sm text-gray-darkest">{{item?.uom}}</h5>
                    <h5 v-else-if="item.uom?.length > 34" class="p-2 text-sm break-words whitespace-pre-wrap text-gray-darkest">{{item.uom.slice(0,20)}}... <span class="cursor-pointer text-secondary" @click="readMore(item.uom)">Read More</span></h5>
										<h5 v-else class="p-2 text-sm break-words whitespace-pre-wrap text-gray-darkest">{{item?.uom}}</h5>
                  </td>
                  <td class="align-top">
                    <h5 class="p-2 text-sm text-right text-gray-darkest">{{ formatCurrency(item?.initialPrice, data?.currency, null, 0) }}</h5>
                  </td>
                  <td class="align-top">
                    <h5 class="p-2 text-sm text-right text-gray-darkest">{{ formatCurrency(item?.finalPrice, data?.currency, null, 0) }}</h5>
                  </td>
                </tr>
            </template>
          </tbody>
          <tbody class="sticky bottom-0 bg-gradient-to-t from-primary-lightest to-white">
            <tr>
              <td></td>
              <td class="px-2"><span class="text-base icon-corner-down-right"></span></td>
              <td colspan="7">
                <div class="flex items-center h-10 border-b border-primary">
                  <div class="flex">
                    <p class="flex-auto w-56 p-2 text-sm font-medium text-gray-darkest">Total Price (Initial Price)</p>
                    <p class="flex-1 text-sm font-medium text-gray-darkest">:</p>
                  </div>
                  <p class="pl-4 text-sm font-medium text-primary-darkest">{{ formatCurrency(data?.initialPrice, data?.currency, null, 0) }}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2"></td>
              <td colspan="7">
                <div class="flex items-center h-10 border-b border-primary">
                  <div class="flex">
                    <p class="flex-auto w-56 p-2 text-sm font-medium text-gray-darkest">Total Price (Final Price)</p>
                    <p class="flex-1 text-sm font-medium text-gray-darkest">:</p>
                  </div>
                  <p class="pl-4 text-sm font-medium text-primary-darkest">{{formatCurrency(data?.finalPrice, data?.currency, null, 0)}}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2"></td>
              <td colspan="7">
                <div class="flex items-center h-10 border-b border-primary">
                  <div class="flex">
                    <p class="flex-auto w-56 p-2 text-sm font-medium text-gray-darkest">VAT (%)</p>
                    <p class="flex-1 text-sm font-medium text-gray-darkest">:</p>
                  </div>
                  <p class="pl-4 text-sm font-medium text-primary-darkest">{{ data?.vat }}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2"></td>
              <td colspan="7">
                <div class="flex items-center h-10 border-b border-primary">
                  <div class="flex">
                    <p class="flex-auto w-56 p-2 text-sm font-medium text-gray-darkest">VAT Amount</p>
                    <p class="flex-1 text-sm font-medium text-gray-darkest">:</p>
                  </div>
                  <p class="pl-4 text-sm font-medium text-primary-darkest">{{ formatCurrency(data?.vatAmount, data?.currency, null, 0) }}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2"></td>
              <td colspan="7">
                <div class="flex items-center h-10 border-b border-primary">
                  <div class="flex">
                    <p class="flex-auto w-56 p-2 text-sm font-medium text-gray-darkest">Currency</p>
                    <p class="flex-1 text-sm font-medium text-gray-darkest">:</p>
                  </div>
                  <p class="pl-4 text-sm font-medium text-primary-darkest">{{ data?.currency }}</p>
                </div>
              </td>
            </tr>
            <tr v-if="data?.currency?.toLowerCase() !== 'idr'">
              <td colspan="2"></td>
              <td colspan="7">
                <div class="flex items-center h-10 border-b border-primary">
                  <div class="flex items-center">
                    <h5 class="flex-auto w-56 p-2 text-sm font-medium text-gray-darkest">Exchange Rate</h5>
                    <p class="flex-1 text-sm font-medium text-primary-darkest">:</p>
                  </div>
                  <div>
                    <p class="pl-4 text-sm font-medium text-primary-darkest">{{formatCurrency(data?.exchangeRate, 'idr', null, 0)}}</p>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="data?.currency?.toLowerCase() !== 'idr'">
              <td colspan="2"></td>
              <td colspan="7">
                <div class="flex items-center h-10 border-b border-primary">
                  <div class="flex">
                    <h5 class="flex-auto w-56 p-2 text-sm text-gray-darkest">Subtotal</h5>
                    <p class="flex-1 text-sm font-medium text-gray-darkest">:</p>
                  </div>
                  <div>
                    <p class="pl-4 text-sm font-medium text-primary-darkest">{{ formatCurrency(data?.subTotal, data?.currency, null, 0) }}</p>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" >
                <div class="h-10 border-b-2 border-primary"></div>
              </td>
              <td colspan="7">
                <div class="flex items-center h-10 border-b-2 border-primary">
                  <div class="flex">
                    <h5 class="flex-auto w-56 p-2 text-sm text-gray-darkest">Grand Total in IDR:</h5>
                    <p class="flex-1 text-sm font-medium text-gray-darkest">:</p>
                  </div>
                  <p class="pl-4 text-sm font-medium text-primary-darkest">{{ formatCurrency(data?.grandTotalIdr, 'idr', null, 0) }}</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
			</div>
		</div>
    <ModalReadMore @click="closeModal" :isOpen="readMoreShow" :text="notes" />
	</div>
</template>

<script>
import { vatIncludedChecker } from '@/core/components/helpers'
import { formatCurrency } from '@/core/components/helpers/formatRupiah'
import ModalReadMore from '@/core/components/modal/ReadMore.vue'

export default {
  name: 'ResultTable',
  props: ['usd', 'index', 'indexJ', 'readOnly', 'heightModal', 'heightModalTitle'],
  data() {
    return {
      allSelected: false,
      readMoreShow: false,
			notes: '',
    }
  },
  computed: {
    logApproval() {
      return this.$store.state.procurementApproval.logApproval
    },
    isRejected() {
      return this.logApproval?.logDetails?.some(e => e.approvalStatus === 'PROCUREMENT_REJECTED')
    },
    data() {
      return this.draftSktLoi?.loiVendors?.[this.index].negotiationResultDetails
    },
    draftSktLoi: {
      get() {
        return this.$store.state.sktLoi.draftSktLoi
      },
      set(value) {
        this.$store.commit('sktLoi/SET_DRAFT_SKT_LOI', value)
      }
    },
    
  },
  methods: {
    formatCurrency,
    vatIncludedChecker,
    readMore(notes) {
			this.notes = notes
			this.readMoreShow = true
		},
    closeModal() {
			this.readMoreShow = false
		},
  },
  mounted() {
    this.$emit('set-height')
  },
  components: {
    ModalReadMore
  }
}
</script>

<style scoped>
.max-h-custom {
	max-height: 50rem;
}
.min-w-12 {
	min-width: 3rem;
}
.min-w-20 {
  min-width: 5rem;
}
.min-w-24 {
  min-width: 6rem;
}
.min-w-48 {
	min-width: 12rem;
}
.min-w-64 {
	min-width: 16rem;
}
.min-h-custom {
  min-height: 35rem;
}
#negotiationTable {
	border-collapse: collapse;
}
#negotiationTable th {
	padding: 0; 
	margin: 0;
}

</style>
