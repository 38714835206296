<template>
  <div>
    <div class="flex mt-6">
      <div class="w-full animate-pulse">
        <div class="flex">
          <div class="mr-6">
            <span class="icon-arrow-left text-gray text-2xl font-bold cursor-pointer"
              @click="backToVendorList"></span>
          </div>
          <div class="h-12 bg-gray-lightest rounded w-full mb-3.5"></div>
        </div>
        <div class="h-20 bg-gray-lightest rounded-xl w-full mb-3.5"></div>
        <div class="h-20 bg-gray-lightest rounded-xl w-full mb-3.5"></div>
        <div class="h-12 bg-gray-lightest rounded w-full mb-3.5"></div>
        <div class="h-40 bg-gray-lightest rounded w-full mb-3.5"></div>
        <div class="h-12 bg-gray-lightest rounded-xl w-full mb-3.5"></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
    name: "NegotiationDetailLoading",
    methods: {
      backToVendorList() {
        this.$store.commit('negotiation/SET_VENDOR_DETAIL', null)
        this.$router.push({ name: 'projectNegotiation' }).catch(() => ({}))
      },
    }
}
</script>
<style scoped>
.max-w-40 {
  max-width: 20rem;
  width: 20rem;
}
</style>