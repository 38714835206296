<template>
	<div class="p-0">
		<ButtonGista v-if="menuScoringTeknis?.canUpdate"
			@click="toggle"
			:disabled="proposalInformation?.isCanceled"
			customClass="w-full sm:w-max"
			type="primary">
			Edit Criteria
		</ButtonGista>

		<GistaModal :value="isOpen"
			width="2/3"
			@input="toggle"
			scrollable
			customClass="bg-white top-0 bottom-0 mt-6 mb-8 max-w-7xl">
			<ValidationObserver ref="form">
				<div class="px-6 py-6">
					<div class="flex flex-col items-center mb-6">
						<h4 class="text-3xl font-semibold">Edit Criteria</h4>
					</div>
					<div 
						class="grid w-full grid-cols-1 gap-6 my-6">
						
							<Card  v-for="(score, i) in scoringTeknisCriteria" :key="i">
								<div class="flex justify-between h-16">
									<div class="flex-wrap">
										<h5 class="text-gray">{{ score.name }} <span class="text-error">*</span></h5>
										
									</div>
									<div class="">
										<div class="flex items-center justify-between">
											<div class="flex items-center justify-center text-white rounded-lg w-9 h-9"
												:class="`${score.scoreTotal > score.bobot ? 'bg-error' : score.scoreTotal < score.bobot ? 'bg-warning' : 'bg-success'}`">
												<p>{{ score.scoreTotal }}</p>
											</div>
											<p class="text-gray">&nbsp; / Bobot: &nbsp;</p>
											<div class="flex items-center justify-center rounded-lg bg-gray-lightest w-9 h-9 text-gray">
												<p>{{ score.bobot }}</p>
											</div>
										</div>
										
										<p v-if="errorScoringInformation[i].scoreTotal?.value"
											class="mt-1 text-xs text-error">{{ errorScoringInformation[i].scoreTotal.message }}</p>
									</div>
								</div>
								<div v-for="(e, j) in score.rfpScoringTeknis"
									:key="j"
									class="mb-4">
									<div class="flex justify-between">
										<div class="w-4/5 mr-3.5">
											<ValidationProvider :name="`name${i}${j}`"
												:rules="`${j === 0?'required':''}`"
												v-slot="{ errors }">
												<InputSolutip type="text"
													:label="`Sub-criteria #${j + 1}`"
													size="large"
													:mandatory="j === 0"
													v-model="e.name"
													@blur="blurSubCriteria(i, j)"
													:error="!!errors[0] || errorScoringInformation[i][keyScoringTeknis[i]][j].value">
													<template #message>
														<p>{{ errors[0] || errorScoringInformation[i][keyScoringTeknis[i]][j].message }}</p>
													</template>
												</InputSolutip>
											</ValidationProvider>
										</div>
										<div class="flex">
											<ValidationProvider :name="`bobot${i}${j}`"
												:rules="`${e.name? 'required|': ''}min_one:1`"
												v-slot="{ errors }">
												<div class="w-full">
													<InputSolutip type="text"
														label="score"
														size="large"
														v-model="e.bobot"
														:mandatory="j === 0"
														@keypress="validationNumber"
														:error="!!errors[0] || (j === 0 && errorScoringInformation[i].scoreTotal.value)">
														<template #message>
															<p>{{ errors[0] }}</p>
														</template>
													</InputSolutip>
												</div>
											</ValidationProvider>
											<div v-if="score.rfpScoringTeknis.length > 1" class="flex items-center">
												<p class="ml-3 text-xl cursor-pointer icon-x-circle text-error" @click="removeSubCriteria(i, j)" ></p>
											</div>
										</div>
									</div>
								</div>
								<div class="flex justify-center w-full">
									<ButtonGista type="primary"
										color="tertiary"
										expanded
										size="small"
										@click="addSubCriteria(i)">
										Add Sub-Criteria
									</ButtonGista>
								</div>
							</Card>
					</div>
	
					<div class="flex justify-end">
	
						<ButtonGista 
							@click="clickSaveChanges"
							customClass="w-full sm:w-max"
							>
							Save Changes
						</ButtonGista>
	
					</div>
				</div>
			</ValidationObserver>
		</GistaModal>
	</div>
</template>
<script>
import Card from '@/core/components/custom/Card.vue'
import { MESSAGE_POPUP_VALIDATION_SUBMIT, MESSAGE_CANNOT_SAME_VALUE } from '@/core/constant/errorMessage.js'
import InputSolutip from '@/core/components/custom/Input.vue'
import { MESSAGE_SUCCESS_EDIT_SCORING_CRITERIA } from '@/core/constant/successMessage'


export default {
	name: 'ScoringEdit',
	data() {
		return {
			isOpen: false,
			isSuccess: false,
			errorScoringInformation: [
				{
					scoreTotal: { value: false, message: 'Total Score Harus Sama' },
					vendorData: [
						{ value: false, message: '' },
						{ value: false, message: '' },
						{ value: false, message: '' },
					],
				},
				{
					scoreTotal: { value: false, message: 'Total Score Harus Sama' },
					solution: [
						{ value: false, message: '' },
						{ value: false, message: '' },
						{ value: false, message: '' },
					],
				},
				{
					scoreTotal: { value: false, message: 'Total Score Harus Sama' },
					support: [
						{ value: false, message: '' },
						{ value: false, message: '' },
						{ value: false, message: '' },
					]
				},
			],
			keyScoringTeknis: ['vendorData', 'solution', 'support'],
			bobotPriceInitial: 0,
			totalBobotInitial: 0
		}
	},
	computed: {
		//menu scoring - teknis
		menuScoringTeknis() {
			return this.$store.getters['dashboard/menuScoringTeknis']
		},
		proposalInformation() {
			return this.$store.state.projectDetail.proposalInformation
		},
		scoringTeknisCriteria() {
      return this.$store.state.projectDetail.scoringTeknisCriteria.filter((_,i) => i !== 3)
    },
		//computed one by one rfpScoringTeknis in scoring teknis criteria for update error state
		rfpScoringTeknis1() {
			return this.$store.state.projectDetail.scoringTeknisCriteria[0].rfpScoringTeknis
		},
		rfpScoringTeknis2() {
			return this.$store.state.projectDetail.scoringTeknisCriteria[1].rfpScoringTeknis
		},
		rfpScoringTeknis3() {
			return this.$store.state.projectDetail.scoringTeknisCriteria[2].rfpScoringTeknis
		},
		scoringTeknisCriteria4() {
			return this.$store.state.projectDetail.scoringTeknisCriteria[3]
		},
		modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    }
	},
	watch: {
		scoringTeknisCriteria: {
			handler() {
				this.checkScoreTotal()
			},
			deep: true
		},
		//watch scoringteknis change in rfpScoringTeknis if added or removed custom error state will adjusted
		rfpScoringTeknis1() {
			this.errorScoringInformation[0]['vendorData'] = this.rfpScoringTeknis1.map((e,i) => ({ value: this.errorScoringInformation[0]['vendorData'][i]?.value || false, message: this.errorScoringInformation[0]['vendorData'][i]?.message || '' }))
			this.checkDuplicateValue(0)
			this.checkScoreTotal()
		},
		rfpScoringTeknis2() {
			this.errorScoringInformation[1]['solution'] = this.rfpScoringTeknis2.map((e,i) => ({ value: this.errorScoringInformation[1]['solution'][i]?.value || false, message: this.errorScoringInformation[1]['solution'][i]?.message || '' }))
			this.checkDuplicateValue(1)
			this.checkScoreTotal()
		},
		rfpScoringTeknis3() {
			this.errorScoringInformation[2]['support'] = this.rfpScoringTeknis3.map((e,i) => ({ value: this.errorScoringInformation[2]['support'][i]?.value || false, message: this.errorScoringInformation[2]['support'][i]?.message || '' }))
			this.checkDuplicateValue(2)
			this.checkScoreTotal()
		},

	},
	methods: {
		toggle() {
			this.isOpen = !this.isOpen
			this.getProjectScoringInformation()
		},
		toggleSuccess() {
			this.isOpen = !this.isOpen
			this.isSuccess = !this.isSuccess
		},
		addSubCriteria(index) {
			this.scoringTeknisCriteria[index].rfpScoringTeknis = [
				...this.scoringTeknisCriteria[index].rfpScoringTeknis, {
					bobot: '',
					name: "",
					rfpScoringTeknisId: 0,
					scoringTeknisCriteriaId: index+1,
					sequence: this.scoringTeknisCriteria[index].sequence,
					isDeleted: false,
				}]
		},
		removeSubCriteria(indexScoring, indexSubCriteria) {
			this.scoringTeknisCriteria[indexScoring].rfpScoringTeknis = this.scoringTeknisCriteria[indexScoring].rfpScoringTeknis.filter((e, i) => i !== indexSubCriteria)
		},
		async clickSaveChanges() {
			try {
				//show loading
				this.modal.modalLoading = true
				//validate with vee-validate
				const success = await this.$refs.form.validate()
				const errorScoringInformation = await this.checkErrorScoringInformation()
				if (!success || !errorScoringInformation) {
					//loading out
					this.modal.modalLoading = false
					//show modal error validate scoring edit
					this.modal.modalError = { value: true, message: MESSAGE_POPUP_VALIDATION_SUBMIT }
					return
			}
				const response = await this.$store.dispatch('projectDetail/putProjectScoringInformation', this.$route.params.projectId)
				if (response?.status < 300 && response?.status >= 200) {
					//loading out
					this.modal.modalLoading = false
					//show modal success scoring edit
					this.modal.modalSuccessSaved = { value: true, title: MESSAGE_SUCCESS_EDIT_SCORING_CRITERIA }
					this.isOpen = false
					this.$store.dispatch('projectDetail/getScoringVendorTeknisTree', this.$route.params.projectId)
					this.$store.dispatch('projectDetail/getProjectScoringInformation', this.$route.params.projectId)
				} else {
					this.$store.dispatch('modal/throwError', { response })
				}
			} catch (error) {
				console.log(error);
				//loading out when error
				this.modal.modalLoading = false
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
			}
		},
		blurSubCriteria(index) {
			this.checkDuplicateValue(index)
		},
		checkDuplicateValue(index) {
			const key = ['vendorData', 'solution', 'support', 'price']
			const uniqueDuplicateElements = this.uniqueDuplicateElements(index)
			if (uniqueDuplicateElements.length > 0) {
				this.setErrorMessageDuplicateValue(uniqueDuplicateElements, index, key)
			} else {
				this.errorScoringInformation[index][key[index]] = this.errorScoringInformation[index][key[index]].map(() => ({ value: false, message: '' }))
			}
		},
		uniqueDuplicateElements(index) {
			const duplicateElements = this.scoringTeknisCriteria[index].rfpScoringTeknis.filter((item, i) => {
					return  this.scoringTeknisCriteria[index].rfpScoringTeknis.map(el => el.name).indexOf(item.name) !== i && item.name
				})
			return duplicateElements.filter((item, i) => duplicateElements.map(el => el.name).indexOf(item.name) === i)
		},
		setErrorMessageDuplicateValue(uniqueDuplicateElements, index, key) {
			let arrayFlag = []
			uniqueDuplicateElements.forEach((el) => {
				this.scoringTeknisCriteria[index].rfpScoringTeknis.forEach((item, i) => {
					if (el.name === item.name || arrayFlag.includes(i)) {
						this.errorScoringInformation[index][key[index]] = this.errorScoringInformation[index][key[index]].map((ele, idx) => {
							if (idx === i) {
								return { value: true, message: MESSAGE_CANNOT_SAME_VALUE }
							} else {
								return ele
							}
						})
						arrayFlag.push(i)
					} else {
						this.errorScoringInformation[index][key[index]] = this.errorScoringInformation[index][key[index]].map((ele, idx) => {
							if (idx === i) {
								return { value: false, message: '' }
							} else {
								return ele
							}
						}) 
					}
				})
			})
		},
		//custom validation error scoring teknis information
		async checkErrorScoringInformation() {
			const key = this.keyScoringTeknis
			//scanning to state error scoring teknis and give result of check error scoring teknis 
			const checkScoringInformation = this.errorScoringInformation.every((e, i) => {
				return e[key[i]].every(k => k.value === false) === true && e.scoreTotal.value === false
			})
			console.log(checkScoringInformation, 'check scoring information');
			return checkScoringInformation
		},

		checkScoreTotal() {
			this.scoringTeknisCriteria.forEach((e, i) => {
				this.scoringTeknisCriteria[i].scoreTotal = e.rfpScoringTeknis.reduce((a, b) => +a + +b.bobot, 0)
				if (this.scoringTeknisCriteria[i].scoreTotal !== this.scoringTeknisCriteria[i].bobot) {
					this.errorScoringInformation[i].scoreTotal.value = true
				} else {
					this.errorScoringInformation[i].scoreTotal.value = false
				}
			})
		},

		validationNumber(e) {
			let keyCode = e.keyCode;
			if (keyCode < 48 || keyCode > 57) {
				e.preventDefault();
			}
		},

		async getProjectScoringInformation() {
			await this.$store.dispatch('projectDetail/getProjectScoringInformation', this.$route.params.projectId)
			this.bobotPriceInitial = +this.$store.state.projectDetail.scoringTeknisCriteria[3].bobot
			this.totalBobotInitial = +this.$store.state.projectDetail.scoringTeknisCriteria.reduce((a,b) => +a + +b.bobot, 0)
			this.errorScoringInformation = this.scoringTeknisCriteria.map((e,i) => {
				return {
						scoreTotal: { value: false, message: 'Total Score Harus Sama' },
						[this.keyScoringTeknis[i]]: e.rfpScoringTeknis.map(() => ({ value: false, message: '' }))
				}
			})
		}

	},
	async mounted() {
		this.getProjectScoringInformation()
	},
	components: {
		Card,
		InputSolutip
	}
}

</script>