<template>
  <div class="flex">
    <div class="flex items-center mr-6 text-base font-medium text-gray gap-2.5">
      <span class="icon-x-circle"></span>
      LOI will not be created
      <div>
        <p class="text-base font-medium underline cursor-pointer text-primary" @click="click">Change</p>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  name: "LoiNotCreated",
  data() {
    return {
      selected: this.value
    }
  },
  watch: {
    value() {
      this.selected = this.value 
    }
  },
  computed:{
    projectDetailContainerWidth() {
      return this.$store.state.width.projectDetailContainerWidth
    },
  },
  methods: {
    click() {
      this.$emit('click')
    }
  }
}
</script>