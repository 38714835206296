<template>
	<div class="w-full">
		<div v-if="!loading">
			<div v-if="scoringProcessByOprGsitKp && !menuScoringDecision?.canUpdate" class="flex items-center justify-center height-custom">
				<div class="flex">
					<span class="icon-info flex items-center text-warning-darkest text-lg cursor-pointer text-gray hover:text-gray-dark"></span>
					<p class="font-medium text-warning-darkest pl-3.5">Proses Scoring sedang dilaksanakan oleh OPR GSIT/KP</p>
				</div>
			</div>
			<div v-else class="w-full">
				<div class="grid w-full gap-6" :class="`${projectDetailContainerWidth > 720? 'grid-cols-2': 'grid-cols-1'}`">
					<Card v-for="(n, i) in scoringVendorDecision" :key="i" class="flex justify-between w-full border border-gray-lightest">
						<div class="w-full" v-if="(n.isSubmittedDecision && !n.isRevisionScoringTechEscrow) || !showButtonScoring || n.statusVendor === SCORING_DECISION_NEGOTIATION || (n.statusVendor === SCORING_DECISION_ELIMINATED && n.statusScoring === SCORING_TECH_APPROVED)">
							<div class="w-full">
								<h6 class="text-sm font-medium text-tertiary">Vendor #{{n?.sequence}}</h6>
								<h5 class="mb-2 font-semibold text-gray-darkest">{{n?.vendorName}}</h5>
							</div>
							<div v-for="(local, j) in n.localPartners" :key="j" class="w-full">
								<h6 class="text-sm font-medium text-tertiary">Local Partner #{{local.sequence}}</h6>
								<h5 class="mb-2 font-semibold text-gray-darkest">{{local?.vendorName}}</h5>
							</div>
							<div class="w-full line-horizontal" v-if="n?.teknisCriteriaList[0].score"></div>
							<div class="w-full" v-if="n?.teknisCriteriaList[0].score">
								<div class="flex justify-between w-full mb-1">
									<h6 class="text-sm font-medium text-tertiary">Criteria Scoring</h6>
								</div>
								<div class="flex justify-between w-full mb-1">
									<h6 class="text-sm font-medium text-gray">Vendor Data</h6>
									<h6 class="text-gray sm-bold">{{n?.teknisCriteriaList[0].score}}</h6>
								</div>
								<div class="flex justify-between w-full mb-1">
									<h6 class="text-sm font-medium text-gray">Solution</h6>
									<h6 class="text-gray sm-bold">{{n?.teknisCriteriaList[1].score}}</h6>
								</div>
								<div class="flex justify-between w-full mb-1">
									<h6 class="text-sm font-medium text-gray">Support</h6>
									<h6 class="text-gray sm-bold">{{n?.teknisCriteriaList[2].score}}</h6>
								</div>
								<div class="flex justify-between w-full mb-1">
									<h6 class="text-sm font-medium text-gray">Total Scoring Weight</h6>
									<h6 class="text-gray sm-bold">{{n?.totalScoreTeknisCriteriaList}}</h6>
								</div>
							</div>
							<div class="w-full line-horizontal" v-if="n?.skalaNilaiScoringEscrow"></div>
							<div class="w-full">
								<div v-if="n?.skalaNilaiScoringEscrow" class="flex justify-between w-full mb-1">
									<h6 class="text-sm font-medium text-tertiary">Escrow Scoring</h6>
									<h6 class="text-gray sm-bold">{{n?.skalaNilaiScoringEscrow}}</h6>
								</div>
								<h6 v-if="n?.skalaNilaiScoringEscrow" class="text-sm font-medium text-gray">{{n?.isEscrowAgreementNeeded?'Perlu':'Tidak Perlu'}} membuat Escrow Agreement</h6>
							</div>
							<div class="w-full line-horizontal"></div>
							<div class="w-full">
								<div class="flex justify-between w-full mb-1">
									<h6 class="text-sm font-medium text-tertiary">Proof Of Concept</h6>
								</div>
								<h6 class="text-sm font-medium text-gray">{{n?.isPocNeeded?'Perlu':'Tidak Perlu'}}</h6>
							</div>
							<div class="w-full line-horizontal"></div>
							<div 
								class="flex items-center justify-center w-full h-12 rounded-xl"
								:class="{'bg-success-lightest shadow-success-sm text-success-dark':n.isActive, 'bg-error-lightest shadow-error-sm text-error-dark': !n.isActive}"
								>
								{{n.isActive? 'Maju ke tahap Negosiasi':'Eliminasi'}}
							</div>
						</div>
						<div class="w-full" v-else>
							<h6 class="text-tertiary">Vendor #{{n?.sequence}}</h6>
							<h5 class="mb-2 text-gray-dark">{{n?.vendorName}}</h5>
							<div v-for="(local, j) in n.localPartners" :key="j">
								<h6 class="text-tertiary">Local Partner #{{local?.sequence}}</h6>
								<h5 class="mb-2 text-gray-dark">{{local?.vendorName}}</h5>
							</div>
							<div class="flex mb-3" :class="projectDetailContainerWidth < 640 ? 'flex-col flex-start gap-1.5' : 'items-center'">
								<label>Action:</label>
								<div class="w-full " :class="projectDetailContainerWidth < 640 ? '' : 'ml-3'">
									<SelectGista 
										placeholder="Pilih Action"
										:options="['Maju ke tahap Negosiasi', 'Eliminasi']"
										:value="isActiveLocal?.[i]? 'Maju ke tahap Negosiasi': 'Eliminasi'"
										:disabled="!completeScoring || scoringVendorDecision.length === 1 || !menuScoringDecision?.canUpdate || proposalInformation?.isAnyVendorThatHaveNotBeenQualified || projectCanceled"
										@change="changeSelect($event, n, i)" />
								</div>
							</div>
						</div>
					</Card>
				</div>
				<div class="grid grid-cols-1 gap-3.5 mt-6">
					<div class="flex">
						<span class="icon-list text-2xl text-gray-dark"></span>
						<h4 class="text-base text-gray-dark font-semibold pl-6">APPROVAL</h4>
					</div>
					<AutocompleteNewSolutip
						v-if="menuScoringDecision?.canUpdate && proposalInformation?.directApproverId && !approvalGsit?.userId && !projectCanceled"
						label="Approver GSIT"
						placeholder="(Search)"
						:options="approverList"
						@updateInput="inputSearchApprover($event, 'approverGsit')"
						@updateAutoComplete="changeApprover($event, 'approverGsit', 'Approver GSIT')"
						@blur="blurApprover('approverGsit')"
						@focus="focusApprover('approverGsit')"
						v-model="approverGsitSearch"
						textField="name"
						textField2="jobTitle"
						iconLeft="icon-search text-xl text-gray-light"
						mandatory
						:isLoading="isLoading.approverGsit"
						:error="error['Approver GSIT']"
					>
						<template v-if="error['Approver GSIT']" #message>
							<p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
						</template>
					</AutocompleteNewSolutip>
          <InputDisabled v-else-if="approvalGsit?.userName" label="Approver GSIT" :value="approvalGsit?.userName"></InputDisabled>
					<AutocompleteNewSolutip
						v-if="menuScoringDecision?.canUpdate && proposalInformation?.approverUkkpId && !approvalUkkp?.userId && !projectCanceled"
						label="Approver UKKP"
						placeholder="(Search)"
						:options="approverList"
						@updateInput="inputSearchApprover($event, 'approverUkkp')"
						@updateAutoComplete="changeApprover($event, 'approverUkkp', 'Approver UKKP')"
						@blur="blurApprover('approverUkkp')"
						@focus="focusApprover('approverUkkp')"
						v-model="approverUkkpSearch"
						textField="name"
						textField2="jobTitle"
						iconLeft="icon-search text-xl text-gray-light"
						mandatory
						:isLoading="isLoading.approverUkkp"
						:error="error['Approver UKKP']"
					>
						<template v-if="error['Approver UKKP']" #message>
							<p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
						</template>
					</AutocompleteNewSolutip>
          <InputDisabled v-else-if="approvalUkkp?.userName" label="Approver UKKP" :value="approvalUkkp?.userName"></InputDisabled>
					<AutocompleteNewSolutip
						v-if="menuScoringDecision?.canUpdate && !approvalDlog?.userId && !projectCanceled"
						label="Approver DLOG"
						placeholder="(Search)"
						:options="approverList"
						@updateInput="inputSearchApprover($event, 'approverDlog')"
						@updateAutoComplete="changeApprover($event, 'approverDlog', 'Approver DLOG')"
						@blur="blurApprover('approverDlog')"
						@focus="focusApprover('approverDlog')"
						v-model="approverDlogSearch"
						textField="name"
						textField2="jobTitle"
						iconLeft="icon-search text-xl text-gray-light"
						mandatory
						:isLoading="isLoading.approverDlog"
						:error="error['Approver DLOG']"
					>
						<template v-if="error['Approver DLOG']" #message>
							<p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
						</template>
					</AutocompleteNewSolutip>
          <InputDisabled v-else-if="approvalDlog?.userName" label="Approver DLOG" :value="approvalDlog?.userName"></InputDisabled>
				</div>
				<div v-if="!completeScoring" class="w-full line-horizontal"></div>
				<div  v-if="menuScoringDecision?.canUpdate" class="flex w-full my-6" :class="`${completeScoring? 'justify-end': 'justify-between'}`">
					<div v-if="!completeScoring" class="flex items-center">
						<p class="text-xl icon-info text-warning"></p>
						<p class="ml-4 text-warning-darkest">{{textWarning}}
						</p>
					</div>
					<modal-submit-decision 
						@click="submitDecision" 
						:completeScoring="completeScoring" 
						:someEnableScoringDecision="showButtonDecision">
					</modal-submit-decision>
				</div>
			</div>
		</div>
		<ScoringDecisionLoading v-else/>
	</div>
</template>
<script>
import Card from '@/core/components/custom/Card.vue'
import ModalSubmitDecision from '@/core/components/modal/SubmitDecision.vue'
import { MESSAGE_SUCCESS_SCORING_DECISION } from '@/core/constant/successMessage'
import { SCORING_DECISION_NEGOTIATION, SCORING_TECH_APPROVED, SCORING_DECISION_ELIMINATED } from '@/core/constant/qualification'
import ScoringDecisionLoading from '@/core/components/rfp/loading/ScoringDecisionLoading.vue'
import AutocompleteNewSolutip from '@/core/components/custom/AutocompleteNew'
import InputDisabled from '@/core/components/custom/InputDisabled'
import { MESSAGE_POPUP_VALIDATION_SUBMIT, MESSAGE_EMPTY_FIELD } from '@/core/constant/errorMessage.js'
import { CANCELED } from '@/core/constant/statusProject'

export default {
  name: "ScoringDecision",
	data() {
		return {
			loading: false,
			approverGsitSearch: '',
			approverUkkpSearch: '',
			approverDlogSearch: '',
			isLoading: {
				approverGsit: false,
				approverUkkp: false,
				approverDlog: false,
			},
			error: {
				'Approver GSIT': false,
				'Approver UKKP': false,
				'Approver DLOG': false,
			},
			MESSAGE_EMPTY_FIELD,
			applicationRoles: {
				gsit: 'SOLUTIP_SPV1_GSIT,SOLUTIP_SPV2_GSIT,SOLUTIP_SPV3_GSIT',
				ukkp: 'SOLUTIP_SPV1_KP,SOLUTIP_SPV2_KP,SOLUTIP_SPV3_KP',
				dlog: 'SOLUTIP_SPV3_LOG,SOLUTIP_SPV2_LOG'
			},
			SCORING_DECISION_NEGOTIATION, SCORING_TECH_APPROVED, SCORING_DECISION_ELIMINATED
		}
	},
  computed: {
    scoringVendorDecision: {
      get() {
        return this.$store.state.projectDetail.scoringVendorDecision
      },
      set(value) {
        this.$store.commit('projectDetail/SET_SCORING_VENDOR_DECISION', value)
      }
    },
		isActiveLocal: {
			get() {
				return this.$store.state.projectDetail.isActiveLocal
			},
			set(value) {
				this.$store.commit('projectDetail/SET_ISACTIVELOCAL', value)
			}
		},
		scoringVendorDecisionEnabled() {
			return this.scoringVendorDecision.filter(e => !e.isSubmittedDecision || e.isRevisionScoringTechEscrow)
		},
		proposalInformation() {
			return this.$store.state.projectDetail.proposalInformation
		},
		projectDetailContainerWidth() {
			return this.$store.state.width.projectDetailContainerWidth
		},
		modal: {
			get() {
				return this.$store.state.modal.modal
			},
			set(value) {
				this.$store.commit('modal/SET_MODAL', value)
			}
		},
		completeScoring() {
			return this.scoringVendorDecisionEnabled.every(e => e.isCompletedScoring)
		},
		textWarning() {
			if (this.proposalInformation.isSoftware && this.scoringVendorDecision.length > 1) {
				return 'Harap lengkapi penilaian Vendor & Escrow sebelum membuat keputusan.'
			} else if (this.proposalInformation.isSoftware && this.scoringVendorDecision.length === 1) {
				return 'Harap lengkapi penilaian Escrow sebelum membuat keputusan.'
			} else {
				return 'Harap lengkapi penilaian Vendor sebelum membuat keputusan.'
			}
		},
		someEnableScoringDecision() {
			return this.scoringVendorDecision.some(e => e.isEnableScoringDecision)
		},
		scoringProcessByOprGsitKp() {
			return this.scoringVendorDecision.some(e => e.isEnableScoringDecision || e.isRevisionScoringTechEscrow) && !this.proposalInformation?.isAnyVendorThatHaveNotBeenQualified
		},
		isRevisionScoringTechEscrow() {
			return this.scoringVendorDecision.some(e => e.isRevisionScoringTechEscrow)
		},
		showButtonDecision() {
			return (this.someEnableScoringDecision || this.isRevisionScoringTechEscrow) && !this.proposalInformation?.isAnyVendorThatHaveNotBeenQualified
		},
		showButtonScoring() {
			return this.scoringVendorDecision.some(e => !e.isSubmittedDecision || e.isRevisionScoringTechEscrow)
		},
		menuScoringDecision() {
			return this.$store.getters['dashboard/menuScoringDecision']
		},
		approverList() {
			return this.$store.state.rfp.approverList.filter(e => !this.filterForApproverList.includes(e.userName))
		},
		filterForApproverList() {
			const people = []
			if (this.approverGsitSearch) {
				people.push(this.approverScoringDecision.approverGsit.userName)
			}
			if (this.approverUkkpSearch) {
				people.push(this.approverScoringDecision.approverUkkp.userName)
			}
			if (this.approverDlogSearch) {
				people.push(this.approverScoringDecision.approverDlog.userName)
			}
			return people
		},
		userDetail() {
			return this.$store.state.dashboard.userProfile;
		},
		scoringVendorApproval() {
      return this.$store.state.projectDetail.scoringVendorApproval
    },
    approvalGsit() {
      return this.scoringVendorApproval?.find(e => e.unitKerja === 'GSIT')
    },
    approvalUkkp() {
      return this.scoringVendorApproval?.find(e => e.unitKerja === 'UKKP')
    },
    approvalDlog() {
      return this.scoringVendorApproval?.find(e => e.unitKerja === 'ITP')
    },
		approverScoringDecision: {
			get() {
				return this.$store.state.projectDetail.approverScoringDecision
			},
			set(val) {
				this.$store.commit('projectDetail/SET_APPROVER_SCORING_DECISION', val)
			}
		},
		projectLogRfp() {
			return this.$store.state.projectLog.projectLogRfp
		},
		sppProcess() {
			return this.$store.state.spp.sppProcess
		},
    projectCanceledByLogs() {
			return this.projectLogRfp.rfpLogs.some(e => e.statusProject === CANCELED)
		},
		projectCanceled() {
			return this.sppProcess || this.projectCanceledByLogs
		},
		counterInterval() {
			return this.$store.state.projectLog.counterInterval
		},
  },
  watch: {
  },
  methods: {
    changeSelect(e, n, i) {
      const arr = [...this.scoringVendorDecision]
      this.scoringVendorDecision = arr.map(el => el.rfpVendorCandidateId == n.rfpVendorCandidateId ? {...el, isActive: e !== 'Eliminasi'} : el);
			this.isActiveLocal = this.isActiveLocal.map((el, idx) => i === idx? e !== 'Eliminasi' : el)
    },
		approvalList() {
			const approvalList = []
			if (this.approverScoringDecision.approverGsit.userName) {
				approvalList.push({ username: this.approverScoringDecision.approverGsit.userName, name: this.approverScoringDecision.approverGsit.name })
			}
			if (this.approverScoringDecision.approverUkkp.userName) {
				approvalList.push({ username: this.approverScoringDecision.approverUkkp.userName, name: this.approverScoringDecision.approverUkkp.name })
			}
			if (this.approverScoringDecision.approverDlog.userName) {
				approvalList.push({ username: this.approverScoringDecision.approverDlog.userName, name: this.approverScoringDecision.approverDlog.name })
			}
			return approvalList
		},
    async submitDecision() {
			try {
				//show loading
				this.modal.modalLoading = true
				this.setApprover()
				this.checkErrorApproval('approverGsit', 'Approver GSIT')
				this.checkErrorApproval('approverUkkp', 'Approver UKKP')
				this.checkErrorApproval('approverDlog', 'Approver DLOG')
				const errorApproval = Object.values(this.error).some(e => e)
				if (errorApproval) {
					this.modal.modalLoading = false
					const arrayFilterErrorApproval = Object.keys(this.error).filter(e => this.error[e] === true)
					const MESSAGE_ERROR = arrayFilterErrorApproval.join(', ')
					this.modal.modalErrorValidate = { value: true, message: MESSAGE_POPUP_VALIDATION_SUBMIT + ' pada field ' +  MESSAGE_ERROR }
					return
				}
				const approvalList = this.approvalList()
				const response = await this.$store.dispatch('projectDetail/putScoringVendorDecision', { rfpId: this.$route.params.projectId, approvalList })
				if (response?.status < 300 && response?.status >= 200) {
					//loading out
					this.modal.modalLoading = false
					//show modal success edit scoring escrow
					this.modal.modalSuccessSubmitted = { value: true, title: MESSAGE_SUCCESS_SCORING_DECISION }
					this.approverGsitSearch = ''
					this.approverUkkpSearch = ''
					this.approverDlogSearch = ''
					this.$store.dispatch('projectDetail/resetApproverScoringDecision')
					this.$store.dispatch('projectDetail/getScoringVendorDecision', this.$route.params.projectId)
					this.$store.dispatch('projectLog/refetchProjectLogByRfpId', this.$route.params.projectId)
					this.$store.dispatch('projectDetail/getScoringVendorApproval', { rfpId: this.$route.params.projectId })
				} else {
					this.$store.dispatch('modal/throwError', { response })
				}
			} catch (error) {
				//loading out when error
				this.modal.modalLoading = false
				//show modal error edit scoring escrow
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
			}

    },
		async inputSearchApprover(e, key) {
			this.isLoading[key] = true
			if (key === 'approverGsit') {
				await this.$store.dispatch('rfp/getApproverList', { applicationRoles: this.applicationRoles.gsit, name: this.approverGsitSearch })
			} else if (key === 'approverUkkp') {
				await this.$store.dispatch('rfp/getApproverList', { applicationRoles: this.applicationRoles.ukkp, name: this.approverUkkpSearch })
			} else if (key === 'approverDlog') {
				await this.$store.dispatch('rfp/getApproverList', { applicationRoles: this.applicationRoles.dlog, name: this.approverDlogSearch })
			}
      this.isLoading[key] = false
		},
		async focusApprover(key) {
			this.isLoading[key] = true
			if (key === 'approverGsit') {
				await this.$store.dispatch('rfp/getApproverList', { applicationRoles: this.applicationRoles.gsit, name: this.approverGsitSearch })
			} else if (key === 'approverUkkp') {
				await this.$store.dispatch('rfp/getApproverList', { applicationRoles: this.applicationRoles.ukkp, name: this.approverUkkpSearch })
			} else if (key === 'approverDlog') {
				await this.$store.dispatch('rfp/getApproverList', { applicationRoles: this.applicationRoles.dlog, name: this.approverDlogSearch })
			}
			this.isLoading[key] = false
		},
		async changeApprover(e, key, errorKey) {
      this.error[errorKey] = false
			if (key === 'approverGsit') {
				this.approverScoringDecision.approverGsit = e
				this.approverGsitSearch = e.name
			} else if (key === 'approverUkkp') {
				this.approverScoringDecision.approverUkkp = e
				this.approverUkkpSearch = e.name
			} else if (key === 'approverDlog') {
				this.approverScoringDecision.approverDlog = e
				this.approverDlogSearch = e.name
			}
		},
		blurApprover() {
			if (!this.approverGsitSearch) {
				this.approverScoringDecision.approverGsit = {}
			} else {
				this.approverGsitSearch = this.approverScoringDecision.approverGsit.name
			}
			if (!this.approverUkkpSearch) {
				this.approverScoringDecision.approverUkkp = {}
			} else {
				this.approverUkkpSearch = this.approverScoringDecision.approverUkkp.name
			}
			if (!this.approverDlogSearch) {
				this.approverScoringDecision.approverDlog = {}
			} else {
				this.approverDlogSearch = this.approverScoringDecision.approverDlog.name
			}
		},
		checkErrorApproval(key, errorKey) {
			if (!this.approverScoringDecision[key]?.name && key === 'approverGsit' && this.proposalInformation.directApproverId) {
				this.error[errorKey] = true
      } else if (!this.approverScoringDecision[key]?.name && key === 'approverUkkp' && this.proposalInformation.approverUkkpId) {
				this.error[errorKey] = true
      } else if (!this.approverScoringDecision[key]?.name && key === 'approverDlog') {
				this.error[errorKey] = true
			} else {
				this.error[errorKey] = false
			}
		},
		setApproverLocal() {
			this.approverGsitSearch = this.approverScoringDecision.approverGsit?.name || ''
			this.approverUkkpSearch = this.approverScoringDecision.approverUkkp?.name || ''
			this.approverDlogSearch = this.approverScoringDecision.approverDlog?.name || ''
		},
		async reviewApproval() {
			if (this.approvalGsit?.approvalStatusLocale?.toLowerCase() === 'on review' && this.approvalGsit.needReviewDecisionScoring && this.userDetail.username.toLowerCase() === this.approvalGsit?.userId?.toLowerCase()) {
				await this.$store.dispatch('projectDetail/putReviewScoringApproval', { data: { username: this.userDetail.username.toLowerCase(), scoringType: 'DECISION' }, rfpId: this.$route.params.projectId })
      } else if (this.approvalUkkp?.approvalStatusLocale?.toLowerCase() === 'on review' && this.approvalUkkp.needReviewDecisionScoring && this.userDetail.username.toLowerCase() === this.approvalUkkp?.userId?.toLowerCase()) {
				await this.$store.dispatch('projectDetail/putReviewScoringApproval', { data: { username: this.userDetail.username.toLowerCase(), scoringType: 'DECISION' }, rfpId: this.$route.params.projectId })
      } else if (this.approvalDlog?.approvalStatusLocale?.toLowerCase() === 'on review' && this.approvalDlog.needReviewDecisionScoring && this.userDetail.username.toLowerCase() === this.approvalDlog?.userId?.toLowerCase()) {
				await this.$store.dispatch('projectDetail/putReviewScoringApproval', { data: { username: this.userDetail.username.toLowerCase(), scoringType: 'DECISION' }, rfpId: this.$route.params.projectId })
			}
			this.$store.dispatch('projectDetail/getScoringVendorApproval', { rfpId: this.$route.params.projectId })
		},
		setIsActiveLocal() {
			this.isActiveLocal = this.scoringVendorDecision.map((e,i) => e.isActive === this.isActiveLocal?.[i]? e.isActive : this.isActiveLocal?.[i] === undefined? e.isActive : this.isActiveLocal?.[i])
		},
		setApprover() {
			this.approverScoringDecision.approverGsit = this.approvalGsit?.userId? { name: this.approvalGsit?.userName, userName: this.approvalGsit?.userId } : this.approverScoringDecision.approverGsit
			this.approverScoringDecision.approverUkkp = this.approvalUkkp?.userId? { name: this.approvalUkkp?.userName, userName: this.approvalUkkp?.userId } : this.approverScoringDecision.approverUkkp
			this.approverScoringDecision.approverDlog = this.approvalDlog?.userId? { name: this.approvalDlog?.userName, userName: this.approvalDlog?.userId } : this.approverScoringDecision.approverDlog
		}
  },
	async mounted() {
		this.loading = true
		const res = await this.$store.dispatch('projectLog/getCheckAccess', { projectRequestId: this.$route.params.projectId, projectRequestType: 'rfp' })
		console.log(res, res?.data.code, 'get access');
    if (res?.data.code !== 200) return
    const r1 = this.$store.dispatch('qualification/getQualifications', this.$route.params.projectId)
    const r2 = this.$store.dispatch('projectDetail/getScoringVendorDecision', this.$route.params.projectId)
		const r3 = this.$store.dispatch('projectDetail/getProposalInformation', this.$route.params.projectId)
		const [res1, res2, res3] = await Promise.all([r1, r2, r3])
		if (res1?.status === 200 && res2?.status === 200 && res3?.status === 200) {
			this.loading = false
			this.setApproverLocal()
			this.reviewApproval()
			this.setIsActiveLocal()
		}
	},
	destroyed() {
		clearInterval(this.counterInterval)
		this.$store.commit('projectLog/SET_COUNTER_INTERVAL', null)
	},
  components: {
    Card,
		ModalSubmitDecision,
		ScoringDecisionLoading,
		AutocompleteNewSolutip,
		InputDisabled
	}
}
</script>

<style scoped>
.height-custom {
	min-height: 30rem;
}
</style>