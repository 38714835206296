<template>
  <div>
    <div class="flex mt-6">
      <div class="w-full">
        <div class="animate-pulse">
          <div class="h-8 rounded-lg bg-gray-lightest rounded w-full mb-3.5"></div>
          <div class="h-14 rounded-lg bg-gray-lightest rounded w-full mb-3.5"></div>
          <div class="h-14 rounded-lg bg-gray-lightest rounded w-full mb-3.5"></div>
          <div class="h-14 rounded-lg bg-gray-lightest rounded w-full mb-3.5"></div>
          <div class="h-14 rounded-lg bg-gray-lightest rounded w-full mb-6"></div>
          <div class="h-14 rounded-lg bg-gray-lightest rounded w-full mb-6"></div>
          <div class="h-8 rounded-lg bg-gray-lightest rounded w-full mb-3.5"></div>
          <div class="h-40 rounded-lg bg-gray-lightest rounded w-full mb-6"></div>
          <div class="h-8 rounded-lg bg-gray-lightest rounded w-full mb-3.5"></div>
          <div class="h-96 rounded-lg bg-gray-lightest rounded w-full mb-6"></div>
          <div class="h-8 rounded-lg bg-gray-lightest rounded w-full mb-3.5"></div>
          <div class="h-40 rounded-lg bg-gray-lightest rounded w-full mb-6"></div>
          <div class="h-8 rounded-lg bg-gray-lightest rounded w-full mb-3.5"></div>
          <div class="h-64 rounded-lg bg-gray-lightest rounded w-full mb-6"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SktLoiLoading',
}
</script>
<style scoped>
.max-w-40 {
  max-width: 20rem;
}
</style>