<template>
	<div class="w-full">
		<div class="flex justify-between mb-3 cursor-pointer"
			@click="changeCollapse">
			<div class="flex">
				<span class="icon-list text-xl text-gray-dark font-semibold mr-6"></span>
				<h4 class="text-base text-gray-dark font-semibold">PROPOSAL INFORMATION</h4>
			</div>
			<div>
				<p :class="collapse.proposalInformation? 'rotate-180': 'rotate-0'"
					class="icon-chevron-down text-gray text-2xl transform transition ease-in"></p>
			</div>
		</div>
		<div v-show="collapse.proposalInformation">
			<div class="mb-4 grid gap-2.5 w-full"
				:class="`${projectDetailContainerWidth < 640? 'grid-cols-1': projectDetailContainerWidth < 1024? 'grid-cols-2': 'grid-cols-4'}`">
				<div class="border border-gray-lightest p-2.5 rounded-xl items-start">
					<label class="text-sm text-gray">Project ID</label>
					<h5 class="text-gray-dark font-semibold">{{proposalInformation?.projectId || '-'}}</h5>
				</div>
				<div class="border border-gray-lightest p-2.5 rounded-xl items-start">
					<label class="text-sm text-gray">No. RFP</label>
					<h5 class="text-gray-dark font-semibold break-words">{{proposalInformation?.noRfp || '-'}}</h5>
				</div>
				<div class="border border-gray-lightest p-2.5 rounded-xl items-start">
					<label class="text-sm text-gray">Budget Spent (IDR)</label>
					<h5 class="text-gray-dark font-semibold break-words">{{proposalInformation?.budgetSpent || '-'}}</h5>
				</div>
				<div class="border border-gray-lightest p-2.5 rounded-xl items-start">
					<label class="text-sm text-gray">Kode WBS Level 1</label>
					<h5 class="text-gray-dark font-semibold break-words">{{proposalInformation?.kodeWbsLev1 || '-'}}</h5>
				</div>
			</div>
			<div class="border border-gray-lightest p-2.5 rounded-xl items-start mb-4 w-full">
				<label class="text-sm text-gray">Project Name</label>
				<h5 class="text-gray-dark font-semibold break-words">{{proposalInformation?.projectName || '-'}}</h5>
			</div>
			<div class="flex flex-wrap justify-between border border-gray-lightest p-2.5 rounded-xl mb-4 "
				id="info-box"
				ref="infoBox">

				<div class="flex-auto mb-3 min-w-max mr-3 border-r border-gray-lightest">
					<label class="text-sm text-gray truncate">Technical Coordinator</label>
					<h5 class="text-gray-dark text-base truncate">{{proposalInformation?.technicalCoordinatorName || '-'}}</h5>
				</div>
				<div class="  flex-auto mb-3 min-w-max mr-3  border-r border-gray-lightest pr-2 ">
					<label class="text-sm text-gray truncate">Email</label>
					<h5 class="text-gray-dark text-base truncate">{{proposalInformation?.technicalCoordinatorEmail || '-'}}</h5>
				</div>
				<div class="flex-auto mb-3 min-w-max mr-3 border-r border-gray-lightest pr-2">
					<label class="text-sm text-gray truncate">Mobile Phone</label>
					<h5 class="text-gray-dark text-base truncate">{{proposalInformation?.technicalCoordinatorMobilePhone || '-'}}</h5>
				</div>
				<div class="flex-auto mb-3 min-w-max mr-3 border-r border-gray-lightest pr-2">
					<label class="text-sm text-gray truncate">Office Phone</label>
					<h5 class="text-gray-dark text-base truncate">{{proposalInformation?.technicalCoordinatorOfficePhone || '-'}}</h5>
				</div>
				<div class="flex-auto mb-3">
					<label class="text-sm text-gray truncate">Ext</label>
					<h5 class="text-gray-dark text-base truncate">{{proposalInformation?.ext || '-'}}</h5>
				</div>

			</div>
			<div class="border border-gray-lightest p-2.5 rounded-xl items-start w-full">
				<label class="text-sm text-gray">Notes</label>
				<h5 class="text-gray-dark whitespace-pre-wrap break-words">{{proposalInformation?.notes || '-'}}</h5>
			</div>

		</div>
	</div>
</template>

<script>
export default {
	name: "ProposalInformationDetail",
	computed: {
		proposalInformation() {
			return this.$store.state.projectDetail.proposalInformation
		},
		collapse() {
			return this.$store.state.projectDetail.collapse
		},
		projectDetailContainerWidth() {
			return this.$store.state.width.projectDetailContainerWidth
		}
	},
	methods: {
		changeCollapse() {
			this.$emit("collapse")
		},

	},
}
</script>