<template>
  <div class="w-full mt-6">
    <div class="flex justify-between mb-3 cursor-pointer"  @click="changeCollapse">
      <div class="flex">
        <span class="icon-check-circle text-xl text-gray-dark font-semibold mr-6"></span>
        <h4 class="text-base text-gray-dark font-semibold">SCORING INFORMATION</h4>
      </div>
      <div>
        <p :class="collapse.scoringTeknis? 'rotate-180': 'rotate-0'" class="icon-chevron-down text-gray text-2xl transform transition ease-in" ></p>
      </div>
    </div>
    <div v-show="collapse.scoringTeknis" class="mb-4 w-full">
      <div class="border border-gray-lightest p-2.5 rounded-xl w-full">
        <div v-for="(n, i) in scoringTeknisCriteria" :key="i" class="w-full">
          <div class="flex justify-between">
            <p class="text-tertiary font-semibold">{{n.name}}</p>
            <p class="text-primary">{{n.bobot}}</p>
          </div>
          <ul>
            <li v-for="(val, j) in n.rfpScoringTeknis" :key="j">
              <div class="flex justify-between w-full">
                <div class="text-gray">{{val.name}}</div>
                <div class="text-gray">{{val.bobot}}</div>
              </div>
            </li>
          </ul>
        </div>
        
        <div class="border border-gray-lightest my-2 w-full"></div>
        <div class="w-full">
          <div class="flex justify-between">
            <p class="text-secondary">Total Scoring Weight</p>
            <p class="bg-primary text-white px-2.5 py-2 rounded-xl">{{totalWeightScore}}/{{totalWeightBobot}}</p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "ScoringInformationDetail",
  data() {
    return {
    }
  },
  computed: {
    scoringTeknisCriteria() {
      return this.$store.state.projectDetail.scoringTeknisCriteria
    },
    collapse() {
      return this.$store.state.projectDetail.collapse
    },
    totalWeightBobot() {
      return this.scoringTeknisCriteria.reduce((a,b) => +a + +b.bobot, 0)
    },
    totalWeightScore() {
      return this.scoringTeknisCriteria.reduce((a,b) => +a + +b.rfpScoringTeknis.reduce((c,d) => +c + +d.bobot, 0), 0)
    },
  },
  methods: {
    changeCollapse() {
      this.$emit("collapse")
    }
  }
}
</script>